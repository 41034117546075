import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addResident,
  deleteCsvData,
  fetchTotalCount,
  importRecord,
  readCsv,
  readCsvError,
} from "../../services/resident";
import { getAllPropertyUnits } from "../../services/unit";
import {
  allProperties,
  getDashboard,
  getSignedUrl,
  recordCount,
} from "../../services/property";
import {
  IAddResident,
  ICsvError,
  IImport,
} from "../../interfaces/ResidentInterface";
import { IResidentUnit } from "../../interfaces/StaffInterface";
import { IShowUnit } from "../../interfaces/UnitInterface";
import { IGerProperty } from "../../interfaces/PropertyInterface";
import {
  IAuthReducerData,
  IShowProperty,
} from "../../interfaces/AuthInterface";

import { addResidentValidationSchema } from "../../utils/validationSchema";
import { RootState } from "../../redux/reducers/Index";
import {
  arrowDown,
  arrowUp,
  resident,
  urlCsv,
  urlCsvForResidentUploadOnPropertyLevel,
  // staff,
} from "../../constants/commonConstants";

import ActionType from "../../resources/enums";
import CommonDatePicker from "../../components/formElements/CommonCalender";
import CommonSelect from "../../components/formElements/CommonSelect";
import CommonTextBox from "../../components/formElements/CommonTextBox";
import storage from "../../utils/storage";
import toast from "../../utils/toastMessage";
import ROUTES from "../../constants/routes";

import LoginRight from "../../assets/images/loginright.png";
import LoginLeft from "../../assets/images/login-left.png";
import PhoneImage from "../../assets/images/phone.svg";

import "react-calendar/dist/Calendar.css";
import { AccessToken } from "../../interfaces/CommonInterface";

type FilePath = {
  filePath: string;
  fileFormat: string;
};

const AddResident: React.FC = () => {
  const { t: translation } = useTranslation();
  const [unites, setUnites] = useState<IResidentUnit[]>();
  const [buildings, setBuildings] = useState<AccessToken[]>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setFileLoading] = useState<boolean>(false);
  const [showConfirmationPopUp, setShowConfirmationPopUp] =
    useState<boolean>(false);
  const [errorData, setErrorData] = useState<ICsvError[]>();
  const [apiResponse, setapiResponse] = useState<boolean>(true);

  const [progress, setProgress] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const [dashboardData, setDashboardData] = useState<IShowProperty>();
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addResidentValidationSchema(translation)),
  });

  useEffect(() => {
    if (storage.get("residentsList") !== "All" && storage.get("propertyId")) {
      getPropertyUnits(storage.get("propertyId"));
      const params: IGerProperty = {
        property_id: storage.get("propertyId") as string,
      };
      getDashboardData(params);
    }
    buildingList();
  }, []);
  const FileToBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const intervalCall = async (
    userdetails: IImport,
    total: number,
    timestamp: string
  ) => {
    // const unitCheckInterval = setInterval(async () => {
    if (storage.get("residentsList") === "All") {
      userdetails.property_id = "all";
      userdetails.company_user_id = storage.get("propertyManagerId");
    }
    const result = await readCsv({ ...userdetails, timestamp });
    if (result?.data?.success) {
      setFileLoading(true);

      const calculation = result.data.data / total;
      const progressResult = calculation * 100;
      setProgress(progressResult);
      if (result?.data?.data === total) {
        const errorResult = await readCsvError(userdetails);
        setShowConfirmationPopUp(true);
        if (errorResult?.data?.success) {
          setErrorData(errorResult?.data?.data);
          setProgress(0);
          if (authReducer.authData._id) {
            const usedetails: IImport = {
              property_id:
                storage.get("residentsList") === "All"
                  ? "all"
                  : storage.get("propertyId"),
              user_id: authReducer.authData._id,
              company_user_id: storage.get("propertyManagerId"),
            };
            deleteCsvData(usedetails);
          }

          setFileLoading(false);
        }
      } else {
        intervalCall(userdetails, total, timestamp);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (authReducer.authData._id) {
        const userdetails: IImport = {
          property_id:
            storage.get("residentsList") === "All"
              ? "all"
              : storage.get("propertyId"),
          user_id: authReducer.authData._id,
          company_user_id: storage.get("propertyManagerId"),
        };
        const response = await fetchTotalCount(userdetails);
        if (response.data.success) {
          intervalCall(
            userdetails,
            response.data.data.count,
            response.data.data.timestamp
          );
          setapiResponse(false);
        } else {
          setapiResponse(false);
        }
      }
    })();
  }, [authReducer]);

  /**
   * Add resident
   */
  const onSubmit = async (data: IAddResident) => {
    setLoading(true);
    const params = {
      role: resident,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      property_id:
        storage.get("residentsList") === "All"
          ? data.property_id
          : storage.get("propertyId"),
      unit_id: data.unit_id,
      date: data.date,
      account_type: resident,
      company_user_id: storage.get("propertyManagerId"),
      created_by: authReducer.authData.id,
    };

    const responseData = await addResident(params);
    if (responseData?.data?.success) {
      setLoading(false);
      toast.success(translation(`${responseData?.data?.message}`));
      const recordCountData = await recordCount(
        storage.get("residentsList") === "All"
          ? "all"
          : storage.get("propertyId")
      );
      if (recordCountData?.data?.success) {
        dispatch({
          type: ActionType.RECORD_COUNT,
          payload: recordCountData.data.data,
        });
        navigate(ROUTES.RESIDENTS);
      }
    } else {
      setLoading(false);
      toast.error(translation(`${responseData?.data?.message}`));
    }
  };

  /**
   *  Get Property
   */
  const getDashboardData = async (data: IGerProperty) => {
    const dashboard = await getDashboard(data);
    if (dashboard?.data?.success) {
      setDashboardData(dashboard.data.data[0]);
    }
  };

  /**
   *  Get Vacant unit of a property
   */
  const getPropertyUnits = async (id: string) => {
    const responseData = await getAllPropertyUnits(id);
    if (responseData?.data?.success) {
      const filterData: { key: string; value: number }[] = [];
      responseData.data.data.forEach((item: IShowUnit) => {
        filterData.push({ key: item.id, value: item.unit } as IResidentUnit);
      });

      setUnites(filterData);
    }
  };
  useEffect(() => {
    if (storage.get("residentsList") !== "All") {
      dashboardData &&
        dashboardData._id &&
        getPropertyUnits(
          dashboardData?._id ? dashboardData?._id : storage.get("propertyId")
        );
    } else if (storage.get("residentsList") === "All" && watch("property_id")) {
      getPropertyUnits(watch("property_id"));
    }
  }, [watch("property_id")]);

  /**
   * get building list for a property
   */
  const buildingList = async () => {
    const responseData = await allProperties(storage.get("propertyManagerId"));

    if (responseData?.data?.success) {
      const filterData: { key: string; value: string }[] = [];
      responseData.data.data.forEach((item: IShowProperty) => {
        filterData.push({
          key: item._id,
          value: item.building_name,
        } as AccessToken);
      });
      setBuildings(filterData);
    }
  };

  useEffect(() => {
    if (storage.get("residentsList") !== "All") {
      reset({
        property_id: dashboardData?.building_name,
        role: resident,
        email: "",
        last_name: "",
        first_name: "",
        phone: "",
      });
    }
  }, [dashboardData]);

  const onDrop = async (event: ChangeEvent<HTMLInputElement>) => {
    if (authReducer.authData._id) {
      const userdetails: IImport = {
        property_id:
          storage.get("residentsList") === "All"
            ? "all"
            : storage.get("propertyId"),
        user_id: authReducer.authData._id,
        company_user_id: storage.get("propertyManagerId"),
      };

      const totalCount = await fetchTotalCount(userdetails);
      const { files } = event.target;
      if (files && files[0] && totalCount && !totalCount.data.success) {
        setFileLoading(true);
        const file = files[0];
        if (inputRef.current != null) {
          // 👉️ TypeScript knows that ref is not null here
          inputRef.current.value = "";
        }
        if (
          file.type === "text/csv" ||
          file.type === "application/vnd.ms-excel"
        ) {
          const filedata = await FileToBase64(file);
          const filePath = `${
            authReducer.authData._id
          }-${new Date().getTime()}.csv`;
          if (filedata) {
            const response = await uploadFileOnS3(file, filePath);
            if (
              response ||
              (storage.get("propertyId") && authReducer.authData._id)
            ) {
              const data: IImport = {
                property_id:
                  storage.get("residentsList") === "All"
                    ? "all"
                    : storage.get("propertyId"),
                user_id: authReducer.authData._id,
                url: response,
                account_type: resident,
                company_user_id: storage.get("propertyManagerId"),
              };
              const responseData = await importRecord(data);
              const usedetails: IImport = {
                property_id: storage.get("propertyId"),
                user_id: authReducer.authData._id,
              };
              if (responseData?.data?.success) {
                intervalCall(
                  usedetails,
                  responseData.data.data.count,
                  responseData?.data?.data?.timestamp
                );
              } else {
                setFileLoading(false);
                toast.info(translation(responseData?.data?.message));
              }
            }
          }
        } else {
          setFileLoading(false);
          toast.error(translation("csv_file_only"));
        }
      } else {
        setFileLoading(false);
        toast.info(translation("record_already_exist"));
      }
    }
  };

  const downloadFile = async () => {
    const link = document.createElement("a");
    link.href =
      storage.get("residentsList") === "All"
        ? urlCsvForResidentUploadOnPropertyLevel
        : urlCsv;
    link.click();
  };

  /**
   *  Get presignedUrl for image upload
   */
  const uploadFileOnS3 = async (file: Blob, filePath: string) => {
    let body: FilePath = {
      filePath: "",
      fileFormat: "",
    };
    body = {
      filePath,
      fileFormat: file.type as string,
    };
    let signedUrl;
    const presignedUrl = await getSignedUrl(body);
    if (presignedUrl && presignedUrl.data) {
      const response = await pushFileToS3(presignedUrl.data.data, file);
      if (response?.url) {
        signedUrl = response?.url.split("?Content")?.[0];
      }
    }
    return signedUrl;
  };

  /**
   *  Upload image on presignedUrl of S3
   */
  const pushFileToS3 = async (signedUrl: string, file: Blob) => {
    const myHeaders = new Headers({
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    });
    return fetch(signedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
  };

  const handleClose = async () => {
    setFileLoading(false);
    setShowConfirmationPopUp(false);
  };
  return (
    <>
      <div className="row m-0">
        <div className="col-md-5 p-0">
          <div
            className="loginleft p-4"
            style={{ backgroundImage: `url(${LoginLeft})` }}
          >
            <div className="position-back">
              <Link to={ROUTES.RESIDENTS}>
                <button
                  type="submit"
                  className="theme-button white-btn-outline"
                >
                  <svg
                    className="me-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10.375"
                    height="18"
                    viewBox="0 0 10.375 18"
                  >
                    <path
                      id="np_arrow-down_2022881_000000"
                      d="M44,51.727a1.332,1.332,0,0,1-.943-.373l-7.683-7.7a1.344,1.344,0,1,1,1.891-1.91L44,48.5l6.735-6.759a1.339,1.339,0,0,1,1.891,1.891l-7.683,7.721a1.332,1.332,0,0,1-.943.373Z"
                      transform="translate(51.727 -34.977) rotate(90)"
                      fill="#fff"
                    />
                  </svg>
                  {translation("back")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-7 p-0">
          <div
            className="loginright"
            style={{ backgroundImage: `url(${LoginRight})` }}
          >
            <form
              className="inner"
              onSubmit={handleSubmit((data) => onSubmit(data as IAddResident))}
            >
              <div className="custom-scroll">
                <div className="loginright-top">
                  <div className="heading">
                    <h2 className=" white-color mb-0">
                      <b>{translation("add_a_new_resident")}</b>
                    </h2>
                  </div>
                  <div className="download-btn">
                    <button onClick={() => downloadFile()} type="button">
                      {translation("file_format_download")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                      >
                        <g
                          id="Icon_feather-download"
                          data-name="Icon feather-download"
                          transform="translate(-3 -3)"
                        >
                          <path
                            id="Path_8648"
                            data-name="Path 8648"
                            d="M20,29H6a3,3,0,0,1-3-3V22a1,1,0,0,1,2,0v4a1,1,0,0,0,1,1H20a1,1,0,0,0,1-1V22a1,1,0,1,1,2,0v4A3,3,0,0,1,20,29Z"
                            transform="translate(0 -6)"
                          />
                          <path
                            id="Path_8649"
                            data-name="Path 8649"
                            d="M15,20.5a1,1,0,0,1-.707-.293l-5-5a1,1,0,1,1,1.414-1.414L15,18.086l4.293-4.293a1,1,0,1,1,1.414,1.414l-5,5A1,1,0,0,1,15,20.5Z"
                            transform="translate(-2 -3.5)"
                          />
                          <path
                            id="Path_8650"
                            data-name="Path 8650"
                            d="M17.5,17a1,1,0,0,1-1-1V4a1,1,0,1,1,2,0V16A1,1,0,0,1,17.5,17Z"
                            transform="translate(-4.5)"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="file-select-btn">
                  <div className="select-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.622"
                      height="27.622"
                      viewBox="0 0 27.622 27.622"
                    >
                      <g
                        id="Icon_feather-upload"
                        data-name="Icon feather-upload"
                        transform="translate(-3 -3)"
                      >
                        <path
                          id="Path_8648"
                          data-name="Path 8648"
                          d="M26.479,32.049H7.143A4.148,4.148,0,0,1,3,27.906V22.381a1.381,1.381,0,1,1,2.762,0v5.524a1.383,1.383,0,0,0,1.381,1.381H26.479a1.383,1.383,0,0,0,1.381-1.381V22.381a1.381,1.381,0,1,1,2.762,0v5.524A4.148,4.148,0,0,1,26.479,32.049Z"
                          transform="translate(0 -1.427)"
                          fill="#7f7f7f"
                        />
                        <path
                          id="Path_8649"
                          data-name="Path 8649"
                          d="M24.192,12.668a1.377,1.377,0,0,1-.977-.4L17.287,6.334l-5.929,5.929A1.381,1.381,0,0,1,9.4,10.31L16.31,3.4a1.381,1.381,0,0,1,1.953,0l6.906,6.906a1.381,1.381,0,0,1-.977,2.358Z"
                          transform="translate(-0.476)"
                          fill="#7f7f7f"
                        />
                        <path
                          id="Path_8650"
                          data-name="Path 8650"
                          d="M17.881,22.335A1.381,1.381,0,0,1,16.5,20.954V4.381a1.381,1.381,0,0,1,2.762,0V20.954A1.381,1.381,0,0,1,17.881,22.335Z"
                          transform="translate(-1.07 0)"
                          fill="#7f7f7f"
                        />
                      </g>
                    </svg>
                    <span>
                      {uploading
                        ? `${translation("uploading ")}${
                            progress > 0 ? progress.toFixed(0) : 0
                          }%`
                        : translation("upload_file")}
                    </span>
                  </div>
                  <div className="file-btn">
                    <button type="button">{translation("browse")}</button>
                    <input
                      disabled={uploading || apiResponse}
                      type="file"
                      ref={inputRef}
                      accept=".csv"
                      onChange={onDrop}
                    />
                  </div>
                </div>
                <p>{apiResponse ? translation("loading_info") : null}</p>

                <div className="seprator">
                  <span>{translation("or")}</span>
                </div>

                <div className="row m-0">
                  {/* <div className="col-md-12 p-0">
                    {" "}
                    <div className="form-group">
                      <CommonTextBox
                        control={control}
                        type="text"
                        placeHolder={translation("select_role_placeholder")}
                        className="form-control"
                        name="role"
                        error={errors?.role}
                        fieldType="input"
                        value="Resident"
                        readOnly
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6 p-0">
                    <div className="form-group me-2">
                      <div className="left-icon-input">
                        {" "}
                        <CommonTextBox
                          control={control}
                          error={errors?.first_name}
                          type="text"
                          placeHolder={translation("first_name_placeholder")}
                          className="form-control"
                          name="first_name"
                          fieldType="input"
                        />
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 37.697 40"
                          >
                            <path
                              id="Path_8"
                              data-name="Path 8"
                              d="M7.51,26.4H34.424q5.391,0,5.391-3.568a9.559,9.559,0,0,0-1.3-4.556,16.039,16.039,0,0,0-3.766-4.493A20.027,20.027,0,0,0,28.8,10.365a21.9,21.9,0,0,0-7.823-1.322,21.973,21.973,0,0,0-7.844,1.322,20.024,20.024,0,0,0-5.947,3.422A16.039,16.039,0,0,0,3.421,18.28a9.559,9.559,0,0,0-1.3,4.556Q2.118,26.4,7.51,26.4Zm-.854-3.5a.974.974,0,0,1-.56-.124.512.512,0,0,1-.171-.443,5.976,5.976,0,0,1,.986-2.94,12.332,12.332,0,0,1,2.909-3.228,16.467,16.467,0,0,1,4.725-2.592,18.553,18.553,0,0,1,6.434-1.032,18.481,18.481,0,0,1,6.412,1.032,16.469,16.469,0,0,1,4.725,2.592,12.506,12.506,0,0,1,2.919,3.228,5.934,5.934,0,0,1,1,2.94.514.514,0,0,1-.17.443,1.016,1.016,0,0,1-.58.124ZM20.978,6.467a8.445,8.445,0,0,0,4.694-1.38A10.125,10.125,0,0,0,29.067,1.4a10.425,10.425,0,0,0,1.268-5.1,10.015,10.015,0,0,0-1.259-4.987,9.775,9.775,0,0,0-3.387-3.573A8.716,8.716,0,0,0,20.978-13.6a8.635,8.635,0,0,0-4.693,1.339,9.923,9.923,0,0,0-3.4,3.595,9.837,9.837,0,0,0-1.247,5A10.454,10.454,0,0,0,12.9,1.428,9.972,9.972,0,0,0,16.281,5.1,8.494,8.494,0,0,0,20.978,6.467Zm0-3.5a4.9,4.9,0,0,1-2.814-.877A6.29,6.29,0,0,1,16.1-.293a7.292,7.292,0,0,1-.775-3.375,6.951,6.951,0,0,1,.763-3.275,6.058,6.058,0,0,1,2.054-2.3,5.058,5.058,0,0,1,2.834-.848,5.083,5.083,0,0,1,2.843.837,6.03,6.03,0,0,1,2.043,2.284,6.9,6.9,0,0,1,.763,3.267,7.337,7.337,0,0,1-.771,3.384,6.382,6.382,0,0,1-2.052,2.4A4.853,4.853,0,0,1,20.978,2.964Z"
                              transform="translate(-2.118 13.595)"
                              opacity="0.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-0">
                    {" "}
                    <div className="form-group">
                      <div className="left-icon-input">
                        {" "}
                        <CommonTextBox
                          control={control}
                          type="text"
                          placeHolder={translation("last_name_placeholder")}
                          className="form-control"
                          name="last_name"
                          error={errors?.last_name}
                          fieldType="input"
                        />
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 37.697 40"
                          >
                            <path
                              id="Path_8"
                              data-name="Path 8"
                              d="M7.51,26.4H34.424q5.391,0,5.391-3.568a9.559,9.559,0,0,0-1.3-4.556,16.039,16.039,0,0,0-3.766-4.493A20.027,20.027,0,0,0,28.8,10.365a21.9,21.9,0,0,0-7.823-1.322,21.973,21.973,0,0,0-7.844,1.322,20.024,20.024,0,0,0-5.947,3.422A16.039,16.039,0,0,0,3.421,18.28a9.559,9.559,0,0,0-1.3,4.556Q2.118,26.4,7.51,26.4Zm-.854-3.5a.974.974,0,0,1-.56-.124.512.512,0,0,1-.171-.443,5.976,5.976,0,0,1,.986-2.94,12.332,12.332,0,0,1,2.909-3.228,16.467,16.467,0,0,1,4.725-2.592,18.553,18.553,0,0,1,6.434-1.032,18.481,18.481,0,0,1,6.412,1.032,16.469,16.469,0,0,1,4.725,2.592,12.506,12.506,0,0,1,2.919,3.228,5.934,5.934,0,0,1,1,2.94.514.514,0,0,1-.17.443,1.016,1.016,0,0,1-.58.124ZM20.978,6.467a8.445,8.445,0,0,0,4.694-1.38A10.125,10.125,0,0,0,29.067,1.4a10.425,10.425,0,0,0,1.268-5.1,10.015,10.015,0,0,0-1.259-4.987,9.775,9.775,0,0,0-3.387-3.573A8.716,8.716,0,0,0,20.978-13.6a8.635,8.635,0,0,0-4.693,1.339,9.923,9.923,0,0,0-3.4,3.595,9.837,9.837,0,0,0-1.247,5A10.454,10.454,0,0,0,12.9,1.428,9.972,9.972,0,0,0,16.281,5.1,8.494,8.494,0,0,0,20.978,6.467Zm0-3.5a4.9,4.9,0,0,1-2.814-.877A6.29,6.29,0,0,1,16.1-.293a7.292,7.292,0,0,1-.775-3.375,6.951,6.951,0,0,1,.763-3.275,6.058,6.058,0,0,1,2.054-2.3,5.058,5.058,0,0,1,2.834-.848,5.083,5.083,0,0,1,2.843.837,6.03,6.03,0,0,1,2.043,2.284,6.9,6.9,0,0,1,.763,3.267,7.337,7.337,0,0,1-.771,3.384,6.382,6.382,0,0,1-2.052,2.4A4.853,4.853,0,0,1,20.978,2.964Z"
                              transform="translate(-2.118 13.595)"
                              opacity="0.5"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    {" "}
                    <div className="form-group">
                      <div className="left-icon-input">
                        {" "}
                        <CommonTextBox
                          control={control}
                          type="email"
                          placeHolder={translation("email_placeholder")}
                          className="form-control"
                          name="email"
                          error={errors?.email}
                          fieldType="input"
                        />
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 41.25 32.25"
                          >
                            <g
                              id="bx-user"
                              transform="translate(0.45 -2.798)"
                              opacity="0.5"
                            >
                              <path
                                id="Path_41"
                                data-name="Path 41"
                                d="M22.5,10.5A4.5,4.5,0,1,1,18,6,4.5,4.5,0,0,1,22.5,10.5Z"
                                transform="translate(2.175)"
                                fill="none"
                              />
                              <g
                                id="mail-outline"
                                transform="translate(0.675 3.923)"
                              >
                                <path
                                  id="Path_292"
                                  data-name="Path 292"
                                  d="M7.126,5.625h31.5A4.882,4.882,0,0,1,43.5,10.5V33a4.882,4.882,0,0,1-4.874,4.874H7.126A4.882,4.882,0,0,1,2.25,33V10.5A4.881,4.881,0,0,1,7.126,5.625Zm31.5,30A2.629,2.629,0,0,0,41.25,33V10.5a2.629,2.629,0,0,0-2.625-2.626H7.126A2.629,2.629,0,0,0,4.5,10.5V33a2.629,2.629,0,0,0,2.626,2.624Z"
                                  transform="translate(-3.375 -6.75)"
                                />
                                <path
                                  id="Path_293"
                                  data-name="Path 293"
                                  d="M22.044,21.7a1.124,1.124,0,0,1-.619-.185L7.256,12.19A1.125,1.125,0,1,1,8.494,10.31l13.551,8.921L35.6,10.31a1.125,1.125,0,1,1,1.237,1.879L22.663,21.518A1.124,1.124,0,0,1,22.044,21.7Z"
                                  transform="translate(-2.544 -4.89)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    {" "}
                    <div className="form-group">
                      <div className="left-icon-input">
                        {" "}
                        <CommonTextBox
                          control={control}
                          type="number"
                          placeHolder={translation("phone_placeholder")}
                          className="form-control"
                          name="phone"
                          error={errors?.phone}
                          fieldType="input"
                          onWheel={(event) => {
                            event.currentTarget.blur();
                          }}
                          onKeyDown={(e) => {
                            if (e.key === arrowDown || e.key === arrowUp) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <span>
                          <img src={PhoneImage} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    {" "}
                    <div className="form-group">
                      <div className="left-icon-input">
                        {storage.get("residentsList") === "All" ? (
                          <CommonSelect
                            className="form-select border-0"
                            aria-label="Default select example"
                            dropDownData={buildings ? buildings : []}
                            error={errors?.property_id}
                            control={control}
                            name="property_id"
                            multipleSelect={false}
                            firstOption="—Select Building —"
                          />
                        ) : (
                          <CommonTextBox
                            control={control}
                            type="text"
                            placeHolder={translation(
                              "select_building_dropdown"
                            )}
                            className="form-control"
                            name="property_id"
                            error={errors?.property_id}
                            fieldType="input"
                            readOnly
                          />
                        )}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 40 35.941"
                          >
                            <path
                              id="Path_304"
                              data-name="Path 304"
                              d="M26.617,19.381H39.354a2.824,2.824,0,0,0,2.2-.881,3.424,3.424,0,0,0,.8-2.4V-4.978a3.422,3.422,0,0,0-.8-2.4,2.824,2.824,0,0,0-2.2-.881H27.816v2.99h10.62a.947.947,0,0,1,.7.229.947.947,0,0,1,.229.7v19.8a.813.813,0,0,1-.925.925H26.617Zm6.5-18.21h2.67a.5.5,0,0,0,.559-.576v-2.6a.5.5,0,0,0-.559-.56h-2.67a.5.5,0,0,0-.559.56v2.6A.5.5,0,0,0,33.114,1.171Zm0,6.288h2.67a.5.5,0,0,0,.559-.562V4.3a.5.5,0,0,0-.559-.56h-2.67a.5.5,0,0,0-.559.56V6.9A.5.5,0,0,0,33.114,7.459Zm0,6.288h2.67a.5.5,0,0,0,.559-.562v-2.6a.5.5,0,0,0-.559-.562h-2.67a.5.5,0,0,0-.559.562v2.6A.5.5,0,0,0,33.114,13.746ZM2.354,16.1a3.4,3.4,0,0,0,.811,2.4,2.849,2.849,0,0,0,2.2.881H26.523a2.822,2.822,0,0,0,2.2-.881,3.424,3.424,0,0,0,.8-2.4V-13.3a3.4,3.4,0,0,0-.8-2.381,2.822,2.822,0,0,0-2.2-.881H5.369a2.849,2.849,0,0,0-2.2.881A3.38,3.38,0,0,0,2.354-13.3Zm3.008-.638V-12.659a.946.946,0,0,1,.221-.682.918.918,0,0,1,.688-.229H25.62a.923.923,0,0,1,.68.229.925.925,0,0,1,.229.682V15.465a.947.947,0,0,1-.229.7.923.923,0,0,1-.68.229H6.271a.918.918,0,0,1-.688-.229A.969.969,0,0,1,5.362,15.465Zm4.665,2.354h2.385V12.236a.461.461,0,0,1,.522-.522h6.011a.469.469,0,0,1,.539.522v5.583h2.384V11.487a2.23,2.23,0,0,0-.5-1.6,2.023,2.023,0,0,0-1.525-.523H12.054a2.019,2.019,0,0,0-1.532.523,2.253,2.253,0,0,0-.5,1.6Zm.386-24.093H13.64a.6.6,0,0,0,.681-.685V-10.1a.6.6,0,0,0-.681-.685H10.413q-.649,0-.649.685v3.142Q9.764-6.274,10.413-6.274Zm7.81,0h3.226a.6.6,0,0,0,.683-.685V-10.1a.6.6,0,0,0-.683-.685H18.223q-.651,0-.651.685v3.142Q17.572-6.274,18.223-6.274ZM10.413.244H13.64a.6.6,0,0,0,.681-.684V-3.566a.6.6,0,0,0-.681-.684H10.413q-.649,0-.649.684V-.44Q9.764.244,10.413.244Zm7.81,0h3.226a.6.6,0,0,0,.683-.684V-3.566a.6.6,0,0,0-.683-.684H18.223q-.651,0-.651.684V-.44Q17.572.244,18.223.244Zm-7.81,6.518H13.64a.6.6,0,0,0,.681-.684V2.952a.6.6,0,0,0-.681-.683H10.413q-.649,0-.649.683V6.078Q9.764,6.762,10.413,6.762Zm7.81,0h3.226a.6.6,0,0,0,.683-.684V2.952a.6.6,0,0,0-.683-.683H18.223q-.651,0-.651.683V6.078Q17.572,6.762,18.223,6.762Z"
                              transform="translate(-2.354 16.561)"
                              fill="#b6b6b6"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    {" "}
                    <div className="form-group">
                      <div className="left-icon-input">
                        {" "}
                        <CommonSelect
                          className="form-select border-0"
                          aria-label="Default select example"
                          dropDownData={unites ? unites : []}
                          error={errors?.unit_id}
                          control={control}
                          name="unit_id"
                          multipleSelect={false}
                          firstOption="—Select Unit —"
                        />
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25.386"
                            height="25"
                            viewBox="0 0 25.386 40"
                          >
                            <path
                              id="Path_7"
                              data-name="Path 7"
                              d="M12.441,13.459,28.752-7.433a1.879,1.879,0,0,0,.479-1.172,1.179,1.179,0,0,0-.368-.882,1.3,1.3,0,0,0-.948-.357H17.852l5.256-13.813a1.6,1.6,0,0,0-.033-1.525,1.3,1.3,0,0,0-1.118-.588,1.662,1.662,0,0,0-1.322.762L4.324-4.1a1.862,1.862,0,0,0-.479,1.155,1.177,1.177,0,0,0,.369.883,1.307,1.307,0,0,0,.947.356H15.226L9.953,12.108A1.642,1.642,0,0,0,10,13.641a1.271,1.271,0,0,0,1.117.589A1.684,1.684,0,0,0,12.441,13.459Zm1.027-4.85L19.487-4.459H8.5L20.148-19.885l-.572-.271-6,13.065H24.56L12.915,8.338Z"
                              transform="translate(-3.845 25.77)"
                              fill="#b6b6b6"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0">
                    <CommonDatePicker
                      name="date"
                      minReqDate
                      control={control}
                      error={errors?.date}
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="theme-button primary-btn mb-3 w-100 mt-3"
                disabled={loading}
              >
                {translation("add_resident")}
                {loading ? (
                  <div
                    className="spinner-border text-light ms-2 btn-loader"
                    role="status"
                  >
                    <span className="sr-only" />
                  </div>
                ) : (
                  ""
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      {showConfirmationPopUp && (
        <div className="modal modal-custom-show">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="inner">
                  <h3 className="theme-color text-center">
                    {errorData && errorData.length > 0
                      ? translation("please_check_format")
                      : null}
                  </h3>
                  <div className="text-center mt-3 error-show-height">
                    {errorData && errorData.length > 0 ? (
                      errorData.map((item: ICsvError) => (
                        <p>{item.message}&nbsp;</p>
                      ))
                    ) : (
                      <p>{translation("csv_file_uploaded")}</p>
                    )}
                  </div>
                  <div className="text-center mt-2">
                    <button
                      className="theme-button btn-sm black-btn-outline"
                      type="submit"
                      onClick={() => handleClose()}
                    >
                      {translation("ok")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddResident;
