import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { staff } from "../../constants/commonConstants";
import ROUTES from "../../constants/routes";
import { IAuthReducerData } from "../../interfaces/AuthInterface";
import {
  IPropertyResidentSchema,
  IPropertyUnit,
  IUserWithUnit,
} from "../../interfaces/ResidentInterface";
import { RootState } from "../../redux/reducers/Index";
import storage from "../../utils/storage";
import close from "../../assets/images/close.svg";
import { recordCount } from "../../services/property";
import ActionType from "../../resources/enums";

interface IPopUpModalInterface {
  showPopUpModal: boolean;
  unitData: IUserWithUnit | undefined;
  buildingName: string;
  closePopUpModal?: (e: boolean) => void;
}

const CommonUnitDetailsPopUp: React.FC<IPopUpModalInterface> = (props) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  /**
   * close the popup model
   */
  const closePopUp = (data: boolean) => {
    props?.closePopUpModal?.(data);
  };

  /**
   * navigate to unit detail page
   */
  const unitDetail = async (item: IPropertyUnit, userId: string) => {
    storage.set("roomId", item.smartthing_room_id);
    storage.set("propertyId", item.property_id);
    storage.set("residentsList", "");
    storage.set("roomId", item.smartthing_room_id);
    const recordCountData = await recordCount(storage.get("propertyId"));
    if (recordCountData?.data?.success) {
      dispatch({
        type: ActionType.RECORD_COUNT,
        payload: recordCountData.data.data,
      });
    }
    if (authReducer.authData.account_type !== staff)
      navigate(ROUTES.UNITDETAIL, {
        state: {
          parentComponent: {
            name: props.buildingName,
            route: ROUTES.DASHBOARD,
          },
          childComponent: {
            name: item.unit,
            route: ROUTES.UNITS,
          },
          unitId: item._id,
          unitName: item.unit,
          residentId: userId,
        },
      });
  };

  return (
    <div>
      {props?.showPopUpModal && (
        <div
          className="modal modal-custom-show theme-modal units-modal"
          id="assignUnitPopUp"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-center p-4">
                <div className="text-center">
                  <h3 className="theme-color">
                    <b>{translation("unit")}</b>
                  </h3>
                </div>
                <div className="modal-closer">
                  <button
                    type="button"
                    className="modal-closer-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => closePopUp(false)}
                  >
                    <img src={close} alt="" />
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="units-list">
                  {props?.unitData &&
                    props?.unitData?.properties.map(
                      (unitItem: IPropertyResidentSchema) =>
                        unitItem.unitsInfo.length > 0 && (
                          <p
                            onClick={() =>
                              unitDetail(
                                unitItem?.unitsInfo[0],
                                props?.unitData ? props?.unitData._id : ""
                              )
                            }
                          >
                            {unitItem.unitsInfo[0].unit}
                          </p>
                        )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CommonUnitDetailsPopUp);
