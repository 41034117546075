/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import moment from "moment";
import * as Sentry from "@sentry/react";
import Header from "../../components/header/Header";
import TicketIcon from "../../assets/images/ticket.svg";
import CloseIcon from "../../assets/images/close.svg";
import CommonTextBox from "../../components/formElements/CommonTextBox";
import {
  addSupportTicket,
  downLoadFile,
  fetchTotalRecord,
  getSupportTicket,
  getSupportTicketCount,
  getSupportUrl,
  updateSupportTicket,
} from "../../services/support";
import {
  IAddSupportTicket,
  ISupportTicket,
  ISupportTicketCount,
  IUpdateSupportTicket,
} from "../../interfaces/Support";
import Loading from "../../components/loader/Loader";
import CommonSearchBar from "../../components/formElements/CommonSearchBar";
import { ISearchAndStatus } from "../../interfaces/ResidentInterface";
import CommonSelect from "../../components/formElements/CommonSelect";
import CommonTextArea from "../../components/formElements/CommonTextarea";
import { allProperties, getPropertyList } from "../../services/property";
import {
  dateSortingOption,
  Status,
  StatusType,
} from "../../constants/commonConstants";
import { unitlist } from "../../services/unit";
import toast from "../../utils/toastMessage";
import {
  addSupportTicketValidationSchema,
  updateSupportTicketValidationSchema,
} from "../../utils/validationSchema";

import {
  IPropertyData,
  IPropertyInformation,
  ISupportTicketDetail,
  ISupportTicketList,
} from "../../interfaces/PropertyInterface";
import { IAuthReducerData, IShowUnit } from "../../interfaces/AuthInterface";
import CommonMultiSearchSelect from "../../components/formElements/CommonSearchMultiSelect";
import { IMultiSelect } from "../../interfaces/CommonInterface";
import { RootState } from "../../redux/reducers/Index";

type state = {
  list: ISupportTicket[];
  offset: number;
  prevOffset: number;
  hasMore: boolean;
};

const SupportTicket: React.FC = () => {
  const { t: translation } = useTranslation();
  const [setModal, showSetModal] = useState(false);
  const [updateLoading, setUploadLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [setSupportTicketModal, showSupportTicketModal] = useState(false);
  const [downloading, setDownloading] = useState(false);
  // const [issueStatus, setIssueStatus] = useState<string[]>([]);
  const [searchTextValue, setSearchText] = useState<string | undefined>("");
  const [supportTicket, setSupportTicket] = useState<state>({
    list: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });
  const [showSupportTicketCount, setSupportTicketCount] =
    useState<ISupportTicketCount>();

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const {
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});

  const {
    control: controlForAddSupport,
    reset: resetForAddSupport,
    watch: watchForAddSupport,
    handleSubmit: handleSubmitForAddSupport,
    setValue,
    formState: { errors: errorForAddSupport },
  } = useForm({
    resolver: yupResolver(addSupportTicketValidationSchema(translation)),
  });

  const {
    control: controlForUpdateSupport,
    reset: resetForUpdateSupport,
    handleSubmit: handleSubmitForUpdateSupport,
    getValues: getValuesForUpdateSupport,
    formState: { errors: errorsForUpdateSupport },
  } = useForm({
    resolver: yupResolver(updateSupportTicketValidationSchema(translation)),
  });
  const [propertyList, setPropertyList] = useState<IMultiSelect[]>([
    { value: "All", label: "Property: All" },
  ]);
  useEffect(() => {
    fetchSupportTicketCount();
    fetchSupportTicket(true);
    getPropertyListData();
    reset({
      companyId: propertyList[0],
      statusType: Status[0].key,
      sortByDate: dateSortingOption[0].key,
    });
  }, []);
  const [supportTicketDetail, setSupportTicketDetail] =
    useState<ISupportTicketDetail>({} as ISupportTicketDetail);

  const [buildingList, setBuildingList] = useState<IMultiSelect[]>([]);
  const [unitList, setUnitList] = useState<IMultiSelect[]>([]);
  useEffect(() => {
    setValue("buildingIdForSupportTicketCreation", "");
    if (
      watchForAddSupport("companyIdForSupportTicketCreation") &&
      watchForAddSupport("companyIdForSupportTicketCreation")
    ) {
      getAllBuildings(
        watchForAddSupport("companyIdForSupportTicketCreation").value
      );
    }
  }, [watchForAddSupport("companyIdForSupportTicketCreation")]);

  useEffect(() => {
    if (
      watchForAddSupport("unitIdForSupportTicketCreation") ||
      (watchForAddSupport("unitIdForSupportTicketCreation") &&
        Object.keys(watchForAddSupport("unitIdForSupportTicketCreation"))
          ?.length === 0)
    ) {
      setValue("unitIdForSupportTicketCreation", {});
    } else {
      setValue("unitIdForSupportTicketCreation", "");
    }
    if (
      watchForAddSupport("buildingIdForSupportTicketCreation") &&
      watchForAddSupport("buildingIdForSupportTicketCreation")
    ) {
      getAllUnitsForBuilding(
        watchForAddSupport("buildingIdForSupportTicketCreation").value
      );
    }
  }, [watchForAddSupport("buildingIdForSupportTicketCreation")]);

  /**
   * get building list according to property
   *  */

  const getAllBuildings = async (companyUserId: string) => {
    const responseData = await allProperties(companyUserId);
    const buildingData: { label: string; value: string }[] = [];
    if (responseData?.data?.success) {
      responseData.data.data.forEach((item: IPropertyData) => {
        buildingData.push({
          value: item._id,
          label: item.building_name,
        });
      });

      setBuildingList(buildingData);
    }
  };

  // get building list according to property

  const getAllUnitsForBuilding = async (buildingId: string) => {
    const responseData = await unitlist(buildingId);
    const unitData: { label: string; value: string }[] = [];
    if (responseData?.data?.success) {
      responseData.data.data.forEach((item: IShowUnit) => {
        unitData.push({
          value: item._id,
          label: String(item.unit),
        });
      });
      setUnitList(unitData);
    }
  };

  // get properties list
  const getPropertyListData = async () => {
    const responseData = await getPropertyList();
    const propertyData: { label: string; value: string }[] = [];
    if (responseData?.data?.success) {
      propertyData.push({
        value: "All",
        label: "Property: All",
      });
      responseData.data.data.forEach((item: IPropertyInformation) => {
        propertyData.push({
          value: item._id,
          label: `${item.first_name} ${item.last_name ? item.last_name : ""}`,
        });
      });
      setPropertyList(propertyData);
    }
  };

  const fetchSupportTicketCount = async () => {
    const supportTicketCountData = await getSupportTicketCount();
    if (supportTicketCountData.data.success) {
      if (supportTicketCountData.data.data) {
        setSupportTicketCount(supportTicketCountData.data.data[0]);
      }
    }
  };

  const fetchSupportTicket = useCallback(
    async (
      firstLoad: boolean,
      searchDataBar?: string,
      status?: string
    ): Promise<void> => {
      setLoading(true);

      const offset: number = firstLoad ? 0 : supportTicket.offset;
      let supportList = {} as ISupportTicketList;
      if (supportTicket.offset !== supportTicket.prevOffset || firstLoad) {
        const offsetVal = firstLoad ? 0 : offset;
        const options: ISearchAndStatus = {
          propertyId:
            getValues() && getValues().companyId && getValues().companyId.value
              ? getValues() && getValues().companyId.value === "All"
                ? ""
                : getValues() && getValues().companyId.value
              : "",
          status:
            getValues() && getValues().statusType
              ? getValues().statusType === "All"
                ? ""
                : getValues().statusType
              : "",

          sortByDate:
            getValues() && getValues().sortByDate
              ? Number(getValues().sortByDate)
              : 1,
          user_id: authReducer?.authData?._id,
          searchData: searchDataBar?.length ? searchDataBar : "",
        };

        if (status) {
          options.status = status === "All" ? "" : status;
          supportList = await getSupportTicket(offsetVal, options);
        } else {
          supportList = await getSupportTicket(offsetVal, options);
        }
        if (supportList?.data?.success) {
          const limit = 10;
          if (supportList?.data?.success) {
            setLoading(false);
            setSupportTicket((prevState) => ({
              ...prevState,
              prevOffset: firstLoad ? 0 : prevState.offset,
              offset: firstLoad ? limit : prevState.offset + limit,
              list: firstLoad
                ? supportList?.data?.data
                : prevState.list.concat(supportList?.data?.data),
              hasMore: true,
            }));
          } else {
            setLoading(false);
          }
        }
      }
    },
    [supportTicket.offset, supportTicket.prevOffset]
  );

  /**
   *  Search functionality
   */

  const searchFilter = debounce(async (searchText?: string) => {
    if (isEmpty(searchText)) {
      setSearchText("");
    }

    try {
      if (searchText) {
        setSearchText(searchText);

        await fetchSupportTicket(true, searchText);
      } else {
        searchText !== undefined && fetchSupportTicket(true, "");
      }
    } catch (error) {
      console.log("Error", Error);
      Sentry.captureException(Error);
    }
  }, 300);

  // add support ticket
  const addSupportTicketInfo = async (data: IAddSupportTicket) => {
    setLoading(true);
    const params = {
      property_id: data.companyIdForSupportTicketCreation.value,
      unit_id: data.unitIdForSupportTicketCreation.value,
      name: data.fullName,
      email: data.email,
      message: data.message,
      comment: [],
    };
    const responseData = await addSupportTicket(params);
    if (responseData?.data?.success) {
      toast.success(translation(responseData.data.message));
      fetchSupportTicket(true);
      fetchSupportTicketCount();
    } else {
      toast.error(translation(responseData.data.message));
    }
    setLoading(false);
    resetForAddSupport({
      fullName: "",
      email: "",
      companyIdForSupportTicketCreation: {},
      buildingIdForSupportTicketCreation: {},
      unitIdForSupportTicketCreation: {},
      message: "",
    });
    showSupportTicketModal(false);
  };

  /**
   * update support ticket
   */

  const updateSupportTicketInfo = async (data: IUpdateSupportTicket) => {
    setUploadLoading(true);
    const params = {
      status: data.issueStatus[0]
        ? data.issueStatus[0]
        : supportTicketDetail.status,
      comment: [data.comment],
      id: data.id ? data.id : supportTicketDetail.item._id,
    };
    const updatedResponse = await updateSupportTicket(params);
    if (updatedResponse?.data?.success) {
      toast.success(translation(updatedResponse.data.message));
      fetchSupportTicket(true, searchTextValue);
      fetchSupportTicketCount();
    } else {
      toast.error(translation(updatedResponse.data.message));
    }
    setUploadLoading(false);
    showSetModal(false);
    resetForUpdateSupport({
      message: "",
      issueStatus: [],
    });
  };

  const intervalCall = async (offset: number, options: ISearchAndStatus) => {
    const downloadFile = await downLoadFile(offset, options);
    let data: any = {};
    if (downloadFile.data.success) {
      setDownloading(false);
      const urlOptions = {
        timeStamp: downloadFile.data.data,
      };
      const callUrlInterval = setInterval(async () => {
        data = await getSupportUrl(urlOptions);
        if (Object.keys(data)?.length) {
          const link = document.createElement("a");
          link.href = data.data.data[0].url;
          link.click();
          clearInterval(callUrlInterval);
        }
      }, 1000);
    }
  };

  const downloadCsv = async (firstLoad: boolean) => {
    setDownloading(true);
    const totalCount = await fetchTotalRecord();
    if (totalCount && totalCount?.data?.success) {
      const offset: number = firstLoad ? 0 : supportTicket.offset;
      const offsetVal = firstLoad ? 0 : offset;
      const options: ISearchAndStatus = {
        propertyId:
          getValues() && getValues().companyId && getValues().companyId.value
            ? getValues() && getValues().companyId.value === "All"
              ? ""
              : getValues() && getValues().companyId.value
            : "",
        status:
          getValues() && getValues().statusType
            ? getValues().statusType === "All"
              ? ""
              : getValues().statusType
            : "",
        sortByDate:
          getValues() && getValues().sortByDate
            ? Number(getValues().sortByDate)
            : 1,
        user_id: authReducer?.authData?._id,
      };
      intervalCall(offsetVal, options);
    } else {
      setDownloading(false);
      toast.error(translation("no_data_found"));
    }
  };
  const changeStatus = (status: string) => {
    setSupportTicketDetail((prevState) => ({
      ...prevState,
      status,
    }));
  };
  const setEmptyValueToUnitAndProperty = async () => {
    // setValue("unitIdForSupportTicketCreation", {});
    // setValue("buildingIdForSupportTicketCreation", {});
    // resetForAddSupport({
    //   ...getValuesForAddSupport,
    //   buildingIdForSupportTicketCreation:{},
    //   unitIdForSupportTicketCreation:{},
    // })
  };
  return (
    <>
      <div className="p-4">
        <Header />
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="theme-color fw-bold">
            {translation("support_ticket")}
          </h2>
          <div className="d-flex">
            <div className="custom-searchbar">
              <CommonSearchBar
                handleChange={searchFilter}
                searchPlaceholderText="search_here"
                isSearch
              />
            </div>
            <button
              className="theme-button primary-btn-outline btn-sm ms-4"
              type="button"
              onClick={() => {
                resetForAddSupport({
                  fullName: "",
                  email: "",
                  buildingIdForSupportTicketCreation: {},
                  companyIdForSupportTicketCreation: propertyList[0],
                  unitIdForSupportTicketCreation: {},
                  message: "",
                });
                showSupportTicketModal(!setSupportTicketModal);
              }}
            >
              {translation("create_ticket")}
            </button>
            <button
              className="theme-button primary-btn btn-sm"
              type="button"
              onClick={() => downloadCsv(true)}
              disabled={downloading}
            >
              {translation("download")}
              {downloading ? (
                <div
                  className="spinner-border text-light ms-2 btn-loader"
                  role="status"
                >
                  <span className="sr-only" />
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <div className="white-card text-start">
              <div className="d-flex align-items-center justify-content-left">
                <img className="me-2 ticket-icon" src={TicketIcon} alt="icon" />
                <p className="op-3 ">{translation("total_ticket")}</p>
              </div>

              <p className="font40">
                {showSupportTicketCount?.totalCount.length
                  ? showSupportTicketCount?.totalCount[0].total
                  : 0}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="white-card text-start">
              <div className="d-flex align-items-center justify-content-left">
                <img className="me-2 ticket-icon" src={TicketIcon} alt="icon" />
                <p className="op-3 ">{translation("pending_ticket")}</p>
              </div>

              <p className="font40">
                {showSupportTicketCount?.pendingCount.length
                  ? showSupportTicketCount?.pendingCount[0].pending
                  : 0}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="white-card text-start">
              <div className="d-flex align-items-center justify-content-left">
                <img className="me-2 ticket-icon" src={TicketIcon} alt="icon" />
                <p className="op-3 ">{translation("inprogress_ticket")}</p>
              </div>

              <p className="font40">
                {showSupportTicketCount?.inProgressCount.length
                  ? showSupportTicketCount?.inProgressCount[0].in_progress
                  : 0}
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="white-card text-start">
              <div className="d-flex align-items-center justify-content-left">
                <img className="me-2 ticket-icon" src={TicketIcon} alt="icon" />
                <p className="op-3 ">{translation("resolved_ticket")}</p>
              </div>

              <p className="font40">
                {showSupportTicketCount?.resolvedCount.length
                  ? showSupportTicketCount?.resolvedCount[0].resolved
                  : 0}
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-4">
            <CommonMultiSearchSelect
              className="custom-select-multi"
              aria-label="Default select example"
              placeholder={translation("select_property_dropdown")}
              options={propertyList}
              control={control}
              name="companyId"
              disabled={false}
              isSearchable
              onChange={() => fetchSupportTicket(true)}
            />
          </div>
          <div className="col-md-4">
            <CommonSelect
              className="form-select border-0"
              aria-label="Default select example"
              dropDownData={Status}
              error={errors?.statusType}
              control={control}
              name="statusType"
              multipleSelect={false}
              firstOption={translation("select_status")}
              disabled={false}
              onChange={(value) =>
                fetchSupportTicket(
                  true,
                  searchTextValue ? searchTextValue : "",
                  value
                )
              }
            />
          </div>
          <div className="col-md-4">
            <CommonSelect
              className="form-select border-0"
              aria-label="Default select example"
              dropDownData={dateSortingOption}
              error={errors?.sortByDate}
              control={control}
              name="sortByDate"
              multipleSelect={false}
              firstOption={translation("select_sort_type")}
              disabled={false}
              onChange={() =>
                fetchSupportTicket(
                  true,
                  searchTextValue ? searchTextValue : "",
                  getValues().statusType
                )
              }
            />
          </div>
        </div>

        <div className="table-responsive mt-3">
          <InfiniteScroll
            dataLength={supportTicket?.list?.length}
            next={() =>
              fetchSupportTicket(false, searchTextValue ? searchTextValue : "")
            }
            hasMore={supportTicket?.hasMore}
            loader=""
            endMessage={
              <p style={{ textAlign: "center" }}>
                {supportTicket?.list?.length > 0 ? (
                  <div>{translation("infinite_scroll_end")}</div>
                ) : (
                  <div className="auth-msg error">
                    {translation("no_company_found")}
                  </div>
                )}
              </p>
            }
            scrollableTarget="scrollableDiv"
          >
            <div id="scrollableDiv" style={{ height: 500, overflow: "auto" }}>
              <table className="w-100 custom-table table">
                <thead>
                  <tr>
                    <td className="bd-top-radius-left">
                      {translation("user")}
                    </td>
                    <td>{translation("message")}</td>
                    <td>
                      {translation("property_as_heading")}
                      {/* <img src={DownIcon} alt="icon" className="down-icon" /> */}
                    </td>
                    <td style={{ whiteSpace: "nowrap", minWidth: "150px" }}>
                      {" "}
                      {translation("date_placeholder")}
                    </td>
                    <td> {translation("status_header")}</td>
                    <td className="bd-top-radius-right">
                      {" "}
                      {translation("action")}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {supportTicket && supportTicket.list.length > 0 ? (
                    supportTicket?.list?.map(
                      (item: ISupportTicket, index: number) => (
                        <tr>
                          <td>{item.name}</td>
                          <td>
                            <p className="message-table">{item.message}</p>
                          </td>
                          <td>{`${item.propertyInfo.first_name} ${
                            item.propertyInfo.last_name
                              ? item.propertyInfo.last_name
                              : ""
                          }`}</td>

                          <td>{`${moment(item?.created_ts.split("T")[0]).format(
                            "MM-DD-YYYY"
                          )}`}</td>
                          <td className="sucess-color margin-common-remove">
                            <CommonSelect
                              className="form-select border-0 yellow"
                              aria-label="Default select example"
                              dropDownData={StatusType}
                              error={errors?.StatusType}
                              control={control}
                              name={`issueStatus${index}`}
                              multipleSelect={false}
                              firstOption={translation("select_status")}
                              onChange={(value) => {
                                updateSupportTicketInfo({
                                  issueStatus: [value],
                                  comment: "",
                                  id: item._id,
                                });
                              }}
                              from={translation("support_panel")}
                              value={item?.status}
                            />
                          </td>
                          <td className="sucess-color">
                            <button
                              type="button"
                              className="theme-button black-btn btn-sm"
                              onClick={() => {
                                setSupportTicketDetail({
                                  item,
                                  index,
                                  status: item.status,
                                });
                                resetForUpdateSupport({ issueStatus: [] });
                                showSetModal(!setModal);
                              }}
                            >
                              {translation("view_detail")}
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  ) : loading ? (
                    <tr>
                      <td colSpan={7}>
                        <div className="item-center">
                          {" "}
                          <Loading />{" "}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="danger-color text-center">
                          {translation("no_support_ticket_found")}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
        </div>
      </div>

      {setModal ? (
        <div className="modal modal-custom-show view-detail-modal">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <form
              onSubmit={handleSubmitForUpdateSupport((data) =>
                updateSupportTicketInfo(data as IUpdateSupportTicket)
              )}
            >
              <div className="modal-content">
                <div className="modal-header pt-4">
                  <div className="d-flex align-items-center w-100">
                    <h2 className="modal-title me-5 colorPrimary">
                      Ticket #
                      {supportTicketDetail?.item?.ticket_id?.length
                        ? supportTicketDetail?.item?.ticket_id
                        : ""}
                    </h2>
                    <div className="margin-common-remove me-4 w-100">
                      <CommonSelect
                        className="form-select border-0"
                        aria-label="Default select example"
                        dropDownData={StatusType}
                        error={errorsForUpdateSupport?.issueStatus}
                        control={controlForUpdateSupport}
                        name={`issueStatus${supportTicketDetail.index}`}
                        multipleSelect={false}
                        firstOption={translation("select_status")}
                        disabled={false}
                        // defaultValue={supportTicketDetail.item.status}
                        onChange={(value) => {
                          changeStatus(value);
                        }}
                        from={translation("support_panel")}
                        value={
                          getValuesForUpdateSupport() &&
                          getValuesForUpdateSupport()?.issueStatus?.length >
                            0 &&
                          getValuesForUpdateSupport().issueStatus.index
                            ? getValuesForUpdateSupport().issueStatus.index
                            : supportTicketDetail.status
                        }
                      />
                    </div>
                  </div>

                  <p className="w-50">{`${moment(
                    supportTicketDetail.item.created_ts
                  ).format("MM-DD-YYYY hh:mm A")}`}</p>
                  <button
                    type="button"
                    className="btn-close ticket-create-position"
                    onClick={() => {
                      showSetModal(false);
                      resetForUpdateSupport({
                        message: "",
                        issueStatus: [],
                      });
                    }}
                  >
                    <img src={CloseIcon} alt="icon" />
                  </button>
                </div>

                <div className="modal-body">
                  <div className="ticket-user-info">
                    <p>
                      <span> {translation("user")}</span>{" "}
                      {supportTicketDetail.item.name}
                    </p>
                    <p>
                      <span>{translation("email")}</span>{" "}
                      {supportTicketDetail.item.email}
                    </p>
                    <p>
                      <span>{translation("propertyName")}</span>
                      {`${supportTicketDetail.item.propertyInfo.first_name} ${
                        supportTicketDetail.item.propertyInfo.last_name
                          ? supportTicketDetail.item.propertyInfo.last_name
                          : ""
                      }`}
                    </p>
                    <p>
                      <span>{translation("unit")}</span>
                      {supportTicketDetail.item.unitInfo.unit}
                    </p>
                  </div>
                  <div className="ticket-form">
                    <p className="label-form">{translation("message")}</p>
                    <p>{supportTicketDetail.item.message}</p>
                  </div>
                  <div className="ticket-form">
                    {supportTicketDetail.item.comment.length > 0 && (
                      <p className="label-form">{translation("Comment")}</p>
                    )}
                    <div className="comment-height">
                      {supportTicketDetail.item.comment.length > 0 &&
                        supportTicketDetail.item.comment.map((item: string) => (
                          <div className="bb1 py-2">
                            {typeof item === "string" ? (
                              <p>{item}</p>
                            ) : (
                              <>
                                <p>{item[0]}</p>
                                <p className="date-comment-show">
                                  {new Date(item[1]).toLocaleString()}
                                </p>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="ticket-form">
                    <p className="label-form">{translation("Comment")}</p>
                    <div className="form-group">
                      <CommonTextArea
                        control={controlForUpdateSupport}
                        placeHolder="Comment"
                        className="form-control grey-input"
                        name="comment"
                        fieldType="comment"
                        rows={6}
                        error={errorsForUpdateSupport?.comment}
                        maxLength={300}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <button className="theme-button primary-btn" type="submit">
                      {translation("submit")}

                      {updateLoading ? (
                        <div
                          className="spinner-border text-light ms-2 btn-loader"
                          role="status"
                        >
                          <span className="sr-only" />
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className="theme-button black-btn-outline ms-2"
                      type="button"
                      onClick={() => {
                        showSetModal(false);
                        resetForUpdateSupport({
                          message: "",
                          issueStatus: [],
                        });
                      }}
                    >
                      {translation("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}

      {setSupportTicketModal ? (
        <div className="modal modal-custom-show view-detail-modal">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h2> {translation("add_support_ticket")} </h2>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    showSupportTicketModal(false);
                    resetForAddSupport({
                      fullName: "",
                      email: "",
                      buildingIdForSupportTicketCreation: {},
                      companyIdForSupportTicketCreation: {},
                      unitIdForSupportTicketCreation: {},
                      message: "",
                    });
                  }}
                >
                  <img src={CloseIcon} alt="icon" />
                </button>
              </div>

              <div className="modal-body">
                <form
                  onSubmit={handleSubmitForAddSupport((data) =>
                    addSupportTicketInfo(data as IAddSupportTicket)
                  )}
                >
                  <div className="white-card mt-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="left-icon-input">
                            <CommonTextBox
                              type="text"
                              control={controlForAddSupport}
                              placeHolder="Full Name"
                              className="form-control grey-input"
                              name="fullName"
                              fieldType="input"
                              error={errorForAddSupport?.fullName}
                            />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28.421"
                                height="30"
                                viewBox="0 0 28.421 30"
                              >
                                <g id="bx-user" opacity="0.5">
                                  <path
                                    id="Path_41"
                                    data-name="Path 41"
                                    d="M22.5,10.5A4.5,4.5,0,1,1,18,6,4.5,4.5,0,0,1,22.5,10.5Z"
                                    transform="translate(-3.789 -3.25)"
                                    fill="none"
                                  />
                                  <path
                                    id="Path_42"
                                    data-name="Path 42"
                                    d="M18.711,3a7.895,7.895,0,1,0,7.895,7.895A7.9,7.9,0,0,0,18.711,3Zm0,12.632a4.737,4.737,0,1,1,4.737-4.737A4.743,4.743,0,0,1,18.711,15.632ZM32.921,33V31.421A11.067,11.067,0,0,0,21.868,20.368H15.553A11.066,11.066,0,0,0,4.5,31.421V33H7.658V31.421a7.9,7.9,0,0,1,7.895-7.895h6.316a7.9,7.9,0,0,1,7.895,7.895V33Z"
                                    transform="translate(-4.5 -3)"
                                  />
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="left-icon-input">
                            <CommonTextBox
                              type="text"
                              control={controlForAddSupport}
                              placeHolder="Email Address"
                              className="form-control grey-input"
                              name="email"
                              fieldType="input"
                              error={errorForAddSupport?.email}
                            />
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="31.273"
                                viewBox="0 0 40 31.273"
                              >
                                <g
                                  id="bx-user"
                                  transform="translate(0.45 -2.798)"
                                >
                                  <path
                                    id="Path_41"
                                    data-name="Path 41"
                                    d="M22.5,10.5A4.5,4.5,0,1,1,18,6,4.5,4.5,0,0,1,22.5,10.5Z"
                                    transform="translate(1.55)"
                                    fill="#b6b6b6"
                                  />
                                  <g
                                    id="mail-outline"
                                    transform="translate(-0.45 2.798)"
                                  >
                                    <path
                                      id="Path_292"
                                      data-name="Path 292"
                                      d="M6.978,5.625H37.523a4.734,4.734,0,0,1,4.727,4.728V32.171A4.734,4.734,0,0,1,37.524,36.9H6.978A4.734,4.734,0,0,1,2.25,32.171V10.353A4.733,4.733,0,0,1,6.978,5.625ZM37.523,34.716a2.549,2.549,0,0,0,2.545-2.545V10.353a2.549,2.549,0,0,0-2.545-2.546H6.978a2.549,2.549,0,0,0-2.546,2.546V32.171a2.549,2.549,0,0,0,2.546,2.545Z"
                                      transform="translate(-2.25 -5.625)"
                                      fill="#b6b6b6"
                                    />
                                    <path
                                      id="Path_293"
                                      data-name="Path 293"
                                      d="M21.419,21.7a1.047,1.047,0,0,1-.593-.185L7.236,12.19a1.155,1.155,0,0,1-.308-1.558,1.053,1.053,0,0,1,1.495-.321l13,8.921,13-8.921a1.053,1.053,0,0,1,1.495.321A1.155,1.155,0,0,1,35.6,12.19l-13.59,9.329A1.047,1.047,0,0,1,21.419,21.7Z"
                                      transform="translate(-1.419 -4.065) "
                                      fill="#b6b6b6"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <CommonMultiSearchSelect
                          className="custom-select-multi height-change"
                          aria-label="Default select example"
                          placeholder={translation("select_property_dropdown")}
                          options={propertyList}
                          control={controlForAddSupport}
                          name="companyIdForSupportTicketCreation"
                          disabled={false}
                          isSearchable
                          errors={
                            errorForAddSupport?.companyIdForSupportTicketCreation
                          }
                        />
                      </div>

                      <div className="col-md-6">
                        <CommonMultiSearchSelect
                          className="custom-select-multi height-change"
                          aria-label="Default select example"
                          placeholder={translation("select_building")}
                          options={buildingList}
                          control={controlForAddSupport}
                          name="buildingIdForSupportTicketCreation"
                          disabled={false}
                          isSearchable
                          value={
                            watchForAddSupport(
                              "buildingIdForSupportTicketCreation"
                            )?.value
                          }
                          errors={
                            errorForAddSupport?.buildingIdForSupportTicketCreation
                          }
                        />
                      </div>

                      <div className="col-md-6">
                        <CommonMultiSearchSelect
                          className="custom-select-multi height-change"
                          aria-label="Default select example"
                          placeholder={translation("select_unit")}
                          options={unitList}
                          control={controlForAddSupport}
                          name="unitIdForSupportTicketCreation"
                          disabled={false}
                          isSearchable
                          value={
                            watchForAddSupport("unitIdForSupportTicketCreation")
                              ?.value
                          }
                          errors={
                            errorForAddSupport?.unitIdForSupportTicketCreation
                          }
                        />
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <CommonTextArea
                            control={controlForAddSupport}
                            placeHolder="Message"
                            className="form-control grey-input"
                            name="message"
                            fieldType="message"
                            rows={6}
                            error={errorForAddSupport?.message}
                            maxLength={300}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        className="theme-button primary-btn"
                        onClick={() => setEmptyValueToUnitAndProperty()}
                        type="submit"
                      >
                        {translation("submit")}
                        {loading ? (
                          <div
                            className="spinner-border text-light ms-2 btn-loader"
                            role="status"
                          >
                            <span className="sr-only" />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>
                      <button
                        className="theme-button black-btn-outline ms-2"
                        type="button"
                        onClick={() => {
                          showSupportTicketModal(false);
                          resetForAddSupport({
                            fullName: "",
                            email: "",
                            buildingIdForSupportTicketCreation: {},
                            companyIdForSupportTicketCreation: {},
                            unitIdForSupportTicketCreation: {},
                            message: "",
                          });
                        }}
                      >
                        {translation("cancel")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SupportTicket;
