import { http } from "../utils/http";

import endpoint from "../constants/endPoint";
import {
  ICreateSupportTicket,
  IUpdateSupportTicketInfo,
} from "../interfaces/Support";

/**
 *
 * get resident pending count
 */
export const getSupportTicketCount = () => {
  return http.get(`${endpoint.Support.GETCOUNT}`);
};

/**
 *
 * get resident pending count
 */
export const getSupportTicket = (offset?: number, data?: any) => {
  const queryParams: string[] = [];
  if (offset || offset === 0) queryParams.push(`offset=${offset}`);
  if (data) {
    Object.keys(data).forEach((key) => {
      queryParams.push(`${key}=${data[key]}`);
    });
  }
  return http.get(
    `${endpoint.Support.GETSUPPORTICKET}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 *
 * update support ticket
 */
export const updateSupportTicket = (data: IUpdateSupportTicketInfo) => {
  return http.post(`${endpoint.Support.UPDATESUPPORTICKET}`, data);
};
/**
 *
 * add support ticket
 */
export const addSupportTicket = (data: ICreateSupportTicket) => {
  return http.post(`${endpoint.Support.ADDSUPPORTICKET}`, data);
};

/**
 * get Total spport record
 */
export const fetchTotalRecord = () => {
  return http.get(`${endpoint.Support.FETCHTOTALRECORD}`);
};

/**
 * get Total spport record
 */
export const downLoadFile = (offset?: number, data?: any) => {
  // getSupportUrl
  const queryParams: string[] = [];
  if (offset || offset === 0) queryParams.push(`offset=${offset}`);
  if (data) {
    Object.keys(data).forEach((key) => {
      queryParams.push(`${key}=${data[key]}`);
    });
  }

  return http.get(
    `${endpoint.Support.DOWNLOADFILE}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

export const getSupportUrl = (data?: any) => {
  const queryParams: string[] = [];
  if (data) {
    Object.keys(data).forEach((key) => {
      queryParams.push(`${key}=${data[key]}`);
    });
  }

  return http.get(
    `${endpoint.Support.GETSUPPORTDATAURL}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};
