import { IAuthReducerData } from "../../interfaces/AuthInterface";
import ActionType from "../../resources/enums/index";
import storage from "../../utils/storage";

const initialState: IAuthReducerData = {
  isRegistered: "",
  isLoggedIn: storage.get("loginDetails") ? true : false,
  authData: storage.get("loginDetails")
    ? JSON.parse(storage.get("loginDetails") as string)
    : {},
  isUpdated: false,
  recordData: {
    unitCount: 0,
    residentCount: 0,
  },
  staffCount: 0,
  notificationCount: 0,
  payment_link: "",
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      storage.set("rtyui", action?.payload?.data);
      return {
        ...state,
        isLoggedIn: true,
        authData: { ...state.authData, ...action?.payload?.data },
      };
    }

    case ActionType.LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        authData: {},
      };
    }
    case ActionType.RECORD_COUNT: {
      return {
        ...state,
        isLoggedIn: true,
        recordData: action.payload,
      };
    }
    case ActionType.STAFF_COUNT: {
      return {
        ...state,
        isLoggedIn: true,
        staffCount: action.payload,
      };
    }

    case ActionType.STAFF_ROLE: {
      return {
        ...state,
        isLoggedIn: true,
        roleType: action.payload,
      };
    }

    case ActionType.NOTIFICATION_COUNT: {
      return {
        ...state,
        isLoggedIn: true,
        notificationCount: action.payload,
      };
    }
    case ActionType.SELECT_API_KEY: {
      return {
        ...state,
        isLoggedIn: true,
        apiList: action.payload,
      };
    }
    case ActionType.EMPTY_API_KEY: {
      return {
        ...state,
        isLoggedIn: true,
        apiList: [],
      };
    }
    case ActionType.PAYMENT_LINK: {
      return {
        ...state,
        isLoggedIn: true,
        payment_link: action.payload,
      };
    }
    case ActionType.CURRENT_COMPANY_ID: {
      return {
        ...state,
        isLoggedIn: true,
        currentCompanyId: action.payload,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
