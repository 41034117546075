import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { forgetPassword } from "../../services/auth";

import { IForgetPassword } from "../../interfaces/AuthInterface";
import { admin, user } from "../../constants/commonConstants";

import toast from "../../utils/toastMessage";
import CommonRecover from "./CommonRecover";
import ROUTES from "../../constants/routes";

import LoginRight from "../../assets/images/loginright.png";
import LoginLeft from "../../assets/images/login-left.png";
import userLoginImage from "../../assets/images/userloginright.png";

import "./userAuth.scss";

const loginType = process.env.REACT_APP_USER_TYPE;
const Recover: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   *  Recover password
   */
  const onRecover = async (data: IForgetPassword) => {
    setLoading(true);
    const responseData = await forgetPassword(data);
    if (responseData?.data?.success && responseData?.data?.data?.encOtp) {
      setLoading(false);
      const encOtp = responseData?.data?.data?.encOtp;
      navigate(ROUTES.VERIFY_OTP, {
        state: {
          encOtp,
          email: data.email,
        },
      });
    } else {
      setLoading(false);
      toast.error(translation(`${responseData?.data?.message}`));
    }
  };
  return (
    <div className="row m-0">
      <div className="col-md-5 p-0">
        <div
          className="loginleft p-4"
          style={
            loginType === admin ? { backgroundImage: `url(${LoginLeft})` } : {}
          }
        >
          {loginType === user ? (
            <CommonRecover
              type={loginType as string}
              getRecover={onRecover}
              loading={loading}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-md-7 p-0">
        <div
          className="loginright"
          style={
            loginType === admin
              ? { backgroundImage: `url(${LoginRight})` }
              : { backgroundImage: `url(${userLoginImage})` }
          }
        >
          {loginType === admin ? (
            <CommonRecover
              type={loginType as string}
              getRecover={onRecover}
              loading={loading}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Recover;
