import { http } from "../utils/http";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";
import { ISendEmailParams } from "../interfaces/EmailInterface";

/**
 * get Building list
 */
export const getBuildingList = (id: string) => {
  return http.get(`${endpoint.Email.GETBUILDINGLIST.replace(":id", id)}`);
};

/**
 *  send email
 */

export const sendEmailData = (data: ISendEmailParams): Promise<ApiResponse> => {
  return http.post(`${endpoint.Email.SENDEMAILS}`, data);
};
