enum ActionType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  RECORD_COUNT = "RECORD_COUNT",
  STAFF_ROLE = "STAFF_ROLE",
  NOTIFICATION_COUNT = "NOTIFICATION_COUNT",
  PAYMENT_LINK = "PAYMENT_LINK",
  SELECT_API_KEY = "SELECT_API_KEY",
  EMPTY_API_KEY = "EMPTY_API_KEY",
  STAFF_COUNT = "STAFF_COUNT",
  CURRENT_COMPANY_ID = "CURRENT_COMPANY_ID",
  RESIDENT_COUNT_FOR_PROPERTY = "RESIDENT_COUNT_FOR_PROPERTY",
}
export default ActionType;
