import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { IFileType, ISignedUrls } from "../../interfaces/EmailInterface";
import { attachmentType, maxFileSize } from "../../constants/commonConstants";
import toast from "../../utils/toastMessage";
import { getSignedUrl } from "../../services/property";

interface IAttachments {
  handleGetAttachments: () => Promise<
    ({ url: string; response: any } | undefined)[]
  >;
  setAttachFiles: (items: IFileType[]) => void;
  attachFile: IFileType[];
}

const useMultipleAttachment = (
  attachFilesEvent?: FileList | null
): IAttachments => {
  const { t: translation } = useTranslation();
  const [attachFile, setAttachFiles] = useState<IFileType[]>([]);

  /**
   *   uplode attachfile
   */

  const onAttachedFile = () => {
    const multipleAttachments: IFileType[] = [];

    if (attachFilesEvent && attachFilesEvent.length) {
      for (let i = 0; i < attachFilesEvent.length; i++) {
        const fileType = attachFilesEvent[i].name.split(".").pop();
        if (
          fileType &&
          attachmentType[fileType as keyof typeof attachmentType]
        ) {
          if (attachFilesEvent[i].size <= maxFileSize) {
            if (attachFile.length + multipleAttachments.length < 10) {
              multipleAttachments.push({
                fileItem: attachFilesEvent[i],
                type: fileType,
              });
            } else {
              toast.error(translation("cannot_attach"));
            }
          } else {
            toast.error(translation("file_size_exceed"));
          }
        } else {
          toast.error(translation("incorrect_file_type"));
        }
      }
    }
    setAttachFiles([...attachFile, ...multipleAttachments]);
  };

  useEffect(() => {
    onAttachedFile();
  }, [attachFilesEvent]);

  const handleSignedUrl = async (filePathName: string, file: Blob) => {
    try {
      const encodedFilePathName = encodeURIComponent(filePathName);
      const filePath = `email_attachments/${encodedFilePathName}`;
      const presignedUrl = await getSignedUrl({
        filePath,
        fileFormat: file.type,
      });

      if (presignedUrl && presignedUrl.data && presignedUrl.data.data) {
        const signedObj: ISignedUrls = { url: presignedUrl.data.data, file };
        return signedObj;
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      return null;
    }
  };

  const pushFileToS3 = async (signedUrl: string, file: Blob) => {
    const headers = {
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    };

    try {
      const response = await axios.put(signedUrl, file, { headers });
      return response.data;
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      return null;
    }
  };

  const handleGetAttachments = async () => {
    let signedUrlsResult: ({ url: string; response: any } | undefined)[] = [];
    if (attachFile.length > 0) {
      const processedFileInfo = attachFile.map((item) => {
        const fileInfo = item.fileItem as File;
        const fileNameArr = fileInfo.name.split(".");
        const fileExt = fileNameArr.pop();
        const fileNameWithoutExt = fileNameArr.join("-").replaceAll(" ", "-");
        const filePath = `${fileNameWithoutExt}-${new Date().getTime()}.${fileExt}`;
        return {
          file: item.fileItem,
          filePath,
          fileNameWithoutExt,
        };
      });

      const preSignedUrls = await Promise.all(
        processedFileInfo.map(({ file, filePath }) =>
          handleSignedUrl(filePath, file)
        )
      );

      signedUrlsResult = await Promise.all(
        preSignedUrls.map(async (item) => {
          if (item) {
            return {
              url: item.url.split("?")[0],
              response: await pushFileToS3(item.url, item.file),
            };
          }
        })
      );
    }

    return signedUrlsResult;
  };

  return {
    handleGetAttachments,
    setAttachFiles,
    attachFile,
  };
};

export default useMultipleAttachment;
