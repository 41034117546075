import React from "react";
import "./Loader.scss";

const Loading: React.FC = () => (
  <div className="loading-main">
    <div className="loading-main-inner">
      <div className="spinner-border text-info" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
);

export default Loading;
