import React, {
  ChangeEvent,
  FocusEvent,
  WheelEvent,
  KeyboardEvent,
  MouseEventHandler,
} from "react";

import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IProps {
  // type: string;
  // rows:number;
  className: string;
  placeHolder?: string;
  name: string;
  fieldType?: string;
  value?: string | number;
  control: Control;
  inputIcon?: string;
  error?: FieldErrors<FieldValues> | FieldError;
  disabled?: boolean;
  readOnly?: boolean;
  rows?: number;
  maxlength?: number;
  label?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;

  onIconClick?: (event: MouseEventHandler<HTMLButtonElement>) => void;
  onMouseOut?: () => void;
  onMouseEnter?: (e: FocusEvent<HTMLInputElement>) => void;
  onWheel?: (e: WheelEvent<HTMLInputElement>) => void;

  onClick?: () => void;
  defaultValue?: string | number;
  maxLength?: number;
}

const CommonTextArea: React.FC<IProps> = (props) => {
  const { t: translation } = useTranslation();
  const {
    rows,
    //   type,
    className,
    placeHolder,
    name,
    control,
    error,
    label,
    value,
    readOnly,
    disabled = false,
    inputIcon = "",
    onChange,
    //   onKeyDown,
    //   onKeyUp,
    //   onMouseEnter,
    onMouseOut,
    onIconClick,
    //   onWheel,
    onClick,
    //   maxlength,
    defaultValue,
    maxLength,
  } = props;

  return (
    <div className="form-group mb-0" onClick={onClick}>
      <label htmlFor="#">{label}</label>

      <Controller
        render={({ field }) => {
          return (
            <textarea
              {...field}
              name={name}
              // type={type}
              disabled={disabled}
              className={className}
              value={field.value}
              placeholder={placeHolder}
              maxLength={maxLength}
              rows={rows}
              readOnly={readOnly}
              onBlur={() => {
                if (onMouseOut) {
                  field.onBlur();
                  onMouseOut();
                }
              }}
              // onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              //   if (onKeyDown) {
              //     onKeyDown(e);
              //   }
              // }}
              // onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => {
              //   if (onKeyUp) {
              //     onKeyUp(e);
              //   }
              // }}
              // onWheel={(e: WheelEvent<HTMLInputElement>) => {
              //   if (onWheel) {
              //     onWheel(e);
              //   }
              // }}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              // onFocus={(e: FocusEvent<HTMLInputElement>) => {
              //   if (onMouseEnter) {
              //     onMouseEnter(e);
              //   }
              // }}
              defaultValue={defaultValue}
            />
          );
        }}
        defaultValue={value || ""}
        name={name}
        control={control}
      />
      {inputIcon ? (
        <button
          type="submit"
          className="icon-btn align-icon-input"
          onClick={() => onIconClick}
        >
          {/* <IonIcon icon={inputIcon} /> */}
        </button>
      ) : null}

      <div className="auth-msg error">
        <p>
          {error && error?.message ? (
            <>{translation(`${error?.message}`, { field: name })}</>
          ) : null}
        </p>
      </div>
    </div>
  );
};

export default React.memo(CommonTextArea);
