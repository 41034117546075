import { http } from "../utils/http";

import {
  IAddCompany,
  ICompanyDeleteParams,
} from "../interfaces/CompanyInterface";
import { IPropertyId } from "../interfaces/PropertyInterface";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";

/**
 * api call for adding the company/propertyManager
 */
export const addCompany = (data: IAddCompany): Promise<ApiResponse> => {
  return http.post(`${endpoint.Company.ADDCOMPANY}`, data);
};

/**
 * api call for getting the property manager list
 */
export const getPropertyManagers = (
  data: IPropertyId
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Company.GETCOMPANY}${
      data.offset || data.offset === 0 ? `?offset=${data.offset}` : ""
    }${data.propertyId ? `?propertyId=${data.propertyId}` : ""}${
      data.id ? `?id=${data.id}` : ""
    }`
  );
};

/**
 * update company
 */
export const updateCompany = (data: IAddCompany): Promise<ApiResponse> => {
  return http.post(`${endpoint.Company.UPDATECOMPANY}`, data);
};

/**
 * delete company
 */
export const deleteCompany = (
  companyInfo: ICompanyDeleteParams
): Promise<ApiResponse> => {
  return http.post(`${endpoint.Company.DELETECOMPANY}`, companyInfo);
};

/**
 *  get api key
 */

export const getApiKey = (data: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.Company.GETAPIKEY.replace(":id", data)}`);
};
/**
 *  Get record count of staff for a property
 */
export const staffRecordCount = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Company.STAFFREORDCOUNT.replace(":company_user_id", data)}`
  );
};
