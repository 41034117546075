import React, { useState, ChangeEvent } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import checkPasswordStrength from "check-password-strength";

import { IVerifyPassword } from "../../interfaces/AuthInterface";
import { PasswordStrengthBarResult } from "../../interfaces/CommonInterface";

import { resetPasswordValidationSchema } from "../../utils/validationSchema";
import { admin, user } from "../../constants/commonConstants";

import CommonTextBox from "../../components/formElements/CommonTextBox";
import ROUTES from "../../constants/routes";

import Logo from "../../assets/images/logo.png";

import "./userAuth.scss";

const loginType = process.env.REACT_APP_USER_TYPE;

interface ILoginProps {
  type: string;
  loading: boolean;
  upDateNewPassword: (value: IVerifyPassword) => void;
}

const CommonNewPassword: React.FC<ILoginProps> = (props) => {
  const { type, upDateNewPassword, loading } = props;

  const { t: translation } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [conformPasswordToggle, setConformPasswordToggle] = useState(false);
  const [passwordStrengthBarOut, setPasswordStrengthBarOut] = useState(false);
  const [typeResult, setTypeResult] = useState<
    PasswordStrengthBarResult | any
  >();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordValidationSchema(translation)),
  });

  /**
   *  update the password
   */
  const onUpdatePassword = async (data: IVerifyPassword) => {
    upDateNewPassword(data);
  };

  /**
   * password strength bar
   */
  let strength;
  const passwordStrengthBar = (e: ChangeEvent<HTMLInputElement>) => {
    const passwordmatches = e.target.value;
    strength = checkPasswordStrength.passwordStrength(passwordmatches);
    setTypeResult({ id: strength.id });
  };

  const onmouseOut = () => {
    setPasswordStrengthBarOut(false);
    setTypeResult({});
  };
  const onmouseEnter = () => {
    setPasswordStrengthBarOut(true);
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onUpdatePassword(data as IVerifyPassword)
      )}
    >
      <div className={loginType === admin ? "inner" : ""}>
        {type === user ? (
          <>
            <img src={Logo} alt="logo" className="logo-left-set" />
            <div className="resident-head">
              <h2>{translation("create_new_password")}</h2>
              <p> {translation("enter_add_recover_passwprd")}</p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-center white-color mb-3">
              {translation("create_new_password")}
            </h2>
            <p className="text-center white-color mb-3">
              {translation("enter_add_recover_passwprd")}
            </p>
          </>
        )}
        <div className="form-group">
          <div className="double-icon-input">
            <CommonTextBox
              control={control}
              type={!toggle ? "password" : "text"}
              placeHolder={translation("password_placeholder")}
              className="form-control"
              name="password"
              error={errors?.password}
              fieldType="input"
              onChange={passwordStrengthBar}
              onMouseOut={onmouseOut}
              onMouseEnter={onmouseEnter}
            />

            <span className="left-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.042"
                height="30"
                viewBox="0 0 30.042 30"
              >
                <g id="password" opacity="0.5">
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M22.607,2.25a9.643,9.643,0,0,0-9.643,9.643,9.5,9.5,0,0,0,.418,2.8L2.25,25.821V32.25H8.679L19.811,21.118A9.643,9.643,0,0,0,32.25,12.685a9.4,9.4,0,0,0-1.768-6.429A9.643,9.643,0,0,0,22.607,2.25Zm0,17.143a7.5,7.5,0,0,1-2.143-.321L19.232,18.7l-.91.91-3.408,3.408L13.4,21.536l-1.51,1.51,1.478,1.478-1.7,1.7L10.189,24.75l-1.51,1.51,1.478,1.478L7.789,30.107h-3.4v-3.4l10.5-10.532.91-.91-.31-1.018a7.649,7.649,0,0,1,3.643-9.043,7.5,7.5,0,0,1,10.971,6.429,7.168,7.168,0,0,1-1.168,4.286,7.5,7.5,0,0,1-6.332,3.471Z"
                    transform="translate(-2.25 -2.25)"
                  />
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M27,11.25A2.25,2.25,0,1,1,24.75,9,2.25,2.25,0,0,1,27,11.25Z"
                    transform="translate(-3.375 -2.598)"
                  />
                </g>
              </svg>
            </span>
            <span className="right-icon">
              <button
                type="button"
                className="theme-button trans-btn"
                onClick={() => setToggle(!toggle)}
              >
                {!toggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30.01"
                    viewBox="0 0 40 30.01"
                  >
                    <path
                      id="np_view-password_4287676_000000"
                      d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                      transform="translate(-6.263 -17.188)"
                      opacity={0.3}
                    />
                  </svg>
                )}
                {toggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24"
                    viewBox="0 0 30 24"
                  >
                    <path
                      id="Show"
                      opacity={0.3}
                      d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                      transform="translate(-2 -4)"
                      fillRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </span>
          </div>
          {passwordStrengthBarOut ? (
            <div className="password-bar-text">
              <div className="color-indicators">
                {typeResult && typeResult?.id >= 0 ? <span /> : ""}
                {typeResult && typeResult?.id >= 1 ? <span /> : ""}

                {typeResult && typeResult?.id >= 2 ? <span /> : ""}

                {typeResult && typeResult?.id >= 3 ? <span /> : ""}
              </div>
              {typeResult && typeResult?.id === 0 ? (
                <p className="colorDanger">{translation("weak")}</p>
              ) : typeResult && typeResult?.id === 1 ? (
                <p className="colorWarning">{translation("average")}</p>
              ) : typeResult && typeResult?.id === 2 ? (
                <p className="colorGood">{translation("good")}</p>
              ) : typeResult && typeResult?.id === 3 ? (
                <p className="colorStrong">{translation("strong")}</p>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="form-group">
          <div className="double-icon-input">
            <CommonTextBox
              control={control}
              type={!conformPasswordToggle ? "password" : "text"}
              placeHolder={translation("confirm_password_placeholder")}
              className="form-control"
              name="confirm_password"
              error={errors?.confirm_password}
              fieldType="input"
            />
            <span className="left-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.042"
                height="30"
                viewBox="0 0 30.042 30"
              >
                <g id="password" opacity="0.5">
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M22.607,2.25a9.643,9.643,0,0,0-9.643,9.643,9.5,9.5,0,0,0,.418,2.8L2.25,25.821V32.25H8.679L19.811,21.118A9.643,9.643,0,0,0,32.25,12.685a9.4,9.4,0,0,0-1.768-6.429A9.643,9.643,0,0,0,22.607,2.25Zm0,17.143a7.5,7.5,0,0,1-2.143-.321L19.232,18.7l-.91.91-3.408,3.408L13.4,21.536l-1.51,1.51,1.478,1.478-1.7,1.7L10.189,24.75l-1.51,1.51,1.478,1.478L7.789,30.107h-3.4v-3.4l10.5-10.532.91-.91-.31-1.018a7.649,7.649,0,0,1,3.643-9.043,7.5,7.5,0,0,1,10.971,6.429,7.168,7.168,0,0,1-1.168,4.286,7.5,7.5,0,0,1-6.332,3.471Z"
                    transform="translate(-2.25 -2.25)"
                  />
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M27,11.25A2.25,2.25,0,1,1,24.75,9,2.25,2.25,0,0,1,27,11.25Z"
                    transform="translate(-3.375 -2.598)"
                  />
                </g>
              </svg>
            </span>
            <span className="right-icon">
              <button
                type="button"
                className="theme-button trans-btn"
                onClick={() => setConformPasswordToggle(!conformPasswordToggle)}
              >
                {!conformPasswordToggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30.01"
                    viewBox="0 0 40 30.01"
                  >
                    <path
                      id="np_view-password_4287676_000000"
                      d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                      transform="translate(-6.263 -17.188)"
                      opacity={0.3}
                    />
                  </svg>
                )}
                {conformPasswordToggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24"
                    viewBox="0 0 30 24"
                  >
                    <path
                      id="Show"
                      opacity={0.3}
                      d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                      transform="translate(-2 -4)"
                      fillRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </span>
          </div>
        </div>

        <button type="submit" className="theme-button primary-btn mb-3 w-100">
          {translation("update_password")}
          {loading ? (
            <div
              className="spinner-border text-light ms-2 btn-loader"
              role="status"
            >
              <span className="sr-only" />
            </div>
          ) : (
            ""
          )}
        </button>
        <p className="text-center">
          {translation("remember_password")}
          <Link to={ROUTES.LOGIN}>
            {" "}
            <span className="theme-color">{translation("login_now")}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

export default CommonNewPassword;
