import { http } from "../utils/http";

import { IGetNotifications } from "../interfaces/Notifications";

import endpoint from "../constants/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";

/**
 * Notificatoon count
 */
export const notificationCount = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Notifications.COUNTNOTIFICATIONS.replace(":user_id", data)}`
  );
};

/**
 *
 * Notifications list
 */
export const getNotifications = (
  data: IGetNotifications
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Notifications.NOTIFICATION.replace(
      ":user_id",
      data.user_Id
    )}${`?offset=${data.offset}`}`
  );
};

/**
 * Delete the notification
 */
export const deleteNotifications = (user_Id: string): Promise<ApiResponse> => {
  return http.delete(
    `${endpoint.Notifications.DELETENOTIFICATION.replace(":user_id", user_Id)}`
  );
};

/**
 * Update the notifications
 */
export const updateAllNotification = (
  user_Id: string
): Promise<ApiResponse> => {
  return http.patch(
    `${endpoint.Notifications.UPDATECOUNTNOTIFICATIONS.replace(
      ":user_id",
      user_Id
    )}`
  );
};
