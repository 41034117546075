import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { notificationCount } from "../../services/notifications";

import { IBreadCrumbsData } from "../../interfaces/CommonInterface";
import { IAuthReducerData } from "../../interfaces/AuthInterface";

import { RootState } from "../../redux/reducers/Index";
import {
  admin,
  company,
  leasingStaff,
  manager,
  resident,
  staff,
} from "../../constants/commonConstants";

import ROUTES from "../../constants/routes";
import ActionType from "../../resources/enums";

import home from "../../assets/images/home.svg";
import right from "../../assets/images/right.svg";
import avatar from "../../assets/images/avatar.png";

import "./Header.scss";
import storage from "../../utils/storage";

interface parent {
  parentComponent?: IBreadCrumbsData;
  childComponent?: IBreadCrumbsData;
}

const Header: React.FC<parent> = (props: parent) => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const dispatch = useDispatch();

  const [notificationsCount, setNotificationsCount] = useState<number>();

  /**
   *  logout
   */
  const logOut = () => {
    localStorage.removeItem("token");
    storage.removeAll();
    dispatch({
      type: ActionType.LOGOUT,
    });
  };
  const navigateTo = () => {
    if (props?.parentComponent?.route) {
      navigate(`${props?.parentComponent?.route}`);
    }
  };

  /**
   *  navigate to notification
   */
  const naviagteToNotifications = () => {
    navigate(ROUTES.NOTIFICATIONS);
  };

  /**
   *  get notifications
   */
  const getNotificationsData = useCallback(async () => {
    if (authReducer?.authData?._id) {
      const notifications = await notificationCount(authReducer.authData._id);
      if (notifications?.data?.success) {
        setNotificationsCount(notifications.data.data);
        dispatch({
          type: ActionType.NOTIFICATION_COUNT,
          payload: notifications.data.data,
        });
      }
    }
  }, []);

  useEffect(() => {
    getNotificationsData();
    const interval = setInterval(() => {
      getNotificationsData();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="header mb-4">
      <div className="row align-items-center">
        <div className="col-md-8">
          <div className="white-card d-flex align-items-center">
            {authReducer.authData.account_type === admin && (
              <Link to={ROUTES.COMPANY}>
                {" "}
                <img src={home} alt="" />
              </Link>
            )}
            {authReducer.authData.account_type === company && (
              <Link to={ROUTES.ALL_PROPETIES}>
                {" "}
                <img src={home} alt="" />
              </Link>
            )}
            {authReducer.authData.account_type === resident && (
              <Link to={ROUTES.Resident.RESIDENT_DASHBOARD}>
                {" "}
                <img src={home} alt="" />
              </Link>
            )}
            {authReducer.authData.account_type === staff &&
              authReducer.roleType !== manager &&
              authReducer.roleType !== leasingStaff && (
                <Link to={ROUTES.ALL_PROPETIES}>
                  {" "}
                  <img src={home} alt="" />
                </Link>
              )}
            {authReducer.authData.account_type === staff &&
              (authReducer.roleType === manager ||
                authReducer.roleType === leasingStaff) && (
                <Link to={ROUTES.ALL_PROPETIES}>
                  {" "}
                  <img src={home} alt="" />
                </Link>
              )}

            <ul className="m-0 p-0 d-flex">
              {props && (
                <>
                  {props?.parentComponent && (
                    <>
                      {props.parentComponent?.homeName ? (
                        <Link to={`${props?.parentComponent?.homeRoute}`}>
                          <li className="active">
                            <img className="me-2" src={right} alt="" />
                            {props.parentComponent?.homeName}
                          </li>
                        </Link>
                      ) : (
                        ""
                      )}
                      <li className="active cursorA" onClick={navigateTo}>
                        <img className="me-2" src={right} alt="" />
                        {props.parentComponent?.name}
                      </li>
                    </>
                  )}
                  {props.childComponent && (
                    <li className="active ">
                      <img className="me-2" src={right} alt="" />
                      {props?.childComponent?.name}
                    </li>
                  )}
                  {props?.childComponent?.extendedName && (
                    <li className="active">
                      {props.childComponent && (
                        <img className="me-2" src={right} alt="" />
                      )}
                      {props?.childComponent?.extendedName}
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-around align-items-center">
            <div className="notification-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                onClick={naviagteToNotifications}
              >
                <title>{translation("notifications")}</title>
                <path
                  d="M427.68 351.43C402 320 383.87 304 383.87 217.35 383.87 138 343.35 109.73 310 96c-4.43-1.82-8.6-6-9.95-10.55C294.2 65.54 277.8 48 256 48s-38.21 17.55-44 37.47c-1.35 4.6-5.52 8.71-9.95 10.53-33.39 13.75-73.87 41.92-73.87 121.35C128.13 304 110 320 84.32 351.43 73.68 364.45 83 384 101.61 384h308.88c18.51 0 27.77-19.61 17.19-32.57zM320 384v16a64 64 0 01-128 0v-16"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                />
              </svg>
              {notificationsCount && notificationsCount > 0 ? (
                <div className="notification-show" />
              ) : (
                ""
              )}
            </div>
            <div className="form-group mb-0">
              <div className="dropdown user-dropdown">
                <button
                  className="btn theme-button white-btn bd-50 dropdown-toggle p-0"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      authReducer.authData.image
                        ? authReducer.authData.image
                        : avatar
                    }
                    alt=""
                  />
                  {authReducer.authData.first_name}
                </button>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <Link className="dropdown-item" to={ROUTES.MY_PROFILE}>
                      {translation("my_profile")}
                    </Link>
                  </li>
                  <li onClick={logOut}>
                    <Link className="dropdown-item" to="/">
                      {translation("logout")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
