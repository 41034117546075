import React, { useState, ChangeEvent } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import checkPasswordStrength from "check-password-strength";

import { IUpdatePassword } from "../../interfaces/AuthInterface";
import { PasswordStrengthBarResult } from "../../interfaces/CommonInterface";

import { updatePasswordValidationSchema } from "../../utils/validationSchema";

import CommonTextBox from "../../components/formElements/CommonTextBox";
import toast from "../../utils/toastMessage";

import lock from "../../assets/images/lock.svg";

interface IUpdatePasswordProps {
  getUpdatePassword: (value: IUpdatePassword) => void;
  loading: boolean;
}

const CommonUpdatePassword: React.FC<IUpdatePasswordProps> = (props) => {
  const { getUpdatePassword, loading } = props;
  const { t: translation } = useTranslation();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrengthBarOut, setPasswordStrengthBarOut] = useState(false);
  const [typeResult, setTypeResult] = useState<
    PasswordStrengthBarResult | any
  >();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(updatePasswordValidationSchema(translation)),
  });
  /**
   *  Update the password
   */
  const onUpdatePassword = async (data: IUpdatePassword) => {
    if (data.password === data.confirm_password) {
      setShowOldPassword(false);
      setShowPassword(false);
      setShowConfirmPassword(false);
      reset({
        old_password: "",
        password: "",
        confirm_password: "",
      });
      getUpdatePassword(data);
    } else {
      toast.error(translation("wrong_password"));
    }
  };

  /**
   *  Reest the fields after updating the password
   */
  const resetPassword = async () => {
    reset({
      old_password: "",
      password: "",
      confirm_password: "",
    });
  };

  let strength;

  const passwordStrengthChecker = (e: ChangeEvent<HTMLInputElement>) => {
    const passwordmatches = e.target.value;
    strength = checkPasswordStrength.passwordStrength(passwordmatches);
    setTypeResult({ id: strength.id });
  };

  const onmouseOut = () => {
    setPasswordStrengthBarOut(false);
    setTypeResult({});
  };
  const onmouseEnter = () => {
    setPasswordStrengthBarOut(true);
  };

  return (
    <form
      onSubmit={handleSubmit((data) =>
        onUpdatePassword(data as IUpdatePassword)
      )}
    >
      <h2 className="theme-color fw-bold mb-3">
        {translation("profile_change_password")}
      </h2>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="double-icon-input">
              <CommonTextBox
                control={control}
                type={!showOldPassword ? "password" : "text"}
                placeHolder={translation("old_password_placeholder")}
                className="form-control grey-input"
                name="old_password"
                error={errors?.old_password}
                fieldType="input"
              />
              <span className="left-icon">
                <img src={lock} alt="" />
              </span>
              <span className="right-icon">
                <button
                  type="button"
                  className="theme-button trans-btn"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {" "}
                  {!showOldPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="30.01"
                      viewBox="0 0 40 30.01"
                    >
                      <path
                        id="np_view-password_4287676_000000"
                        d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                        transform="translate(-6.263 -17.188)"
                        opacity={0.3}
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="24"
                      viewBox="0 0 30 24"
                    >
                      <path
                        id="Show"
                        opacity={0.3}
                        d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                        transform="translate(-2 -4)"
                        fillRule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="double-icon-input">
              <CommonTextBox
                control={control}
                type={!showPassword ? "password" : "text"}
                placeHolder={translation("password_placeholder")}
                className="form-control grey-input"
                name="password"
                error={errors?.password}
                fieldType="input"
                onChange={passwordStrengthChecker}
                onMouseOut={onmouseOut}
                onMouseEnter={onmouseEnter}
              />
              <span className="left-icon">
                <img src={lock} alt="" />
              </span>
              <span className="right-icon">
                <button
                  type="button"
                  className="theme-button trans-btn"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="30.01"
                      viewBox="0 0 40 30.01"
                    >
                      <path
                        id="np_view-password_4287676_000000"
                        d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                        transform="translate(-6.263 -17.188)"
                        opacity={0.3}
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="24"
                      viewBox="0 0 30 24"
                    >
                      <path
                        id="Show"
                        opacity={0.3}
                        d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                        transform="translate(-2 -4)"
                        fillRule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </span>
            </div>

            {passwordStrengthBarOut ? (
              <div className="password-bar-text">
                <div className="color-indicators">
                  {typeResult && typeResult?.id >= 0 ? <span /> : ""}
                  {typeResult && typeResult?.id >= 1 ? <span /> : ""}

                  {typeResult && typeResult?.id >= 2 ? <span /> : ""}

                  {typeResult && typeResult?.id >= 3 ? <span /> : ""}
                </div>
                {typeResult && typeResult?.id === 0 ? (
                  <p className="colorDanger">{translation("weak")}</p>
                ) : typeResult && typeResult?.id === 1 ? (
                  <p className="colorWarning">{translation("average")}</p>
                ) : typeResult && typeResult?.id === 2 ? (
                  <p className="colorGood">{translation("good")}</p>
                ) : typeResult && typeResult?.id === 3 ? (
                  <p className="colorStrong">{translation("strong")}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <div className="double-icon-input">
              <CommonTextBox
                control={control}
                type={!showConfirmPassword ? "password" : "text"}
                placeHolder={translation("confirm_password_placeholder")}
                className="form-control grey-input"
                name="confirm_password"
                error={errors?.confirm_password}
                fieldType="input"
              />
              <span className="left-icon">
                <img src={lock} alt="" />
              </span>
              <span className="right-icon">
                <button
                  type="button"
                  className="theme-button trans-btn"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {!showConfirmPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="30.01"
                      viewBox="0 0 40 30.01"
                    >
                      <path
                        id="np_view-password_4287676_000000"
                        d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                        transform="translate(-6.263 -17.188)"
                        opacity={0.3}
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="24"
                      viewBox="0 0 30 24"
                    >
                      <path
                        id="Show"
                        opacity={0.3}
                        d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                        transform="translate(-2 -4)"
                        fillRule="evenodd"
                      />
                    </svg>
                  )}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        disabled={loading}
        className="theme-button primary-btn"
      >
        {translation("update_password")}
        {loading ? (
          <div
            className="spinner-border text-light ms-2 btn-loader"
            role="status"
          >
            <span className="sr-only" />
          </div>
        ) : (
          ""
        )}
      </button>
      <button
        type="button"
        className="theme-button primary-btn"
        onClick={() => resetPassword()}
      >
        {translation("cancel")}
      </button>
    </form>
  );
};

export default CommonUpdatePassword;
