const PrivacyPolicy = () => {
  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">
        <strong>Iosync, LLC Privacy Policy</strong>
      </h1>
      <p>Date of Last Revision: February 13, 2023</p>
      <p>
        Iosync, LLC (&ldquo;Iosync&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;)
        recognizes that its clients, customers, visitors, users and others
        (&ldquo;User&rdquo;, &ldquo;Users&rdquo;, or &ldquo;you&rdquo;) who use
        our website, administrative website, mobile apps and the features that
        they provide (&ldquo;Our Services&rdquo;) value their privacy. This
        Privacy Policy (&ldquo;Privacy Policy&rdquo;) applies to all the
        products, services, and websites offered by Iosync and its
        affiliates&rsquo; products, services, and websites that specifically
        link to it, and details important information regarding the use and
        disclosure of User information collected.
      </p>
      <p>
        <strong>Information We Collect</strong>.&nbsp;
      </p>
      <p>We may get information about you in a range of ways:</p>
      <p>
        <strong>Information You Give Us</strong>. Some features of Our Services
        require that you have an account. You will be required to share personal
        information during the account creation process (such as name, phone
        number, address, password and email or to use certain interactive
        features currently developed and not yet developed.&nbsp;
      </p>
      <p>
        <strong>Information We Get From Others</strong>. Our Services are
        purchased by a client, who is typically a property manager, property
        owner or building manager with authority to provide services to a
        certain set of residences (&ldquo;Your Property Manager&rdquo;). Your
        Property Manager may provide your personal information according to the
        Agreements you have signed with Your Property Manager.&nbsp;
      </p>
      <p>
        <strong>Information Automatically Collected.</strong> We automatically
        log information about you and your device. When using Our Services, we
        may log your device&rsquo;s operating system type, language, browser
        type (where applicable), the website you visited before browsing to Our
        Service (where applicable), items you viewed, how long you spent on a
        particular item, mobile device IDs, access times, and information about
        your use of and actions on Our Services.&nbsp;
      </p>
      <p>
        <strong>Cookies</strong>. On our website, may log information using
        &quot;cookies.&quot; Cookies are small data files stored on your hard
        drive by a website. We may use both session Cookies (which expire once
        you close your web browser) and persistent Cookies (which stay on your
        computer until you delete them) to provide you with a more personal and
        interactive experience in Our Services. This type of information is
        collected to make Our Services more useful to you and to tailor the
        experience with us to meet your special interests and needs.
      </p>
      <p>
        <strong>A Note on Credit Card Information</strong>: We do not collect or
        store your credit card information. Credit card information you provide,
        if any, is processed by a third party payment processor selected by Your
        Property Manager. For more information on this third party processor,
        you can contact us at the address below or you can contact Your Property
        Manager.
      </p>
      <p>
        <strong>Use of Information</strong>
      </p>
      <p>We may use your personal information to:</p>
      <ul>
        <li>
          <p>
            operate, maintain, and improve our sites, products, and services.
          </p>
        </li>
        <li>
          <p>process and deliver contest entries and rewards.</p>
        </li>
        <li>
          <p>respond to comments and questions and provide customer service.</p>
        </li>
        <li>
          <p>
            send information including confirmations, invoices, technical
            notices, updates, security alerts, and support and administrative
            messages.
          </p>
        </li>
        <li>
          <p>
            communicate about promotions, upcoming events, and other news about
            products and services offered by us and our selected partners.
          </p>
        </li>
        <li>
          <p>
            protect, investigate, and deter against fraudulent, unauthorized, or
            illegal activity.
          </p>
        </li>
        <li>
          <p>to enforce our Terms of Service; and&nbsp;</p>
        </li>
        <li>
          <p>
            provide and deliver products and services you or Your Property
            Manager&rsquo;s request.
          </p>
        </li>
      </ul>
      <p>
        <strong>Sharing of Information</strong>
      </p>
      <p>
        We will share any Information You Give Us with Your Property Manager.
      </p>
      <p>
        <u>We may share your personal information:&nbsp;</u>
      </p>
      <ul>
        <li>
          <p>
            With third parties who need it to do work for us or provide a
            portion of Our Services to you. For example, we use third parties
            (collectively &ldquo;Third Parties&rdquo;) to process orders,
            provide mailing list services and provide SMS messaging services. We
            advise that you read any and all Third Parties&rsquo; privacy
            policies referenced in this Policy or expressly referenced in Our
            Services. As of the date above, we share information with the
            following Third Parties (though Our Services may include requests to
            share your information with additional Third Parties). Next to each,
            is a link to their respective Privacy Policies:
          </p>
        </li>
        <li>
          <p>
            Sendgrid:&nbsp;
            <a href="https://www.twilio.com/legal/privacy">
              https://www.twilio.com/legal/privacy
            </a>
          </p>
        </li>
        <li>
          <p>
            Twilio:&nbsp;
            <a href="https://www.twilio.com/legal/privacy">
              <u>https://www.twilio.com/legal/privacy</u>
            </a>
          </p>
        </li>
        <li>
          <p>
            Samsung SmartThings:&nbsp;
            <a href="https://www.smartthings.com/privacy">
              https://www.smartthings.com/privacy
            </a>
          </p>
        </li>
      </ul>
      <p>
        <br />
        &nbsp;
      </p>
      <ul>
        <li>
          <p>
            For legal, protection, and safety purposes, including but not
            limited to (i) as required by law, (ii) when we believe that
            disclosure is necessary to protect our rights and/or comply with a
            judicial proceeding, or legal process served to Iosync, LLC or its
            affiliates, (iii) if we believe it is necessary in order to
            investigate, prevent, or take action regarding illegal activities,
            suspected fraud, situations involving potential threats to the
            physical safety of any person, violations of our Terms of Service,
            or as otherwise required by law or (iv) in an emergency (this
            includes protecting the safety of our employees and agents, our
            customers, or any person).
          </p>
        </li>
        <li>
          <p>
            If we go through a business transaction, such as a merger,
            acquisition by another company, or sale of all or a portion of its
            assets. Your personally identifiable information will likely be
            among the assets transferred. You will be notified via email of any
            such change in ownership or control of your information.
          </p>
        </li>
      </ul>
      <p>
        We may also share aggregated and/or anonymized data with others for
        their own uses.
      </p>
      <p>
        <strong>Information from Children</strong>
      </p>
      <p>
        We are committed to protecting the privacy of children. We do not
        knowingly or intentionally collect personal information online from
        children under the age of 13. We do not collect personal information
        from children under the age of 18 without parental consent. If you
        believe we have wrongfully collected information about any child, or if
        you would like your child&rsquo;s information removed from Our Services,
        please contact us.
      </p>
      <p>
        <strong>Other Users, Public Information</strong>
      </p>
      <p>
        Keep in mind that any information, or comments that you voluntarily
        disclose in public spaces on the Website or Our Services, for example on
        comment pages or on the blog forums, becomes publicly available. Your
        account name is displayed to other Users when you send or post messages
        through the Website or Our Services. Any comment or other content you
        publicly post or send to the Website becomes published content and is
        not considered personally identifiable information subject to this
        Privacy Policy. Your messages and other information you make available
        in public areas of the Website and/or Our Services may be seen by people
        and organizations not related to or controlled by us, and may be used by
        others to contact you.
      </p>
      <p>
        <strong>International Users.</strong>
      </p>
      <p>
        If you are a User accessing the Website, Ecosystem or Services from the
        European Union, Asia, or any other region with laws or regulations
        governing PIP collection, use, and disclosure, that differ from United
        States laws, please be advised that through your continued use of the
        Services, which is governed by U.S. law and this Privacy Policy as
        incorporated in our Terms of Service, you will be transferring your
        personal information into the United States and you consent to that
        transfer.
      </p>
      <p>
        <strong>Information Choices And Changes</strong>
      </p>
      <p>
        Our marketing emails tell you how to &ldquo;opt-out.&rdquo; If you opt
        out, we may still send you non-marketing emails. Non-marketing emails
        include emails about your accounts and our business dealings with you.
      </p>
      <p>
        You may send requests about personal information to our Contact
        Information below. You can request to change contact choices, opt-out of
        our sharing with others, and update your personal information.
      </p>
      <p>
        You can typically remove and reject cookies from Our Service with your
        browser settings. Many browsers are set to accept cookies until you
        change your settings. If you remove or reject our cookies, it could
        affect how Our Service works for you.&nbsp;
      </p>
      <p>
        <strong>Contact Information.&nbsp;</strong>
      </p>
      <p>
        We welcome your comments or questions about this privacy policy. You may
        also contact us at: [privacy@iosync.us]
      </p>
      <p>
        <strong>Changes and Updates to this Privacy Policy.</strong>
      </p>
      <p>
        We may update this Privacy Policy from time-to-time in our sole
        discretion and without prior notice. It is recommended that you review
        this Privacy Policy when using the Services to stay abreast of any
        changes and be informed about our information practices. As the Privacy
        Policy is updated we will change the date at the top of this policy. We
        will strive to notify you about material changes in the way we treat
        personally identifiable information by sending a notice to the primary
        email address specified and/or by placing a notice on the Website. If we
        decide to change our Privacy Policy, we will post those changes to this
        privacy statement, the home page, or other places we deem appropriate so
        that you are aware of what information we collect, how we use it, and
        under what circumstances, if any, we disclose it.
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default PrivacyPolicy;
