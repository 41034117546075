import { http } from "../utils/http";

import {
  IGetUnitList,
  ISetVacantUnitData,
  IUnitDeleteParam,
  IUnitDetailData,
  IVacantUnit,
  IVacantUnitParam,
} from "../interfaces/UnitInterface";
import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";
import { IUnitData } from "../interfaces/PropertyInterface";

/**
 * Get units for a property
 */
export const getAllPropertyUnits = (
  propertyId: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETPROPERTYUNITS.replace(":property_id", propertyId)}`
  );
};

/**
 *  Vacant or unassign the unit
 */
export const vacantUnit = (unitId: IVacantUnitParam): Promise<ApiResponse> => {
  return http.post(`${endpoint.Unit.VACANTUNIT}`, unitId);
};

/**
 *  Vacant or unassign the unit
 */
export const deleteUnit = (
  unitData: IUnitDeleteParam[]
): Promise<ApiResponse> => {
  return http.post(`${endpoint.Unit.DELETEUNIT}`, unitData);
};

/**
 *  Assign a unit to a resident
 */
export const assignUnit = (data: IGetUnitList): Promise<ApiResponse> => {
  return http.post(`${endpoint.Unit.ASSIGNUNIT}`, data);
};

/**
 * Get Unit List for a property
 */
export const unitlist = (
  property_id: string,
  offset?: number,
  data?: any
): Promise<ApiResponse> => {
  const queryParams: string[] = [];
  data &&
    Object.keys(data).forEach((key) => {
      if (data[key]) queryParams.push(`${key}=${data[key]}`);
    });

  offset && queryParams.push(`offset=${Number(offset)}`);
  return http.get(
    `${endpoint.Unit.GETUNITLIST.replace(":property_name", property_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 * Get Detail of a unit
 */
export const getunitDetailData = (
  data: IUnitDetailData
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Unit.GETUNITDETAIL.replace(
      ":property_id",
      data.propertyId
    ).replace(":unit_id", data.unitId)}`
  );
};

/**
 *  Get vacant unit list
 */
export const getVacantUnit = (data: IVacantUnit): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Unit.GETVACANTUNIT.replace(":property_id", data.property_id)}${
      data?.status ? `?status=${data.status}` : ""
    }`
  );
};

/**
 *  set vacant unit set
 */

export const setVacantUnit = (
  data: ISetVacantUnitData
): Promise<ApiResponse> => {
  return http.post(`${endpoint.Unit.SETVACANTUNITSETTING}`, data);
};

/**
 * Get Unit List of Resident
 */
export const getunitListResident = (data: {
  id: string;
  propertyId: string;
}): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Unit.GETRESIDENTUNITLIST.replace(":id", data.id).replace(
      ":property_id",
      data.propertyId
    )}`
  );
};
/**
 * Get Property List of Resident
 */
export const getResidentPropertiesList = (
  user_id: string,
  company_user_id: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETRESIDENTPROPERTYLIST.replace(
      ":user_id",
      user_id
    ).replace(":company_user_id", company_user_id)}`
  );
};
/**
 * Get company List of Resident
 */
export const getResidentCompanyList = (
  user_id: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETRESIDENTCOMPANYLIST.replace(":user_id", user_id)}`
  );
};

/**
 *  Add unit
 */

export const addUnit = (data: IUnitData): Promise<ApiResponse> => {
  return http.post(`${endpoint.Unit.ADDUNIT}`, data);
};
