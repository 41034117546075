import React from "react";

import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  approved,
  denied,
  inprogress,
  pending,
  resolved,
} from "../../constants/commonConstants";

import { IObjectParams } from "../../interfaces/CommonInterface";

interface IProps {
  className: string;
  // placeHolder: string;
  name: string;
  value?: string;
  multipleSelect: boolean;
  from?: string;
  disabled?: boolean;
  readOnly?: boolean;
  dropDownData: {
    key: string;
    value: string | number;
  }[];
  control: Control;
  error?: FieldErrors<FieldValues> | FieldError;
  firstOption?: string;
  onChange?: (value: string) => void;
  defaultValue?:
    | {
        name: string;
        id: string;
      }
    | string;
}

const CommonSelect: React.FC<IProps> = (props) => {
  const {
    dropDownData,
    error,
    control,
    name,
    multipleSelect,
    firstOption,
    onChange,
    defaultValue = "",
    value,
    from,
    disabled,
  } = props;
  const { t: translation } = useTranslation();

  return (
    <div className="form-group">
      <Controller
        render={({ field }) => (
          <select
            {...field}
            multiple={multipleSelect}
            value={value || field.value}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e.target.value);
            }}
            className={`${
              value === pending
                ? "form-control form-select red"
                : value === denied
                ? "form-control form-select red"
                : value === approved
                ? "form-control form-select green"
                : value === inprogress
                ? "form-control form-select yellow"
                : value === resolved
                ? "form-control form-select green"
                : "form-select"
            }`}
            disabled={disabled}
          >
            {!from && (
              <option disabled={field.value ? true : false} value={value}>
                {firstOption ?? translation("select_role")}
              </option>
            )}

            {dropDownData?.length &&
              dropDownData.map((data: IObjectParams) => {
                return (
                  <option
                    className={
                      data.key === approved
                        ? "sucess-color"
                        : data.key === denied
                        ? "danger-color"
                        : data.key === pending
                        ? "grey-color"
                        : data.key === inprogress
                        ? "danger-color"
                        : data.key === resolved
                        ? "sucess-color"
                        : ""
                    }
                    selected={from && value === data.key ? true : false}
                    value={data.key}
                  >
                    {data.value}
                  </option>
                );
              })}
          </select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue as string}
      />
      <div className="auth-msg error">
        <p>
          {error && error.message ? (
            <>{translation(`${error.message}`, { field: name })}</>
          ) : null}
        </p>
      </div>
    </div>
  );
};
export default CommonSelect;
