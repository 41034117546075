import React, { lazy, Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { IAuthReducerData } from "../interfaces/AuthInterface";

import Login from "../container/userAuth/Login";
import NewPassword from "../container/userAuth/NewPassword";
import PrivateRoutes from "./PrivateRoute";
import Recover from "../container/userAuth/Recover";
import Sidebar from "../components/sidebar/Sidebar";
import ROUTES from "../constants/routes";
import Notifications from "../container/notification/Notifications";
import AddResident from "../container/addResident/AddResident";
import { RootState } from "../redux/reducers/Index";
import MyProfile from "../container/myProfile/MyProfile";
import Residents from "../container/residents/Residents";
import UserDashboard from "../container/dashboard/UserDashboard";
import UnitNo from "../container/units/UnitNo";
import { admin, company, resident, staff } from "../constants/commonConstants";
import SupportTicket from "../container/support/SupportTicket";
import PrivacyPolicy from "../container/staticPages/PrivacyPolicy";
import TermsCondition from "../container/staticPages/TermsCondition";
import Email from "../container/sendEmail/Email";

const AddProperties = lazy(
  () => import("../container/addProperty/Addproperty")
);
const AllProperties = lazy(
  () => import("../container/allProperties/AllPropeties")
);
const Dashboard = lazy(() => import("../container/dashboard/Dashboard"));
const Units = lazy(() => import("../container/units/Units"));
const Verify = lazy(() => import("../container/userAuth/Verify"));
const AddCompany = lazy(() => import("../container/addCompany/AddCompany"));
const SuperAdmin = lazy(() => import("../container/superAdmin/SuperAdmin"));
const Resident = lazy(() => import("../container/residents/Residents"));
const Staff = lazy(() => import("../container/staff/Staff"));
const ManageRoles = lazy(() => import("../container/manageRoles/ManageRoles"));

interface Props {
  component: React.ComponentType | any;
}
export const preLogginPages = [
  "/",
  ROUTES.LOGIN,
  ROUTES.FORGET_PASSWORD,
  ROUTES.NEW_PASSWORD,
  ROUTES.VERIFY_OTP,
];
const route = window.location?.pathname;
export const WithHeaderAndSideBar: React.FC<Props> = ({ component }) => {
  const authReducer: any = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  return (
    authReducer &&
    authReducer.isLoggedIn && (
      <div className="main">
        <Sidebar />
        <div
          className="main-box"
          style={
            preLogginPages.includes(route)
              ? { width: "100%" }
              : { display: "block" }
          }
        >
          <PrivateRoutes component={component} />
        </div>
      </div>
    )
  );
};

const PublicRoutes: React.FC = () => {
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const { t: translation } = useTranslation();
  return (
    <Suspense fallback={<p>{translation("loading")}</p>}>
      <Routes>
        {/* <Route
          path={ROUTES.DASHBOARD}
          element={<WithHeaderAndSideBar component={Dashboard} />}
        /> */}
        <Route
          path={ROUTES.ADD_COMPANY}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={AddCompany} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.EDIT_COMPANY}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={AddCompany} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.ADD_PROPERTIES}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={AddProperties} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.EDIT_PROPERTIES}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={AddProperties} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.ALL_PROPETIES}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={AllProperties} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.COMPANY}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={SuperAdmin} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.RESIDENTS}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Resident} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.STAFF}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Staff} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />

        <Route
          path={ROUTES.UNITS}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Units} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.UNITDETAIL}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={UnitNo} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />

        <Route
          path={ROUTES.MANAGE_ROLES}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={ManageRoles} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.SUPPORT_TICKET}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={SupportTicket} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.MY_PROFILE}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={MyProfile} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.ADD_RESIDENTS}
          element={authReducer?.isLoggedIn ? <AddResident /> : <Login />}
        />
        <Route
          path={ROUTES.RESIDENTS}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Residents} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />

        <Route
          path={ROUTES.Resident.RESIDENT_DASHBOARD}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={UserDashboard} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.DASHBOARD}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Dashboard} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.NOTIFICATIONS}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Notifications} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path={ROUTES.EMAIL}
          element={
            authReducer?.isLoggedIn ? (
              <WithHeaderAndSideBar component={Email} />
            ) : (
              <Navigate to={ROUTES.LOGIN} />
            )
          }
        />
        <Route
          path="/"
          element={
            authReducer?.isLoggedIn ? (
              authReducer.authData.account_type === admin ? (
                <Navigate to={ROUTES.COMPANY} />
              ) : authReducer.authData.account_type === (company || staff) ? (
                <Navigate to={ROUTES.ALL_PROPETIES} />
              ) : authReducer.authData.account_type === resident ? (
                <Navigate to={ROUTES.Resident.RESIDENT_DASHBOARD} />
              ) : (
                <Login />
              )
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/login"
          element={
            authReducer?.isLoggedIn ? (
              authReducer.authData.account_type === admin ? (
                <Navigate to={ROUTES.COMPANY} />
              ) : authReducer.authData.account_type === (company || staff) ? (
                <Navigate to={ROUTES.ALL_PROPETIES} />
              ) : authReducer.authData.account_type === resident ? (
                <Navigate to={ROUTES.Resident.RESIDENT_DASHBOARD} />
              ) : (
                <Login />
              )
            ) : (
              <Login />
            )
          }
        />
        <Route path={ROUTES.TERMS_CONDITION} element={<TermsCondition />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.NEW_PASSWORD} element={<NewPassword />} />
        <Route path={ROUTES.FORGET_PASSWORD} element={<Recover />} />
        <Route path={ROUTES.VERIFY_OTP} element={<Verify />} />
        <Route path="*" element={<p>{translation("no_route_match")}</p>} />
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
