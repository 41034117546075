import { IFileType } from "../../interfaces/EmailInterface";

interface IProps {
  fileItems: IFileType[];
  setFileItems: (items: IFileType[]) => void;
}

const Attachments = (props: IProps) => {
  const { fileItems, setFileItems } = props;

  const removeFile = async (index: number) => {
    const updatedFile = [...fileItems];
    updatedFile.splice(index, 1);
    setFileItems(updatedFile);
  };
  return (
    <div className="atteched-file-list">
      {fileItems &&
        fileItems.length > 0 &&
        fileItems.map((fileItem, index) => (
          <div className="atteched-file-item">
            {(() => {
              switch (fileItem.type) {
                case "pdf":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 482.14 482.14"
                      xmlSpace="preserve"
                      className="item-icon"
                    >
                      <g>
                        <path d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69   c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z" />
                        <path d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394   c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z" />
                        <path d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404   c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223   c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796   c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646   c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811   c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748   c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233   c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z    M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282   c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222   C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42   v19.875H314.892z" />
                      </g>
                    </svg>
                  );

                case "jpg":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="512"
                      height="512"
                      className="item-icon"
                    >
                      <path d="M19.14,21.75H4.86a2.61,2.61,0,0,1-2.61-2.61V4.86A2.61,2.61,0,0,1,4.86,2.25H19.14a2.61,2.61,0,0,1,2.61,2.61V19.14A2.61,2.61,0,0,1,19.14,21.75ZM4.86,3.75A1.11,1.11,0,0,0,3.75,4.86V19.14a1.11,1.11,0,0,0,1.11,1.11H19.14a1.11,1.11,0,0,0,1.11-1.11V4.86a1.11,1.11,0,0,0-1.11-1.11Z" />
                      <path d="M3,18.81a.76.76,0,0,1-.57-.26.75.75,0,0,1,.09-1.06l5.12-4.32a2,2,0,0,1,2.7.11l2.43,2.43a.5.5,0,0,0,.65,0L15.78,14a2,2,0,0,1,2.44,0l3.25,2.59a.75.75,0,1,1-.94,1.18l-3.24-2.6a.5.5,0,0,0-.61,0L14.32,17a2,2,0,0,1-2.61-.18L9.28,14.34a.5.5,0,0,0-.67,0L3.48,18.64A.72.72,0,0,1,3,18.81Z" />
                      <path d="M15,11.75A2.75,2.75,0,1,1,17.75,9,2.75,2.75,0,0,1,15,11.75Zm0-4A1.25,1.25,0,1,0,16.25,9,1.25,1.25,0,0,0,15,7.75Z" />
                    </svg>
                  );
                case "jpeg":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="512"
                      height="512"
                      className="item-icon"
                    >
                      <path d="M19.14,21.75H4.86a2.61,2.61,0,0,1-2.61-2.61V4.86A2.61,2.61,0,0,1,4.86,2.25H19.14a2.61,2.61,0,0,1,2.61,2.61V19.14A2.61,2.61,0,0,1,19.14,21.75ZM4.86,3.75A1.11,1.11,0,0,0,3.75,4.86V19.14a1.11,1.11,0,0,0,1.11,1.11H19.14a1.11,1.11,0,0,0,1.11-1.11V4.86a1.11,1.11,0,0,0-1.11-1.11Z" />
                      <path d="M3,18.81a.76.76,0,0,1-.57-.26.75.75,0,0,1,.09-1.06l5.12-4.32a2,2,0,0,1,2.7.11l2.43,2.43a.5.5,0,0,0,.65,0L15.78,14a2,2,0,0,1,2.44,0l3.25,2.59a.75.75,0,1,1-.94,1.18l-3.24-2.6a.5.5,0,0,0-.61,0L14.32,17a2,2,0,0,1-2.61-.18L9.28,14.34a.5.5,0,0,0-.67,0L3.48,18.64A.72.72,0,0,1,3,18.81Z" />
                      <path d="M15,11.75A2.75,2.75,0,1,1,17.75,9,2.75,2.75,0,0,1,15,11.75Zm0-4A1.25,1.25,0,1,0,16.25,9,1.25,1.25,0,0,0,15,7.75Z" />
                    </svg>
                  );
                case "png":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="512"
                      height="512"
                      className="item-icon"
                    >
                      <path d="M19.14,21.75H4.86a2.61,2.61,0,0,1-2.61-2.61V4.86A2.61,2.61,0,0,1,4.86,2.25H19.14a2.61,2.61,0,0,1,2.61,2.61V19.14A2.61,2.61,0,0,1,19.14,21.75ZM4.86,3.75A1.11,1.11,0,0,0,3.75,4.86V19.14a1.11,1.11,0,0,0,1.11,1.11H19.14a1.11,1.11,0,0,0,1.11-1.11V4.86a1.11,1.11,0,0,0-1.11-1.11Z" />
                      <path d="M3,18.81a.76.76,0,0,1-.57-.26.75.75,0,0,1,.09-1.06l5.12-4.32a2,2,0,0,1,2.7.11l2.43,2.43a.5.5,0,0,0,.65,0L15.78,14a2,2,0,0,1,2.44,0l3.25,2.59a.75.75,0,1,1-.94,1.18l-3.24-2.6a.5.5,0,0,0-.61,0L14.32,17a2,2,0,0,1-2.61-.18L9.28,14.34a.5.5,0,0,0-.67,0L3.48,18.64A.72.72,0,0,1,3,18.81Z" />
                      <path d="M15,11.75A2.75,2.75,0,1,1,17.75,9,2.75,2.75,0,0,1,15,11.75Zm0-4A1.25,1.25,0,1,0,16.25,9,1.25,1.25,0,0,0,15,7.75Z" />
                    </svg>
                  );
                case "webm":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_14_811)">
                        <path
                          d="M20 13.3332L26.0707 10.2986C26.2739 10.197 26.4997 10.1491 26.7267 10.1593C26.9536 10.1695 27.1742 10.2376 27.3675 10.357C27.5608 10.4764 27.7203 10.6432 27.831 10.8416C27.9417 11.04 27.9999 11.2634 28 11.4906V20.5092C27.9999 20.7364 27.9417 20.9598 27.831 21.1582C27.7203 21.3566 27.5608 21.5234 27.3675 21.6428C27.1742 21.7623 26.9536 21.8303 26.7267 21.8405C26.4997 21.8507 26.2739 21.8028 26.0707 21.7012L20 18.6666V13.3332Z"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 10.6667C4 9.95942 4.28095 9.28115 4.78105 8.78105C5.28115 8.28095 5.95942 8 6.66667 8H17.3333C18.0406 8 18.7189 8.28095 19.219 8.78105C19.719 9.28115 20 9.95942 20 10.6667V21.3333C20 22.0406 19.719 22.7189 19.219 23.219C18.7189 23.719 18.0406 24 17.3333 24H6.66667C5.95942 24 5.28115 23.719 4.78105 23.219C4.28095 22.7189 4 22.0406 4 21.3333V10.6667Z"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14_811">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                case "csv":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enableBackground="new 0 0 512 512"
                      height="512"
                      viewBox="0 0 512 512"
                      width="512"
                    >
                      <g id="_x31_45_x2C__csv_x2C__file_type_x2C__file_format_x2C__file_extension_x2C__document">
                        <g id="XMLID_1_">
                          <g id="XMLID_2791_">
                            <path
                              id="XMLID_2710_"
                              d="m391 426h-300.667c-29.959 0-54.333-24.374-54.333-54.333v-155.667c0-5.523 4.478-10 10-10s10 4.477 10 10v155.667c0 18.931 15.401 34.333 34.333 34.333h300.667c8.272 0 15-6.729 15-15v-120c0-8.271-6.728-15-15-15h-264.999c-5.522 0-10-4.477-10-10s4.478-10 10-10h264.999c19.299 0 35 15.701 35 35v120c0 19.299-15.701 35-35 35z"
                            />
                          </g>
                          <g id="XMLID_2789_">
                            <path
                              id="XMLID_2709_"
                              d="m126 256h-50c-22.056 0-40-17.944-40-40s17.944-40 40-40h50c5.523 0 10 4.477 10 10s-4.477 10-10 10h-50c-11.028 0-20 8.972-20 20s8.972 20 20 20h50c5.523 0 10 4.477 10 10s-4.477 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_2787_">
                            <path
                              id="XMLID_2708_"
                              d="m436 496h-280c-22.056 0-40-17.944-40-40v-40c0-5.523 4.478-10 10-10 5.523 0 10 4.477 10 10v40c0 11.028 8.972 20 20 20h280c11.028 0 20-8.972 20-20v-345.858l-74.143-74.142h-225.857c-11.028 0-20 8.972-20 20v189.999c0 5.523-4.477 10-10 10-5.522 0-10-4.477-10-10v-189.999c0-22.056 17.944-40 40-40h230c2.652 0 5.195 1.054 7.071 2.929l80 80c1.875 1.875 2.929 4.419 2.929 7.071v350c0 22.056-17.944 40-40 40z"
                            />
                          </g>
                          <g id="XMLID_2785_">
                            <path
                              id="XMLID_2707_"
                              d="m456 116h-70c-5.522 0-10-4.477-10-10v-70c0-5.523 4.478-10 10-10s10 4.477 10 10v60h60c5.522 0 10 4.477 10 10s-4.478 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_2180_">
                            <path
                              id="XMLID_2706_"
                              d="m256 106h-80c-5.523 0-10-4.477-10-10s4.477-10 10-10h80c5.522 0 10 4.477 10 10s-4.478 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_2178_">
                            <path
                              id="XMLID_2705_"
                              d="m336 146h-160c-5.523 0-10-4.477-10-10s4.477-10 10-10h160c5.522 0 10 4.477 10 10s-4.478 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_8_">
                            <path
                              id="XMLID_2704_"
                              d="m336 186h-160c-5.523 0-10-4.477-10-10s4.477-10 10-10h160c5.522 0 10 4.477 10 10s-4.478 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_6_">
                            <path
                              id="XMLID_2703_"
                              d="m171 386c-27.57 0-50-22.43-50-50s22.43-50 50-50c5.523 0 10 4.477 10 10s-4.477 10-10 10c-16.542 0-30 13.458-30 30s13.458 30 30 30c5.523 0 10 4.477 10 10s-4.477 10-10 10z"
                            />
                          </g>
                          <g id="XMLID_4_">
                            <path
                              id="XMLID_2702_"
                              d="m326 386c-4.168 0-7.899-2.586-9.363-6.489l-30-80c-1.939-5.171.681-10.935 5.852-12.875 5.177-1.939 10.935.682 12.875 5.852l20.636 55.032 20.637-55.031c1.94-5.171 7.699-7.792 12.875-5.852 5.171 1.939 7.791 7.703 5.852 12.875l-30 80c-1.465 3.902-5.196 6.488-9.364 6.488z"
                            />
                          </g>
                          <g id="XMLID_2_">
                            <path
                              id="XMLID_2701_"
                              d="m236 386h-30c-5.523 0-10-4.477-10-10s4.477-10 10-10h30c5.514 0 10-4.486 10-10s-4.486-10-10-10h-10c-16.542 0-30-13.458-30-30s13.458-30 30-30h30c5.522 0 10 4.477 10 10s-4.478 10-10 10h-30c-5.514 0-10 4.486-10 10s4.486 10 10 10h10c16.542 0 30 13.458 30 30s-13.458 30-30 30z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  );
                case "docx":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      width="470.586px"
                      height="470.586px"
                      viewBox="0 0 470.586 470.586"
                    >
                      <g>
                        <path d="M327.081,0H90.234c-15.9,0-28.854,12.959-28.854,28.859v412.863c0,15.924,12.953,28.863,28.854,28.863H380.35   c15.917,0,28.855-12.939,28.855-28.863V89.234L327.081,0z M333.891,43.184l35.996,39.121h-35.996V43.184z M384.972,441.723   c0,2.542-2.081,4.629-4.634,4.629H90.234c-2.551,0-4.62-2.087-4.62-4.629V28.859c0-2.548,2.069-4.613,4.62-4.613h219.41v70.181   c0,6.682,5.444,12.099,12.129,12.099h63.198V441.723z M131.858,161.048l-25.29-99.674h18.371l11.688,49.795   c1.646,6.954,3.23,14.005,4.592,20.516c1.555-6.682,3.425-13.774,5.272-20.723l13.122-49.583h16.863l11.969,49.929   c1.552,6.517,3.094,13.243,4.395,19.742c1.339-5.784,2.823-11.718,4.348-17.83l0.562-2.217l12.989-49.618h17.996l-28.248,99.673   h-16.834l-12.395-51.173c-1.531-6.289-2.87-12.052-3.975-17.693c-1.292,5.618-2.799,11.366-4.643,17.794l-13.964,51.072h-16.819   V161.048z M242.607,139.863h108.448c5.013,0,9.079,4.069,9.079,9.079c0,5.012-4.066,9.079-9.079,9.079H242.607   c-5.012,0-9.079-4.067-9.079-9.079C233.529,143.933,237.596,139.863,242.607,139.863z M360.135,209.566   c0,5.012-4.066,9.079-9.079,9.079H125.338c-5.012,0-9.079-4.067-9.079-9.079c0-5.013,4.066-9.079,9.079-9.079h225.718   C356.068,200.487,360.135,204.554,360.135,209.566z M360.135,263.283c0,5.012-4.066,9.079-9.079,9.079H125.338   c-5.012,0-9.079-4.067-9.079-9.079c0-5.013,4.066-9.079,9.079-9.079h225.718C356.068,254.204,360.135,258.271,360.135,263.283z    M360.135,317c0,5.013-4.066,9.079-9.079,9.079H125.338c-5.012,0-9.079-4.066-9.079-9.079c0-5.012,4.066-9.079,9.079-9.079h225.718   C356.068,307.921,360.135,311.988,360.135,317z M360.135,371.474c0,5.013-4.066,9.079-9.079,9.079H125.338   c-5.012,0-9.079-4.066-9.079-9.079s4.066-9.079,9.079-9.079h225.718C356.068,362.395,360.135,366.461,360.135,371.474z" />
                      </g>
                    </svg>
                  );
                case "xlsx":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="512"
                      viewBox="0 0 449.73 512"
                      width="512"
                    >
                      <g id="Layer_2" data-name="Layer 2">
                        <g
                          id="Microsoft_Excel_file"
                          data-name="Microsoft Excel file"
                        >
                          <path d="m222.88 396.85c-9.72 0-10.38-.13-11.53-.34a15 15 0 0 1 -12.29-14.75v-91.57a15 15 0 0 1 30 0v76.64l14.7-.07h.09a15 15 0 0 1 .09 30c-9.76.06-16.43.09-21.06.09z" />
                          <path d="m321.6 396.84c-16.69 0-32.38-6.75-43.05-18.51a15 15 0 1 1 22.23-20.15c4.92 5.42 12.7 8.66 20.82 8.66 7.63 0 12-4.48 12.52-7.5.61-3.42-4.87-6.73-9.75-8.53-14.32-5.28-27.05-10.95-27.58-11.19a14.93 14.93 0 0 1 -2.08-1.13 32.64 32.64 0 0 1 -14.57-32.31c2-13.62 11.86-24.7 25.85-28.91 21.44-6.46 41.4 3.79 49.81 10.86l-19.3 23 .12.1c-.11-.08-10.82-8.56-22-5.2-2.76.84-4.52 2.46-4.8 4.46a2.76 2.76 0 0 0 .68 2.44c3.69 1.61 13.61 5.86 24.23 9.77 25.18 9.29 31.44 27.76 28.91 41.95-3.32 18.63-21 32.19-42.04 32.19z" />
                          <path d="m169.4 396.84a15 15 0 0 1 -12.28-6.37l-64.47-91.65a15 15 0 1 1 24.53-17.26l64.47 91.65a15 15 0 0 1 -12.25 23.63z" />
                          <path d="m100.27 396.84a15 15 0 0 1 -12.27-23.64l64.56-91.65a15 15 0 0 1 24.53 17.28l-64.56 91.65a15 15 0 0 1 -12.26 6.36z" />
                          <path d="m373.48 512h-297.48a34.87 34.87 0 0 1 -34.84-34.83v-41.12a15 15 0 0 1 30 0v41.12a4.84 4.84 0 0 0 4.84 4.83h297.5a4.84 4.84 0 0 0 4.84-4.83v-41.12a15 15 0 0 1 30 0v41.12a34.87 34.87 0 0 1 -34.86 34.83z" />
                          <path d="m393.32 256.92a15 15 0 0 1 -15-15v-123a4.89 4.89 0 0 0 -1.43-3.43l-83.77-84.09a4.78 4.78 0 0 0 -3.4-1.4h-213.72a4.84 4.84 0 0 0 -4.84 4.83v207.09a15 15 0 1 1 -30 0v-207.09a34.87 34.87 0 0 1 34.84-34.83h213.72a34.61 34.61 0 0 1 24.63 10.2l83.79 84.09a34.68 34.68 0 0 1 10.18 24.61v123a15 15 0 0 1 -15 15.02z" />
                          <path d="m404.73 451.05h-359.73a45 45 0 0 1 -45-45v-134.13a45.05 45.05 0 0 1 45-45h359.73a45 45 0 0 1 45 45v134.13a45 45 0 0 1 -45 45zm-359.73-194.13a15 15 0 0 0 -15 15v134.13a15 15 0 0 0 15 15h359.73a15 15 0 0 0 15-15v-134.13a15 15 0 0 0 -15-15z" />
                        </g>
                      </g>
                    </svg>
                  );
                case "mp3":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_14_805)">
                        <path
                          d="M12 6.66675C12 5.60588 12.4214 4.58847 13.1716 3.83832C13.9217 3.08818 14.9391 2.66675 16 2.66675C17.0609 2.66675 18.0783 3.08818 18.8284 3.83832C19.5786 4.58847 20 5.60588 20 6.66675V13.3334C20 14.3943 19.5786 15.4117 18.8284 16.1618C18.0783 16.912 17.0609 17.3334 16 17.3334C14.9391 17.3334 13.9217 16.912 13.1716 16.1618C12.4214 15.4117 12 14.3943 12 13.3334V6.66675Z"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6.66675 13.3333C6.66675 15.8086 7.65008 18.1826 9.40042 19.9329C11.1508 21.6833 13.5247 22.6666 16.0001 22.6666C18.4754 22.6666 20.8494 21.6833 22.5997 19.9329C24.3501 18.1826 25.3334 15.8086 25.3334 13.3333"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.6667 28H21.3334"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 22.6667V28.0001"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14_805">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                case "mp4":
                  return (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_14_811)">
                        <path
                          d="M20 13.3332L26.0707 10.2986C26.2739 10.197 26.4997 10.1491 26.7267 10.1593C26.9536 10.1695 27.1742 10.2376 27.3675 10.357C27.5608 10.4764 27.7203 10.6432 27.831 10.8416C27.9417 11.04 27.9999 11.2634 28 11.4906V20.5092C27.9999 20.7364 27.9417 20.9598 27.831 21.1582C27.7203 21.3566 27.5608 21.5234 27.3675 21.6428C27.1742 21.7623 26.9536 21.8303 26.7267 21.8405C26.4997 21.8507 26.2739 21.8028 26.0707 21.7012L20 18.6666V13.3332Z"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4 10.6667C4 9.95942 4.28095 9.28115 4.78105 8.78105C5.28115 8.28095 5.95942 8 6.66667 8H17.3333C18.0406 8 18.7189 8.28095 19.219 8.78105C19.719 9.28115 20 9.95942 20 10.6667V21.3333C20 22.0406 19.719 22.7189 19.219 23.219C18.7189 23.719 18.0406 24 17.3333 24H6.66667C5.95942 24 5.28115 23.719 4.78105 23.219C4.28095 22.7189 4 22.0406 4 21.3333V10.6667Z"
                          stroke="#4F4F4F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14_811">
                          <rect width="32" height="32" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  );
                default:
                  return null;
              }
            }).call(this)}

            <span>{(fileItem.fileItem as File).name}</span>
            <div className="close-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                onClick={() => removeFile(index)}
              >
                <path
                  id="Path_312"
                  data-name="Path 312"
                  d="M8.272,19.81a19.156,19.156,0,0,0,7.72-1.575A20.492,20.492,0,0,0,22.374,13.9,20.38,20.38,0,0,0,26.7,7.53,19.242,19.242,0,0,0,28.262-.2a19.156,19.156,0,0,0-1.575-7.72,20.618,20.618,0,0,0-4.334-6.392,20.118,20.118,0,0,0-6.382-4.323,19.374,19.374,0,0,0-7.72-1.554,19.3,19.3,0,0,0-7.71,1.554A20.272,20.272,0,0,0-5.84-14.313a20.384,20.384,0,0,0-4.334,6.392A19.264,19.264,0,0,0-11.738-.2a19.133,19.133,0,0,0,1.575,7.73A20.527,20.527,0,0,0-5.83,13.9,20.527,20.527,0,0,0,.542,18.235,19.133,19.133,0,0,0,8.272,19.81Zm0-3.664a16.025,16.025,0,0,1-6.361-1.266A16.4,16.4,0,0,1-3.3,11.369,16.263,16.263,0,0,1-6.8,6.161,16.138,16.138,0,0,1-8.053-.2,16.138,16.138,0,0,1-6.8-6.562,16.263,16.263,0,0,1-3.3-11.77a16.294,16.294,0,0,1,5.2-3.5,16.064,16.064,0,0,1,6.351-1.256,16.138,16.138,0,0,1,6.361,1.256,16.263,16.263,0,0,1,5.208,3.5,16.4,16.4,0,0,1,3.51,5.208A16.025,16.025,0,0,1,24.6-.2a15.776,15.776,0,0,1-1.245,6.361,16.489,16.489,0,0,1-3.5,5.208,16.253,16.253,0,0,1-5.208,3.51A16.1,16.1,0,0,1,8.272,16.145ZM1.746,8.013A1.513,1.513,0,0,0,2.94,7.5L8.251,2.187,13.6,7.5a1.668,1.668,0,0,0,2.862-1.153,1.638,1.638,0,0,0-.515-1.194L10.619-.18l5.353-5.373a1.486,1.486,0,0,0,.515-1.132,1.584,1.584,0,0,0-.494-1.194,1.638,1.638,0,0,0-1.173-.473,1.648,1.648,0,0,0-1.173.494L8.251-2.506,2.9-7.838a1.5,1.5,0,0,0-1.153-.494,1.628,1.628,0,0,0-1.184.473A1.576,1.576,0,0,0,.078-6.686,1.508,1.508,0,0,0,.593-5.533L5.925-.18.593,5.172A1.53,1.53,0,0,0,.078,6.346,1.62,1.62,0,0,0,.562,7.519,1.593,1.593,0,0,0,1.746,8.013Z"
                  transform="translate(11.738 20.19)"
                  fill="#0d0d0d"
                />
              </svg>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Attachments;
