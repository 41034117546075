import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { getResidentProperty } from "../../services/resident";
import { getStaffProperty } from "../../services/staff";
import { login } from "../../services/auth";

import { ILoginState } from "../../interfaces/AuthInterface";

import {
  admin,
  company,
  manager,
  resident,
  staff,
  user,
} from "../../constants/commonConstants";

import ActionType from "../../resources/enums";
import toast from "../../utils/toastMessage";
import storage from "../../utils/storage";
import CommonLogin from "./CommonLogin";
import ROUTES from "../../constants/routes";

import LoginRight from "../../assets/images/loginright.png";
import LoginLeft from "../../assets/images/login-left.png";
import userLoginImage from "../../assets/images/userloginright.png";

import "./userAuth.scss";
import { recordCount } from "../../services/property";
import { getPropertyManagers } from "../../services/company";
import { IResidentInfo } from "../../interfaces/ResidentInterface";

const loginType = process.env.REACT_APP_USER_TYPE;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [checkbox, setChecbox] = useState<boolean>();

  /**
   * login
   */
  const onLogin = async (data: ILoginState) => {
    setLoading(true);
    const responseData = await login(data);
    if (responseData?.data?.success) {
      const date = new Date();
      storage.set("OLDER_TIME", JSON.stringify(date));
      if (checkbox) {
        const rememberMeData = {
          email: data.email,
          password: data.password,
        };
        await storage.set(
          "rememberMe",
          checkbox ? JSON.stringify(rememberMeData) : ""
        );
      } else {
        await storage.remove("rememberMe");
      }

      if (loginType === admin) {
        if (responseData?.data?.data.account_type === admin) {
          await storage.set(
            "__RTA__",
            JSON.stringify(responseData?.data?.token)
          );
          await storage.set(
            "loginDetails",
            JSON.stringify(responseData?.data?.data)
          );
          dispatch({
            type: ActionType.LOGIN,
            payload: responseData.data,
          });
          if (responseData.data.data.account_type === admin) {
            navigate(`${ROUTES.COMPANY}`);
          }
        } else {
          toast.error(translation("not_for_user"));
        }
      } else if (responseData?.data?.data.account_type !== admin) {
        await storage.set("__RTA__", JSON.stringify(responseData?.data?.token));
        await storage.set(
          "loginDetails",
          JSON.stringify(responseData?.data?.data)
        );

        if (responseData.data.data.account_type === company) {
          await storage.set("propertyManagerId", responseData.data.data._id);
          dispatch({
            type: ActionType.LOGIN,
            payload: responseData.data,
          });
          navigate(`${ROUTES.ALL_PROPETIES}`);
        }

        if (responseData.data.data.account_type === resident) {
          const getPropertyId = await getResidentProperty(
            responseData.data.data._id
          );

          if (getPropertyId?.data?.success) {
            const residentPropertyAndUnitInfo =
              getPropertyId?.data?.data.filter(
                (item: IResidentInfo) => item.unit_id
              );
            await storage.set(
              "propertyId",
              residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0
                ? residentPropertyAndUnitInfo[0].property_id
                : ""
            );
            await storage.set(
              "unitId",
              residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0
                ? residentPropertyAndUnitInfo[0].unit_id
                : ""
            );
            await storage.set(
              "propertyManagerId",
              residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0 &&
                residentPropertyAndUnitInfo[0].company_user_id
            );
            dispatch({
              type: ActionType.CURRENT_COMPANY_ID,
              payload:
                residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0
                  ? residentPropertyAndUnitInfo[0].company_user_id
                  : getPropertyId.data.data[0].company_user_id,
            });
            const propertyManager = await getPropertyManagers({
              id:
                residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0
                  ? residentPropertyAndUnitInfo[0].company_user_id
                  : getPropertyId.data.data[0].company_user_id,
            });
            if (propertyManager?.data?.success) {
              dispatch({
                type: ActionType.PAYMENT_LINK,
                payload: propertyManager.data.data[0].payment_link,
              });
            }
            const recordCountData = await recordCount(
              residentPropertyAndUnitInfo &&
                residentPropertyAndUnitInfo.length > 0
                ? residentPropertyAndUnitInfo[0].property_id
                : getPropertyId.data.data[0].property_id
            );

            if (recordCountData?.data?.success) {
              dispatch({
                type: ActionType.RECORD_COUNT,
                payload: recordCountData.data.data,
              });
            }
          }
          dispatch({
            type: ActionType.LOGIN,
            payload: responseData.data,
          });
          if (responseData.data.data.first_login) {
            navigate(`${ROUTES.MY_PROFILE}`);
          } else {
            navigate(`${ROUTES.Resident.RESIDENT_DASHBOARD}`);
          }
        }

        if (responseData.data.data.account_type === staff) {
          const getStaffPropertyId = await getStaffProperty(
            responseData.data.data._id
          );
          if (getStaffPropertyId?.data?.success) {
            await storage.set(
              "propertyId",
              getStaffPropertyId.data.data[0].property_id
            );

            dispatch({
              type: ActionType.LOGIN,
              payload: responseData.data,
            });
            dispatch({
              type: ActionType.STAFF_ROLE,
              payload: getStaffPropertyId.data.data[0].roleInfo.name,
            });
            await storage.set(
              "propertyManagerId",
              getStaffPropertyId.data.data[0].company_user_id
            );
            const getRecordCount = await recordCount(
              "all",
              storage.get("propertyManagerId")
            );

            if (getRecordCount?.data?.success) {
              dispatch({
                type: ActionType.RECORD_COUNT,
                payload: getRecordCount.data.data,
              });
            }
            if (responseData.data.data.first_login) {
              navigate(`${ROUTES.MY_PROFILE}`);
            } else if (
              getStaffPropertyId.data.data[0].roleInfo.name === manager
            ) {
              navigate(`${ROUTES.ALL_PROPETIES}`);
            } else {
              navigate(`${ROUTES.ALL_PROPETIES}`);
            }
          }
        }
      } else {
        toast.error(translation("not_for_admin"));
      }
    } else if (responseData?.error) {
      toast.error(translation(`email_valid`));
    } else {
      toast.error(translation(`${responseData?.data?.message}`));
    }
    setLoading(false);
  };

  /**
   *  checked or unchecked value for remember me
   */

  const checkBoxDataHandler = (data: boolean) => {
    setChecbox(data);
  };

  return (
    <div className="row m-0">
      <div className="col-md-5 p-0">
        <div
          className="loginleft"
          style={
            loginType === admin ? { backgroundImage: `url(${LoginLeft})` } : {}
          }
        >
          {loginType === user ? (
            <CommonLogin
              type={loginType as string}
              getLogin={onLogin}
              loading={loading}
              checkBoxDataHandler={checkBoxDataHandler}
            />
          ) : (
            " "
          )}
        </div>
      </div>

      <div className="col-md-7 p-0">
        <div
          className="loginright"
          style={
            loginType === admin
              ? { backgroundImage: `url(${LoginRight})` }
              : { backgroundImage: `url(${userLoginImage})` }
          }
        >
          {loginType === admin ? (
            <CommonLogin
              type={loginType as string}
              getLogin={onLogin}
              loading={loading}
              checkBoxDataHandler={checkBoxDataHandler}
            />
          ) : (
            " "
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
