import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { debounce } from "lodash";
import * as Sentry from "@sentry/react";

import {
  deleteMultipleResident,
  deleteResident,
  getResidentList,
  getResidentcredentialStatus,
  pendingResidentCount,
  updateResident,
} from "../../services/resident";
import { getDashboard, recordCount } from "../../services/property";
import { getAllPropertyUnits } from "../../services/unit";

import {
  IGerProperty,
  IShowProperty,
} from "../../interfaces/PropertyInterface";
import {
  IPropertyUnit,
  ISearch,
  IUserWithUnit,
} from "../../interfaces/ResidentInterface";
import { IAuthReducerData } from "../../interfaces/AuthInterface";

import {
  Action,
  maintenance,
  staff,
  leasingStaff,
  selectStatus,
} from "../../constants/commonConstants";
import { RootState } from "../../redux/reducers/Index";

import CommonSearchBar from "../../components/formElements/CommonSearchBar";
import CommonSelect from "../../components/formElements/CommonSelect";
import CommonUnitDetailsPopUp from "../../components/commonPopUp/CommonIUnitDeatilsPopUp";

import Header from "../../components/header/Header";
import Loading from "../../components/loader/Loader";
import ROUTES from "../../constants/routes";
import storage from "../../utils/storage";
import toast from "../../utils/toastMessage";
import ActionType from "../../resources/enums";

import "./Residents.scss";

export interface list {
  _id: string;
  user_id: string;
  unit_id: string;
  property_id: string;
  company_user_id: string;
  role: string;
  date: string;
  __v: number;
  userInfo: UserInfo;
  property_units: PropertyUnits;
}

export interface UserInfo {
  _id: string;
  status: string;
  first_name: string;
  last_name: string;
}

export interface PropertyUnits {
  _id: string;
  unit: number;
}
type state = {
  list: IUserWithUnit[];
  offset: number;
  prevOffset: number;
  hasMore: boolean;
};

const Residents: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const stateData: any = location.state;
  const [loading, setLoading] = useState<boolean>(false);
  const { t: translation } = useTranslation();
  const [addResidentEnable, setAddResidentEnable] = useState<boolean>(false);
  const [currentSearchData, setCurrentSearchData] = useState<string>("");
  const [currentDropDown, setCurrentDropDown] = useState<string>("");
  const [residentUserIdToDelete, setResidentUserIdToDelete] =
    useState<IUserWithUnit>();
  const [showConfirmationPopUp, setShowConfirmationPopUp] =
    useState<boolean>(false);
  const [updateResidentPopUp, setUpdateResidentPopUp] = useState({
    model: false,
    value: "",
  });
  const [residentListData, setResidentListData] = useState<state>({
    list: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });
  const [uploadStatus, setUploadStatus] = useState<boolean>(false);
  const [approvalPending, setApprovalPending] = useState<number>(0);
  const [showPop, setShowPopUp] = useState<boolean>(false);
  const [unitDetailsModalData, setUnitDetailsModalData] =
    useState<IUserWithUnit>();
  const [single, setSingle] = useState<string[]>([]);
  const residentIds = useRef<string[]>([]);
  const intervalIdRef: any = useRef(null);

  const [dashboardData, setDashboardData] = useState<IShowProperty>();
  const {
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm({});

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  let callResidenTimeout: null | ReturnType<typeof setTimeout> = null;
  /**
   * navigate to  Add Resident
   */
  const addResidentClick = () => {
    if (addResidentEnable || storage.get("residentsList") === "All") {
      navigate(`${ROUTES.ADD_RESIDENTS}`);
    } else {
      toast.info(translation(`all_units_occupied`));
    }
  };
  useEffect(() => {
    reset({
      statusType: stateData ? stateData?.status : "All",
    });
    callResidenTimeout = setTimeout(() => {
      residentList(true);
    }, 5000);

    calculatePendingResidentCount();
  }, [watch]);

  useEffect(() => {
    if (callResidenTimeout) {
      clearTimeout(callResidenTimeout);
    }
    stateData?.status && residentList(true, "", stateData?.status);
  }, [stateData?.status]);

  /**
   *  Get resident list
   */
  const residentList = useCallback(
    async (
      firstLoad: boolean,
      searchData?: string,
      status?: string
    ): Promise<void> => {
      setLoading(true);
      const offset: number = firstLoad ? 0 : residentListData.offset;
      status?.length && status !== undefined && setCurrentDropDown(status);
      const propertyId =
        storage.get("residentsList") === "All"
          ? "all"
          : storage.get("propertyId");
      if (
        residentListData.offset !== residentListData.prevOffset ||
        firstLoad
      ) {
        if (searchData || status) {
          const options: ISearch = {
            searchData,
            status,
          };
          if (propertyId === "all") {
            options.companyUserId = storage.get("propertyManagerId");
          }
          const offsetVal = firstLoad ? 0 : offset;
          const residentListInfoWithSearch = await getResidentList(
            propertyId,
            offsetVal,
            options
          );
          if (residentListInfoWithSearch?.data?.success) {
            const limit = 10;
            if (residentListInfoWithSearch?.data?.success) {
              setLoading(false);
              setResidentListData((prevState) => ({
                ...prevState,
                prevOffset: firstLoad ? 0 : prevState.offset,
                offset: firstLoad ? limit : prevState.offset + limit,
                list: firstLoad
                  ? residentListInfoWithSearch?.data?.data
                  : prevState.list.concat(
                      residentListInfoWithSearch?.data?.data
                    ),
                hasMore: true,
              }));
            } else {
              setLoading(false);
            }
          }
        } else {
          const offsetValue = firstLoad ? 0 : offset;
          const options: ISearch = {
            companyUserId: storage.get("propertyManagerId"),
          };
          const residentListInfo = await getResidentList(
            propertyId,
            offsetValue,
            options
          );
          if (residentListInfo?.data?.success) {
            const limit = 10;
            if (residentListInfo?.data?.success) {
              setLoading(false);
              setResidentListData((prevState) => ({
                ...prevState,
                prevOffset: firstLoad ? 0 : prevState.offset,
                offset: firstLoad ? limit : prevState.offset + limit,
                list: firstLoad
                  ? residentListInfo?.data?.data
                  : prevState.list.concat(residentListInfo?.data?.data),
                hasMore: true,
              }));
            } else {
              setLoading(false);
            }
          }
        }
      }
    },
    [residentListData.offset, residentListData.prevOffset]
  );
  /**
   *  Calculate resident count of the residents with pending status
   */
  const calculatePendingResidentCount = async () => {
    const statusCountForAllResident =
      storage.get("residentsList") === "All"
        ? "all"
        : storage.get("propertyId");
    const propertyManagerId =
      statusCountForAllResident === "all"
        ? storage.get("propertyManagerId")
        : "";
    const pendingResident = await pendingResidentCount(
      statusCountForAllResident,
      propertyManagerId
    );
    if (pendingResident.data.success) {
      setApprovalPending(pendingResident.data.data);
    }
  };
  /**
   * update resident status
   */
  const handleActivaleValue = async (value: string, index: number) => {
    if (residentListData && residentListData.list) {
      residentListData.list[index].status = value;
      const updateInfo = await updateResident(
        [residentListData.list[index]._id],
        value
      );
      if (updateInfo.data && updateInfo.data.success) {
        calculatePendingResidentCount();
        residentList(
          true,
          currentSearchData?.length ? currentSearchData : "",
          currentDropDown?.length ? currentDropDown : ""
        );
        toast.success(translation(`${updateInfo.data.message}`));
      } else {
        toast.error(translation(`${updateInfo?.data?.message}`));
      }
    }
  };
  /**
   *  Search functionality
   */
  const searchFilter = debounce(async (searchText?: string) => {
    try {
      searchText !== undefined && setCurrentSearchData(searchText);
      await residentList(
        true,
        searchText,
        currentDropDown?.length ? currentDropDown : ""
      );
    } catch (error) {
      console.log("Error", Error);
      Sentry.captureException(Error);
    }
  }, 300);

  useEffect(() => {
    const params: IGerProperty = {
      property_id: storage.get("propertyId") as string,
    };
    if (storage.get("propertyId")) {
      getDashboardData(params);
      getUnits(params?.property_id);
    }
    updateUserCount();
  }, []);

  /**
   * Property Manager detail
   */
  const getDashboardData = async (data: IGerProperty) => {
    const dashboard = await getDashboard(data);
    if (dashboard?.data?.success) {
      setDashboardData(dashboard.data.data[0]);
    }
  };

  /**
   * get the units of the property and check status vacant or occupied
   */
  const getUnits = async (id: string) => {
    const responseData = await getAllPropertyUnits(id);
    if (responseData?.data?.success && responseData?.data?.data.length > 0) {
      setAddResidentEnable(true);
    } else {
      setAddResidentEnable(false);
    }
  };
  /**
   * navigate to unit detail page
   */
  const unitDetail = async (item: IPropertyUnit, userId: string) => {
    // if(storage.get("residentsList")==="All"){
    storage.set("propertyId", item.property_id);
    storage.set("residentsList", "");
    storage.set("roomId", item.smartthing_room_id);
    const recordCountData = await recordCount(storage.get("propertyId"));
    if (recordCountData?.data?.success) {
      dispatch({
        type: ActionType.RECORD_COUNT,
        payload: recordCountData.data.data,
      });
    }
    if (authReducer.authData.account_type !== staff)
      navigate(ROUTES.UNITDETAIL, {
        state: {
          parentComponent: {
            name: dashboardData?.building_name,
            route: ROUTES.DASHBOARD,
          },
          childComponent: {
            name: item.unit,
            route: ROUTES.UNITS,
          },
          unitId: item._id,
          unitName: item.unit,
          residentId: userId,
        },
      });
  };
  /**
   *  delete resident
   */
  const deleteResidentRecord = async () => {
    setLoading(true);

    if (residentUserIdToDelete || single) {
      let responseData;
      if (single.length > 1) {
        responseData = await deleteMultipleResident({
          id: single,
          deletedBy: authReducer.authData.id,
          property_id:
            storage.get("residentsList") === "All"
              ? "all"
              : storage.get("propertyId"),
        });
      } else {
        responseData = await deleteResident({
          resident_id: residentUserIdToDelete?._id
            ? residentUserIdToDelete?._id
            : single[0],
          deletedBy: authReducer.authData.id,
          property_id:
            storage.get("residentsList") === "All"
              ? "all"
              : storage.get("propertyId"),
        });
      }
      if (responseData?.data?.success) {
        calculatePendingResidentCount();
        await updateUserCount();
        toast.success(translation(`${responseData.data.message}`));
        setShowConfirmationPopUp(false);
        setSingle([]);
      } else {
        toast.error(translation(`${responseData?.data?.message}`));
      }
    } else {
      toast.error(translation("select_resident"));
    }
    setLoading(false);
  };

  /**
   * Update the users count
   */
  const updateUserCount = async (): Promise<void> => {
    const getRecordCount = await recordCount(
      storage.get("residentsList") === "All"
        ? "all"
        : storage.get("propertyId"),
      storage.get("propertyManagerId")
    );

    if (getRecordCount?.data?.success) {
      dispatch({
        type: ActionType.RECORD_COUNT,
        payload: getRecordCount.data.data,
      });
      await residentList(true, "", stateData?.status);
      setLoading(false);
    }
  };

  /**
   * close popup model
   */
  const closePopUp = async (childData: boolean) => {
    if (childData) {
      setShowPopUp(false);
    } else {
      setShowPopUp(false);
    }
  };

  /**
   *  changning the status
   */
  const handleResidentSelection = (
    { currentTarget: { checked } }: ChangeEvent<HTMLInputElement>,
    id?: string
  ) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    const tmp = single;

    if (checked && id) {
      tmp?.push(id);
    } else {
      const index = tmp.findIndex((ele: string) => ele === id);
      tmp.splice(index, 1);
    }

    setSingle([...tmp]);
    if (!residentListData?.hasMore) {
      setSelectAll(true);
    }
    if (residentListData.list.length === single.length) {
      setSelectAll(true);
    }
    if (!checked) {
      setSelectAll(false);
    }
  };

  const startInterval = () => {
    let intervalCallingTime = 0;
    intervalIdRef.current = setInterval(() => {
      intervalCallingTime += 1;
      if (intervalCallingTime < 6) {
        getResidentData();
      } else {
        closePopUpAndfetchResidentDetails();
        clearInterval(intervalIdRef.current);
      }
    }, 10000);
  };
  const getResidentData = async () => {
    calculatePendingResidentCount();
    const resultData = await getResidentcredentialStatus(residentIds.current);

    if (resultData.data && resultData.data.success) {
      if (resultData.data.data.length) {
        if (resultData.data.data.length === residentIds.current.length) {
          residentIds.current.length = 0;
        } else {
          resultData.data.data.forEach((item: string) => {
            residentIds.current.splice(
              residentIds.current.findIndex(
                (residentItem) => residentItem === item
              ),
              1
            );
          });
        }
        if (residentIds.current.length === 0) {
          closePopUpAndfetchResidentDetails();
          clearInterval(intervalIdRef.current);
        }
      }
    }
  };

  const closePopUpAndfetchResidentDetails = async () => {
    clearInterval(intervalIdRef);
    calculatePendingResidentCount();
    residentList(
      true,
      currentSearchData?.length ? currentSearchData : "",
      currentDropDown?.length ? currentDropDown : ""
    );
    setUploadStatus(false);
    setUpdateResidentPopUp({ model: false, value: "" });
    toast.success(translation("user_status_updated"));
    setSelectAll(false);
    setSingle([]);
  };
  /**
   * update multiple resident status
   */
  const updateResidentStatus = async (data: string[]) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    setUploadStatus(true);
    if (residentListData && residentListData.list) {
      const filterData: string[] = [];
      if (single.length > 0) {
        residentListData.list.forEach((item) => {
          if (
            single.includes(item._id) &&
            item.credentials_sent === false &&
            updateResidentPopUp.value === "Approved"
          ) {
            filterData.push(item._id);
          }
        });
      }
      residentIds.current = filterData;

      const updateInfo = await updateResident(data, updateResidentPopUp.value);
      if (updateInfo.data && updateInfo.data.success) {
        if (residentIds.current.length) {
          startInterval();
        } else {
          calculatePendingResidentCount();
          residentList(
            true,
            currentSearchData?.length ? currentSearchData : "",
            currentDropDown?.length ? currentDropDown : ""
          );
          setUploadStatus(false);
          setSingle([]);
          setUpdateResidentPopUp({ model: false, value: "" });
          toast.success(translation(`${updateInfo.data.message}`));
          setSelectAll(false);
        }
      } else {
        setUploadStatus(false);
        setUpdateResidentPopUp({ model: false, value: "" });

        toast.error(translation(`${updateInfo?.data?.message}`));
      }
    }
  };

  /**
   *
   * select all functionality
   */
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const handleSelectAllResidentSelection = ({
    currentTarget: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    if (checked) {
      const selectedResidentIds: string[] = [];
      residentListData.list.forEach((ele) => {
        selectedResidentIds.push(ele._id);
      });
      setSingle(selectedResidentIds);
    } else {
      setSingle([]);
    }
    setSelectAll(checked);
  };

  return (
    <div className="residents-page">
      <CommonUnitDetailsPopUp
        showPopUpModal={showPop}
        unitData={unitDetailsModalData}
        closePopUpModal={closePopUp}
        buildingName={dashboardData ? dashboardData.building_name : ""}
      />
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="p-4">
          {/* {authReducer.authData.account_type === staff ? (
            authReducer.roleType === manager ? (
              <Header
                parentComponent={{
                  route: ROUTES.DASHBOARD,
                  name: dashboardData?.building_name,

                  homeRoute: ROUTES.DASHBOARD,
                }}
                childComponent={{
                  name: translation("residents"),
                  route: ROUTES.RESIDENTS,
                  extendedName: translation("all"),
                }}
              />
            ) : (
              <Header />
            )
          ) : ( */}
          {authReducer.authData.account_type === staff &&
          authReducer.roleType !== leasingStaff ? (
            <Header
              parentComponent={{
                name: dashboardData?.building_name,
                homeName: translation("all_buildings"),
                homeRoute: ROUTES.ALL_PROPETIES,
              }}
              childComponent={{
                name: translation("residents"),
                route: ROUTES.RESIDENTS,
                extendedName: translation("all"),
              }}
            />
          ) : storage.get("residentsList") === "All" ? (
            <Header
              parentComponent={{
                // route: ROUTES.DASHBOARD,
                // name: dashboardData?.building_name,
                name: translation("all_buildings"),
                route: ROUTES.ALL_PROPETIES,
              }}
              childComponent={{
                name: translation("residents"),
                route: ROUTES.RESIDENTS,
                extendedName: translation("all"),
              }}
            />
          ) : (
            <Header
              parentComponent={{
                route: ROUTES.DASHBOARD,
                name: dashboardData?.building_name,
                homeName: translation("all_buildings"),
                homeRoute: ROUTES.ALL_PROPETIES,
              }}
              childComponent={{
                name: translation("residents"),
                route: ROUTES.RESIDENTS,
                extendedName: translation("all"),
              }}
            />
          )}

          <div className="d-flex justify-content-between">
            <div>
              <h2 className="theme-color fw-bold">
                {approvalPending} {translation("residents")}
              </h2>
              <p className="op-6">{translation("awaiting_approval")}</p>
            </div>
            <div className="d-flex align-items-center">
              <div className="select-margin">
                <CommonSelect
                  className="form-select border-0"
                  aria-label="Default select example"
                  dropDownData={selectStatus}
                  error={errors?.statusType}
                  control={control}
                  name="statusType"
                  multipleSelect={false}
                  defaultValue={stateData?.status}
                  firstOption={translation("select_status")}
                  disabled={false}
                  onChange={(value) =>
                    residentList(
                      true,
                      currentSearchData?.length ? currentSearchData : "",
                      value
                    )
                  }
                />
              </div>
              <CommonSearchBar
                handleChange={searchFilter}
                searchPlaceholderText="search_here"
                isSearch
              />
              {authReducer.roleType !== maintenance && (
                <button
                  className="theme-button primary-btn ms-3"
                  type="button"
                  disabled={loading}
                  onClick={addResidentClick}
                >
                  {translation("add_resident")}
                </button>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="table-responsive mt-3">
              <InfiniteScroll
                dataLength={residentListData?.list?.length}
                next={() =>
                  residentList(
                    false,
                    currentSearchData?.length ? currentSearchData : "",
                    currentDropDown?.length
                      ? currentDropDown
                      : stateData?.status?.length
                      ? stateData?.status
                      : ""
                  )
                }
                hasMore={residentListData?.hasMore}
                loader=""
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    {residentListData?.list?.length > 0 ? (
                      <div>{translation("infinite_scroll_end")}</div>
                    ) : (
                      <div className="auth-msg error">
                        {translation("no_company_found")}
                      </div>
                    )}
                  </p>
                }
              >
                <table className="w-100 custom-table table">
                  <thead>
                    <tr>
                      <td aria-label="Description">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={(e) => {
                            handleSelectAllResidentSelection(
                              e as unknown as ChangeEvent<HTMLInputElement>
                            );
                          }}
                        />
                      </td>
                      <td>{translation("name")}</td>
                      <td>{translation("email")}</td>
                      <td>{translation("role")}</td>
                      <td>{translation("unit")}</td>
                      {authReducer.roleType !== maintenance && (
                        <td className="text-center">{translation("action")}</td>
                      )}
                      <td aria-label="Description">&nbsp;</td>

                      {authReducer.authData.account_type !== staff && (
                        <td
                          aria-label="Description"
                          className="bd-top-radius-right"
                        >
                          {" "}
                        </td>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {residentListData && residentListData.list.length > 0 ? (
                      residentListData?.list?.map(
                        (item: IUserWithUnit, index: number) => (
                          <tr>
                            <td aria-label="Description">
                              <input
                                type="checkbox"
                                checked={single && single?.includes(item._id)}
                                onChange={(e) => {
                                  handleResidentSelection(
                                    e as unknown as ChangeEvent<HTMLInputElement>,
                                    item._id
                                  );
                                }}
                                // onChange={() =>
                                //   toast.info(translation("unit_occupied"))
                                // }
                              />
                            </td>
                            <td>{`${item?.first_name} ${item?.last_name}`}</td>
                            <td>{item?.email}</td>
                            <td>{item?.properties[0].role}</td>
                            <td className="theme-color cursorA">
                              {item?.properties && item?.properties.length ? (
                                item?.properties.filter(
                                  (data) => data?.unitsInfo?.length
                                ).length > 1 ? (
                                  <p
                                    onClick={() => {
                                      setShowPopUp(true);
                                      setUnitDetailsModalData(item);
                                    }}
                                    className="see-more-align"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="25.386"
                                      height="40"
                                      viewBox="0 0 25.386 40"
                                    >
                                      <path
                                        id="Path_7"
                                        data-name="Path 7"
                                        d="M12.441,13.459,28.752-7.433a1.879,1.879,0,0,0,.479-1.172,1.179,1.179,0,0,0-.368-.882,1.3,1.3,0,0,0-.948-.357H17.852l5.256-13.813a1.6,1.6,0,0,0-.033-1.525,1.3,1.3,0,0,0-1.118-.588,1.662,1.662,0,0,0-1.322.762L4.324-4.1a1.862,1.862,0,0,0-.479,1.155,1.177,1.177,0,0,0,.369.883,1.307,1.307,0,0,0,.947.356H15.226L9.953,12.108A1.642,1.642,0,0,0,10,13.641a1.271,1.271,0,0,0,1.117.589A1.684,1.684,0,0,0,12.441,13.459Zm1.027-4.85L19.487-4.459H8.5L20.148-19.885l-.572-.271-6,13.065H24.56L12.915,8.338Z"
                                        transform="translate(-3.845 25.77)"
                                      />
                                    </svg>{" "}
                                    <u>{translation("see_more")}</u>
                                  </p>
                                ) : item?.properties.filter(
                                    (data) => data?.unitsInfo?.length
                                  ).length > 0 ? (
                                  <p
                                    onClick={() => {
                                      unitDetail(
                                        item?.properties.filter(
                                          (data) => data?.unitsInfo?.length > 0
                                        )[0]?.unitsInfo[0],
                                        residentListData?.list[index]._id
                                      );
                                    }}
                                  >
                                    {
                                      item.properties.filter(
                                        (data) => data?.unitsInfo?.length
                                      )[0]?.unitsInfo[0]?.unit
                                    }{" "}
                                    <br />
                                  </p>
                                ) : (
                                  "-"
                                )
                              ) : (
                                "-"
                              )}
                            </td>
                            {/* <td>{item?.date}</td> */}
                            {authReducer.roleType !== maintenance && (
                              <td>
                                <div className="margin-common-remove">
                                  <CommonSelect
                                    className="form-select border-0"
                                    aria-label="Default select example"
                                    dropDownData={Action}
                                    error={errors?.Action}
                                    control={control}
                                    name={`Action${index}`}
                                    multipleSelect={false}
                                    firstOption={translation("select_action")}
                                    onChange={(value) =>
                                      single.length > 0
                                        ? setUpdateResidentPopUp({
                                            model: true,
                                            value,
                                          })
                                        : handleActivaleValue(value, index)
                                    }
                                    value={item?.status}
                                    from={translation("resident_option")}
                                  />
                                </div>
                              </td>
                            )}
                            {(authReducer.authData.account_type !== staff ||
                              (authReducer.authData.account_type === staff &&
                                authReducer.roleType === leasingStaff)) && (
                              <td>
                                <div className="action-btn-show">
                                  <svg
                                    onClick={() => {
                                      setShowConfirmationPopUp(true);
                                      setResidentUserIdToDelete(item);
                                    }}
                                    id="Layer_1"
                                    data-name="Layer 1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 105.16 122.88"
                                  >
                                    <title>{translation("delete")}</title>
                                    <path d="M11.17,37.16H94.65a8.4,8.4,0,0,1,2,.16,5.93,5.93,0,0,1,2.88,1.56,5.43,5.43,0,0,1,1.64,3.34,7.65,7.65,0,0,1-.06,1.44L94,117.31v0l0,.13,0,.28v0a7.06,7.06,0,0,1-.2.9v0l0,.06v0a5.89,5.89,0,0,1-5.47,4.07H17.32a6.17,6.17,0,0,1-1.25-.19,6.17,6.17,0,0,1-1.16-.48h0a6.18,6.18,0,0,1-3.08-4.88l-7-73.49a7.69,7.69,0,0,1-.06-1.66,5.37,5.37,0,0,1,1.63-3.29,6,6,0,0,1,3-1.58,8.94,8.94,0,0,1,1.79-.13ZM5.65,8.8H37.12V6h0a2.44,2.44,0,0,1,0-.27,6,6,0,0,1,1.76-4h0A6,6,0,0,1,43.09,0H62.46l.3,0a6,6,0,0,1,5.7,6V6h0V8.8h32l.39,0a4.7,4.7,0,0,1,4.31,4.43c0,.18,0,.32,0,.5v9.86a2.59,2.59,0,0,1-2.59,2.59H2.59A2.59,2.59,0,0,1,0,23.62V13.53H0a1.56,1.56,0,0,1,0-.31v0A4.72,4.72,0,0,1,3.88,8.88,10.4,10.4,0,0,1,5.65,8.8Zm42.1,52.7a4.77,4.77,0,0,1,9.49,0v37a4.77,4.77,0,0,1-9.49,0v-37Zm23.73-.2a4.58,4.58,0,0,1,5-4.06,4.47,4.47,0,0,1,4.51,4.46l-2,37a4.57,4.57,0,0,1-5,4.06,4.47,4.47,0,0,1-4.51-4.46l2-37ZM25,61.7a4.46,4.46,0,0,1,4.5-4.46,4.58,4.58,0,0,1,5,4.06l2,37a4.47,4.47,0,0,1-4.51,4.46,4.57,4.57,0,0,1-5-4.06l-2-37Z" />
                                  </svg>
                                </div>
                              </td>
                            )}
                            <td aria-label="Description">&nbsp;</td>
                          </tr>
                        )
                      )
                    ) : loading ? (
                      <tr>
                        <td colSpan={7}>
                          <div className="item-center">
                            {" "}
                            <Loading />{" "}
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={7}>
                          <div className="danger-color text-center">
                            {translation("no_resident_found")}
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </form>
      {showConfirmationPopUp && (
        <div className="modal modal-custom-show">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-center p-4">
                <div className="text-center">
                  <h3 className="theme-color">
                    {translation("confirm_delete_message_resident")}
                  </h3>
                </div>
              </div>

              <div>
                <div className="modal-body">
                  <div className="inner">
                    <div className="text-center mt-3">
                      <button
                        className="theme-button primary-btn"
                        type="submit"
                        disabled={loading}
                        onClick={() => {
                          deleteResidentRecord();
                        }}
                      >
                        {translation("yes")}
                        {loading ? (
                          <div
                            className="spinner-border text-light ms-2 btn-loader"
                            role="status"
                          >
                            <span className="sr-only" />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>

                      <button
                        className="theme-button black-btn-outline"
                        type="submit"
                        disabled={loading}
                        onClick={() => setShowConfirmationPopUp(false)}
                      >
                        {translation("no")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {updateResidentPopUp.model && (
        <div className="modal modal-custom-show">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header justify-content-center p-4">
                <div className="text-center">
                  <h3 className="theme-color">
                    {intervalIdRef.current
                      ? `${translation("sending_mails")} ${
                          single.length && residentIds.current.length
                            ? (
                                ((single.length - residentIds.current.length) /
                                  single.length) *
                                100
                              ).toFixed()
                            : 0
                        }%`
                      : translation("update_satus_of_resident")}
                  </h3>
                </div>
              </div>

              <div>
                <div className="modal-body">
                  <div className="inner">
                    <div className="text-center mt-3">
                      <button
                        className="theme-button primary-btn"
                        type="submit"
                        disabled={uploadStatus}
                        onClick={() => {
                          updateResidentStatus(single);
                        }}
                      >
                        {translation("yes")}
                        {uploadStatus ? (
                          <div
                            className="spinner-border text-light ms-2 btn-loader"
                            role="status"
                          >
                            <span className="sr-only" />
                          </div>
                        ) : (
                          ""
                        )}
                      </button>

                      <button
                        className="theme-button black-btn-outline"
                        type="submit"
                        disabled={uploadStatus}
                        onClick={() =>
                          setUpdateResidentPopUp({ model: false, value: "" })
                        }
                      >
                        {translation("no")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Residents;
