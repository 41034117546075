import { http } from "../utils/http";

import { IAddNewStaff } from "../interfaces/ResidentInterface";
import { IDeleteStaffParam } from "../interfaces/StaffInterface";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";

/**
 *  Get Stafflist for a property
 */
export const staffList = (
  data: string,
  optionalParam: any
): Promise<ApiResponse> => {
  const queryParams: string[] = [];
  Object.keys(optionalParam).forEach((key) => {
    queryParams.push(`${key}=${optionalParam[key]}`);
  });
  return http.get(
    `${endpoint.Staff.GETSTAFFLIST.replace(":property_id", data)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 * Add new staff for a property
 */
export const addNewStaff = (data: IAddNewStaff) => {
  return http.post(`${endpoint.Staff.ADDSTAFF}`, data);
};

/**
 * Delete Staff
 */
export const deleteStaff = (data: IDeleteStaffParam) => {
  return http.post(`${endpoint.Staff.DELETESTAFF}`, data);
};

/**
 *
 * get property id of the staff
 */
export const getStaffProperty = (id: string) => {
  return http.get(`${endpoint.Staff.STAFF_PROPERTY.replace(":id", id)}`);
};
/**
 *
 *staff notifications
 */
// export const staffNotfications = (data: INotifications) => {
//   return http.post(`${endpoint.Staff.STAFFNOTIFICATION}`, data);
// };
