import React from "react";
import Select from "react-select";

import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";

import { useTranslation } from "react-i18next";

import { IMultiSelect } from "../../interfaces/CommonInterface";
import { approved, inprogress, pending } from "../../constants/commonConstants";

interface IProps {
  label?: string;
  isMulti?: boolean;
  value?: unknown;
  isSearchable: boolean;
  name: string;
  options: IMultiSelect[];
  control: Control;
  errors?: FieldErrors<FieldValues> | FieldError;
  className?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const CommonMultiSearchSelect: React.FC<IProps> = (props) => {
  const {
    label,
    isMulti,
    isSearchable,
    name,
    options,
    control,
    errors,
    required,
    value = [],
    className,
    placeholder,
    disabled,
    onChange,
  } = props;

  const { t: translation } = useTranslation();

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={`${label}_${name}`}>
          {label} {required ? <sup className="colorRed">*</sup> : null}
        </label>
      )}
      <Controller
        render={({ field }) => (
          <Select
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e);
            }}
            onBlur={field.onBlur}
            value={field.value}
            name={name}
            isDisabled={disabled}
            isMulti={isMulti}
            isSearchable={isSearchable}
            options={options}
            id={`${label}_${name}`}
            // className={className}
            className={`${
              field.value === pending
                ? `form-control  ${className}  pending`
                : field.value === inprogress
                ? `form-control  ${className} denied`
                : field.value === approved
                ? `form-control  ${className} approve`
                : `custom-select-multi ${className}`
            }`}
          />
        )}
        name={name}
        control={control}
        defaultValue={value}
      />
      {/* Common Error message component  */}
      <div className="auth-msg error">
        <p>
          {errors && errors.message ? (
            <>{translation(`${errors.message}`, { field: name })}</>
          ) : null}
        </p>
      </div>{" "}
    </div>
  );
};
export default CommonMultiSearchSelect;
