const ROUTES = {
  auth: {
    LOGIN: "/auth/login",
    FORGETPASSWORD: "/auth/forgot-password",
    RESETPASSWORD: "/auth/reset-password",
    VERIFYOTP: "/auth/otp-verification",
    DISABLE_USER: "/auth/disable-user/:user_id",
    CHANGEPASSWORD: "/auth/change-password",
    DELETEIMAGE: "/auth/delete-image",
    MATCHTOKEN: "/auth/match-token",
  },
  Company: {
    ADDCOMPANY: "/auth/add-user",
    GETCOMPANY: "/user/companies",
    UPDATECOMPANY: "/auth/update-company",
    DELETECOMPANY: "/user/delete-company",
    GETAPIKEY: "/auth/api-key/:id",
    COMPANYNOTIFICATION: "/user/company-notification",
    STAFFREORDCOUNT: "/user/get-staff-record-count/:company_user_id",
  },

  UPDATEPROFILE: "/user/edit-profile",
  GETPROPERTYUNITS: "/units/:property_id",
  ADDCOMPANY: "/auth/add-user",
  ADDRESIDENT: "/resident",
  GETCOMPANY: "/user/companies",
  GETPROPERTIES: "/user/properties/:id",
  ADDPROPERTIES: "/user/add-properties",
  PRESIGNEDURL: "/user/generate-presignedurl",
  GETDASHBOARD: "/user/get-dashboard/:property_id",
  GETUNITDETAIL: "/device/:property_id/:unit_id",
  GETRESIDENT: "/resident/:property_id",
  UPDATERESIDENT: "/resident/user/:id",
  GETROLES: "/roles",
  DELETEROLES: "/roles/:role_id",
  STAFF: "/staff",
  property: {
    GETPROPERTY: "/user/get-property/:property_id",
    DELETEPROPERTY: "/user/delete-property",
    GETPROPERTYMANAGER: "/user/get-property-manager/:property_manager_id",
    GETPROPERTIES: "/user/get-properties",
  },
  Unit: {
    VACANTUNIT: "/units/vacant-unit",
    DELETEUNIT: "/units/delete-unit",
    ASSIGNUNIT: "/units/assign",
    GETVACANTUNIT: "/units/vacant-unit-list/:property_id",
    GETUNITLIST: "/units/unit-list/:property_name",
    GETUNITDETAIL: "/device/:property_id/:unit_id",
    SETVACANTUNITSETTING: "/device/set-vacant-unit-setting",
    GETRESIDENTUNITLIST: "/resident/unit-list/:id/:property_id",
    ADDUNIT: "/units/add-unit",
  },
  Staff: {
    GETSTAFFLIST: "/staff/staff-list/:property_id",
    ADDSTAFF: "/staff/add-staff",
    DELETESTAFF: "/staff/delete-staff",
    STAFF_PROPERTY: "/staff/get-staff-property/:id",
    STAFF: "/staff",
    STAFFNOTIFICATION: "/staff/staff-notifications",
  },
  google: {
    SEARCH_PREDICTION: "/google/get-predictions",
    PLACES_DETAIL: "/google/get-places-details",
    PLACE_DETAILS: "/google/location-details",
  },
  Property: {
    REORDCOUNT: "/user/get-record-count/:property_id",
    GETPROPERTYUNITS: "/units/:property_id",
    GETPROPERTY: "/user/get-property/:property_id",
    GETPROPERTYMANAGER: "/user/get-property-manager/:property_manager_id",
    GETPROPERTIES: "/user/properties/:id",
    ADDPROPERTIES: "/user/add-properties",
    PRESIGNEDURL: "/user/generate-presignedurl",
    GETDASHBOARD: "/user/get-dashboard/:property_id",
    UPDATEPROPERTY: "/user/update-property",
    GETAPIKEYS: "user/get-api-keys/:id",
    UNITCHECK: "user/unit-check/:id/:units",
    DELETECOMPANYACCESSTOKEN: "user/delete-company-access-token/:id",
    GETRESIDENTPROPERTYLIST:
      "/resident/property-list/:user_id/:company_user_id",
    GETRESIDENTCOMPANYLIST: "/resident/company-list/:user_id",
  },
  Resident: {
    GETRESIDENTPROPERTY: "/resident/get-resident-property/:id",
    GETPENDINGCOUNT: "/resident/status-pending/:property_id",
    ADDRESIDENT: "/resident",
    GETRESIDENT: "/resident/:property_id",
    UPDATERESIDENT: "/resident/user",
    GETRESIDENTCREDENTIALSTATUS:
      "/resident/get-user-credential-status/:user_ids",
    SUPPORT: "/support/add-support-ticket",
    GETROOMID: "/resident/get-resident-unit-detail/:resident_id",
    DELETERESIDENT: "/resident/delete-resident/:id/:deleted_by/:property_id",
    DELETEMULTIPLERESIDENT: "/resident/delete-multiple-resident",
    RESIDENTNOTIFICATION: "/resident/resident-notifications",
    GET_PRESIGNED_URL: "/resident/pre-signed-url",
    IMPORT_RECORD: "/resident/import-record",
    READ_CSV: "/resident/read-csv-record",
    READ_CSV_ERROR: "/resident/csv-error/:property_id/:user_id",
    DELETE_CSV_DATA: "/resident/delete-csv-data/:property_id/:user_id",
    READ_TOTAL_COUNT: "/resident/total-csv-data/:property_id/:user_id",
  },
  Roles: {
    GETROLES: "/roles",
    DELETEROLES: "/roles/:role_id",
  },
  Notifications: {
    NOTIFICATION: "/notifications/:user_id",
    DELETENOTIFICATION: "/notifications/:user_id",
    COUNTNOTIFICATIONS: "/notifications/count/:user_id",
    UPDATECOUNTNOTIFICATIONS: "/notifications/:user_id",
  },
  Device: {
    GETDEVICEREPORT: "/device/device-report/:property_id",
    GETDEVICEREPORTHUB: "/device/device-report-hub/:unit_id",
    CONTROLDEVICE: "/device/control-device",
  },

  Support: {
    GETCOUNT: "/support/count",
    GETSUPPORTICKET: "/support",
    ADDSUPPORTICKET: "/support/add-support-ticket",
    UPDATESUPPORTICKET: "/support/update-support-ticket",
    FETCHTOTALRECORD: "/support/get-support-count",
    DOWNLOADFILE: "/support/get-support-data",
    GETSUPPORTDATAURL: "/support/get-support-data-url",
  },
  Email: {
    GETBUILDINGLIST: "/email/get-building-list/:id",
    SENDEMAILS: "/email/send-email",
  },
};
export default ROUTES;
