import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  deleteNotifications,
  getNotifications,
  updateAllNotification,
} from "../../services/notifications";

import { IAuthReducerData } from "../../interfaces/AuthInterface";
import { IgetNotificationsList } from "../../interfaces/Notifications";

import { RootState } from "../../redux/reducers/Index";

import Header from "../../components/header/Header";
import ROUTES from "../../constants/routes";
import toast from "../../utils/toastMessage";
import Loading from "../../components/loader/Loader";

import units from "../../assets/images/units.svg";

type State = {
  getNotificationsList: IgetNotificationsList[];
  offset: number;
  prevOffset: number;
  hasMore: boolean;
};

const Notifications: React.FC = () => {
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { t: translation } = useTranslation();
  const [notificationState, setNotificationState] = useState<State>({
    getNotificationsList: [],
    offset: 0,
    prevOffset: 0,
    hasMore: true,
  });

  /**
   *  Get Notification data
   */
  const getNotificationsData = async (firstLoad: boolean) => {
    const offset = firstLoad ? 0 : notificationState.offset;

    if (
      notificationState.offset !== notificationState.prevOffset ||
      firstLoad
    ) {
      setLoading(true);
      const options = {
        offset,
        user_Id: authReducer.authData.id,
      };

      const notifications = await getNotifications(options);
      if (notifications?.data?.data?.length <= 10) {
        setNotificationState((prevState) => ({
          ...prevState,
          prevOffset: firstLoad ? 0 : prevState.offset,
          offset: firstLoad ? 1 : prevState.offset + 1,
          getNotificationsList: firstLoad
            ? notifications?.data?.data
            : prevState.getNotificationsList.concat(notifications?.data?.data),
          hasMore: notifications?.data?.data?.length < 10 ? false : true,
        }));
      } else {
        setNotificationState((prevState) => ({
          ...prevState,
          getNotificationsList: firstLoad
            ? []
            : prevState.getNotificationsList.concat(notifications?.data?.data),
          hasMore: false,
        }));
      }
    }
    setLoading(false);
    return;
  };

  /**
   *  clear all notification
   */
  const clearAllNotifications = async () => {
    setLoading(true);
    const deleteNotificationsData = await deleteNotifications(
      authReducer.authData.id
    );
    if (deleteNotificationsData?.data?.success) {
      toast.success(translation(`${deleteNotificationsData?.data?.message}`));
      setLoading(false);
      getNotificationsData(true);
    } else {
      toast.error(translation(`${deleteNotificationsData?.data?.message}`));
      setLoading(false);
    }
  };

  /**
   *  Update notification count
   */
  const updateAllNotificationCount = async () => {
    await updateAllNotification(authReducer?.authData?.id);
  };

  useEffect(() => {
    getNotificationsData(true);
    updateAllNotificationCount();
  }, []);
  return (
    <div className="p-4">
      <Header
        childComponent={{
          name: translation("notification"),
          route: ROUTES.NOTIFICATIONS,
        }}
      />
      <div className="d-flex justify-content-between">
        <div>
          <h2 className="theme-color fw-bold">
            {translation("notifications")}
          </h2>
        </div>
        <button
          className="theme-button grey-btn-sm"
          type="button"
          onClick={clearAllNotifications}
          disabled={
            notificationState.getNotificationsList.length > 0 ? false : true
          }
        >
          {translation("clear_all")}
          {loading && notificationState?.getNotificationsList?.length > 0 ? (
            <div
              className="spinner-border text-light ms-2 btn-loader"
              role="status"
            >
              <span className="sr-only" />
            </div>
          ) : (
            ""
          )}
        </button>
      </div>
      {notificationState?.getNotificationsList?.length > 0 ? (
        <InfiniteScroll
          dataLength={notificationState?.getNotificationsList?.length}
          next={() => getNotificationsData(false)}
          hasMore={notificationState?.hasMore}
          loader=""
          className="infinite-custom-scroll"
        >
          {notificationState?.getNotificationsList?.map(
            (item: IgetNotificationsList) => (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="white-card">
                    <div className="d-flex">
                      <div className="theme-border me-2">
                        <img src={units} alt="" />
                      </div>
                      <div>
                        <h4 className="op-3"> </h4>
                        <p className="mt-1">{item?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </InfiniteScroll>
      ) : !loading ? (
        <div className="item-center danger-color">
          {translation("no_notifications")}
        </div>
      ) : (
        <div className="item-center">
          {" "}
          <Loading />{" "}
        </div>
      )}
    </div>
  );
};

export default Notifications;
