import { http } from "../utils/http";

import {
  IAuthData,
  IChnagePassword,
  IForgetPassword,
  ILoginState,
  IOtp,
} from "../interfaces/AuthInterface";
import { deleteImageUrl } from "../interfaces/CommonInterface";

import ApiResponse from "../resources/entity/IApiResponse";

import endpoint from "../constants/endPoint";

/**
 * api call for login onto the website
 */
export const login = (data: ILoginState): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.LOGIN}`, data);
};

/**
 * api call for forget password
 */
export const forgetPassword = (data: IForgetPassword): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.FORGETPASSWORD}`, data);
};

/**
 * api call for reset password
 */
export const resetPassword = (data: ILoginState): Promise<ApiResponse> => {
  return http.patch(`${endpoint.auth.RESETPASSWORD}`, data);
};

/**
 *  api call for updating the image
 */
export const updateProfile = (data: IAuthData): Promise<ApiResponse> => {
  return http.patch(`${endpoint.UPDATEPROFILE}`, data);
};

/**
 * api call for changing the password
 */
export const changePassword = (data: IChnagePassword): Promise<ApiResponse> => {
  return http.patch(`${endpoint.auth.CHANGEPASSWORD}`, data);
};

/**
 * api call for getting otp
 */
export const verifyOtp = (data: IOtp): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.VERIFYOTP}`, data);
};

/**
 * api call for changing the user status either enable or disable
 */
export const changeUserActiveStatus = (
  data: string,
  status: number
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.auth.DISABLE_USER.replace(":user_id", data)}${
      status || status === 0 ? `?status=${status}` : ""
    }`
  );
};

/**
 *  delete the image from s3
 */
export const deleteImage = (data: deleteImageUrl): Promise<ApiResponse> => {
  return http.post(`${endpoint.auth.DELETEIMAGE}`, data);
};

/**
 * Match authentication token
 * @returns axios service
 */
export const matchToken = (): Promise<ApiResponse> =>
  http.get(`${endpoint.auth.MATCHTOKEN}`);
