import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { resetPassword } from "../../services/auth";

import {
  IForgetPassword,
  IVerifyPassword,
} from "../../interfaces/AuthInterface";

import { admin, user } from "../../constants/commonConstants";

import toast from "../../utils/toastMessage";
import ROUTES from "../../constants/routes";
import CommonNewPassword from "./CommonNewPassword";

import LoginRight from "../../assets/images/loginright.png";
import LoginLeft from "../../assets/images/login-left.png";
import userLoginImage from "../../assets/images/userloginright.png";

import "./userAuth.scss";

const loginType = process.env.REACT_APP_USER_TYPE;

const NewPassword: React.FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const stateInLocation = location.state as IForgetPassword;

  /**
   *  add new password
   */
  const addNewPassword = async (data: IVerifyPassword) => {
    setLoading(true);
    if (data.password === data.confirm_password) {
      const userNewCredentials = {
        email: stateInLocation.email,
        password: data.password,
        confirm_password: data.confirm_password,
      };
      const responseData = await resetPassword(userNewCredentials);
      if (responseData?.data?.success) {
        setLoading(false);
        toast.success(translation(`${responseData?.data?.message}`));
        navigate(ROUTES.LOGIN);
      } else {
        setLoading(false);
        toast.error(translation(`${responseData?.data?.message}`));
      }
    } else {
      setLoading(false);
      toast.error(translation("password_does_not_match"));
    }
  };

  return (
    <div className="row m-0">
      <div className="col-md-5 p-0">
        <div
          className="loginleft p-4"
          style={
            loginType === admin ? { backgroundImage: `url(${LoginLeft})` } : {}
          }
        >
          {loginType === user ? (
            <CommonNewPassword
              type={loginType as string}
              upDateNewPassword={addNewPassword}
              loading={loading}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-md-7 p-0">
        <div
          className="loginright"
          style={
            loginType === admin
              ? { backgroundImage: `url(${LoginRight})` }
              : { backgroundImage: `url(${userLoginImage})` }
          }
        >
          {loginType === admin ? (
            <CommonNewPassword
              type={loginType as string}
              upDateNewPassword={addNewPassword}
              loading={loading}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
