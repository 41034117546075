const TermsCondition = () => {
  return (
    <div className="container mt-4">
      <h1 className="text-center mb-4">
        <strong>Iosync, LLC Terms of Service</strong>
      </h1>
      <p>Date of Last Revision: February 12, 2023</p>
      <p>
        Welcome to our Terms of Service. These TERMS OF SERVICE (this
        &quot;Agreement&quot;) is made between Iosync, LLC (Provider) and you,
        or, if you represent an entity or other organization, that entity or
        organization (in either case &quot;You&quot;). &quot;You&quot; when used
        in this statement shall mean the customer of record, any users of the
        services and end users of any Access Device(s) using the services. For
        purposes of this Policy, any actions that provider may take with regard
        to your account and use of the Service may also be performed by a
        third-party service provider.
      </p>
      <p>
        Iosync, LLC offers a service and any reference to &quot;Services&quot;
        or &quot;Our Services&quot; below incorporate our website,
        administrative website, mobile apps and the features that they provide.
        Additionally, any reference to &quot;Community Management Company&quot;
        refers to iosync&apos;s client, the client&apos;s community that the
        resident user lives at, or a staff member that works at our
        client&apos;s community.
      </p>
      <p>
        Iosync, LLC designed the services for the use by residents and the
        community staff. In order to use our services, you must comply with the
        following terms, in addition to all applicable policies set by your
        Community Management Company, as well as applicable local, state and
        federal laws.
      </p>
      <p>
        <span>
          By using this Service, you agree to these terms and conditions
          (&ldquo;Agreement&rdquo;) and the Acceptable Use Policy (AUP). It is
          important that you carefully read these terms before using the
          services. These terms when accepted by you form a license
          (&quot;License&quot;) agreement between the provider and you
          (&quot;User&quot;) for the use of one or any or all of the
          Services.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        If you violate any of the provisions of this Policy Provider may take
        action to restrict or terminate your access to the Service, including
        access to Provider Internet websites associated with the Service.
        Provider reserve the right, at their discretion, to update or revise
        this Policy, any other policy or statement on any Provider website, and
        any product offerings or programs described on any Provider website.
        Please check back periodically to review any changes to this
        Policy.&nbsp;
      </p>
      <p>
        <br />
        &nbsp;Use of the Service for any purpose that is unlawful, objectionable
        or in any manner, which could damage, disable, overburden or impair the
        operation of the Provider Network or the Service or any other
        party&apos;s use or enjoyment of the Provider Network or the Service, is
        strictly prohibited.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Restrictions</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          You may not use the Service in a manner that violates federal, state
          or local laws and rules, or in a manner that interferes or could
          interfere with services provided to others or that could harm Provider
          facilities. Provider assumes no liability for use of its service by a
          minor. If Provider has reason to suspect unlawful use, abuse,
          fraudulent usage or interference in connection with the Service or the
          Service network, Provider may immediately, without notice or
          liability, deactivate or terminate the Service.&nbsp;
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        If you suspect fraudulent or unlawful use of your Service, please
        contact Provider immediately at (877-364-5907). Provider may temporarily
        refuse, limit, interrupt, or curtail your Service as necessary.
        Provider, or its authorized agents, shall make all repairs and
        modifications of the Provider network and systems. User agrees not to
        disturb, tamper with, reroute or in any way interfere with any component
        of the Provider network or systems. You agree to repay Provider any and
        all damages resulting from any tampering or modification of the Provider
        network or systems and the lost value of the Provider service, if any,
        plus reasonable costs and attorney&apos;s fees incurred by Provider in
        enforcing this provision.
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Specific Restrictions:</strong>&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        You may not, by use of the Service or another service, upload, post or
        otherwise distribute or facilitate distribution of any content,
        including text, communications, software, images, sounds, data, or other
        information that, in Provider&apos;s discretion:
      </p>
      <ul>
        <li>
          <p>
            Attempt to use or gain unauthorized access to data, accounts, hosts,
            servers, systems or networks associated with the Service, or to
            probe, scan or test the vulnerability of a system or network
            associated with the Service.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Attempt to use or gain unauthorized access to data, accounts, hosts,
            servers, systems, networks or any information owned by another
            person or organization without the consent and permission of the
            person or organization or to probe, scan or test the vulnerability
            of a system or network of any other person or organization.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Interfere with Service to any user, host or network including,
            without limitation, mail-bombing, flooding, or attempting to
            overload the system, use of any system that contains a virus,
            malware or any other type of software or function that may harm or
            hinder the use of the service for any user.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Use the Service to engage in activities that violate any terms or
            conditions of any other network access provider or Internet service
            provider.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Use the service in any way that causes harm or attempts to cause
            harm to minors, including but not limited to the possession or
            distribution of child pornography or other illegal materials.
          </p>
        </li>
        <li>
          <p>
            Forge any TCP-IP packet header or any part of the header information
            in an e-mail or a newsgroup posting or any other activity.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Falsify address information or otherwise modify e-mail headers to
            conceal the sender&apos;s or the recipient&apos;s identity.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Is unlawful, threatening, abusive, harassing, libelous, defamatory,
            obscene, deceptive, fraudulent, invasive of another&apos;s privacy,
            tortious, indecent, pornographic or inaccurate.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Victimizes, harasses, degrades, or intimidates an individual or
            group of individuals on the basis of religion, gender, sexual
            orientation, race, ethnicity, age, disability or any other
            reason.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Modify, adapt, translate, reverse engineer, decompile or disassemble
            any portion of the Service;
          </p>
        </li>
        <li>
          <p>
            Remove any copyright, trademark or other proprietary rights notice
            from the Service or materials originating from the Service;
          </p>
        </li>
        <li>
          <p>
            Frame or mirror any part of the Service without our express prior
            written consent;
          </p>
        </li>
        <li>
          <p>
            Create a database by systematically downloading and storing Our
            Service content; or
          </p>
        </li>
        <li>
          <p>
            Resell or attempt to resell any aspect of the Service, whether for
            profit or otherwise.
          </p>
        </li>
        <li>
          <p>
            Violates any copyright or any other intellectual property rights or
            laws.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent yourself, or your affiliation with any person or
            entity;
          </p>
        </li>
        <li>
          <p>
            Solicit, harvest or collect personal information from any other
            user;
          </p>
        </li>
        <li>
          <p>Intimidate, harass, or &quot;stalk&quot; another; or</p>
        </li>
        <li>
          <p>Use the Service for any illegal or fraudulent purpose.</p>
        </li>
      </ul>
      <p>
        If you post any content to the Service, you agree not to place
        hyperlinks to, upload, post, transmit, share, store or otherwise make
        available:
      </p>
      <ul>
        <li>
          <p>
            Any content that victimizes, harasses, degrades, or intimidates an
            individual or group of individuals on the basis of religion, gender,
            sexual orientation, race, ethnicity, age, or disability;
          </p>
        </li>
        <li>
          <p>
            Any unsolicited or unauthorized advertising, solicitations,
            promotional materials, &quot;junk mail&quot;, &quot;spam&quot;,
            &quot;chain letters&quot;, &quot;pyramid schemes&quot;, or any other
            form of solicitation;
          </p>
        </li>
        <li>
          <p>
            Any private information of any third party, including, without
            limitation, addresses, phone numbers, email addresses, Social
            Security numbers and credit card numbers;
          </p>
        </li>
        <li>
          <p>
            Any content that infringes any patent, trademark, trade secret,
            copyright or other proprietary rights of any party;
          </p>
        </li>
        <li>
          <p>
            Any content that contains nudity, violence, or offensive subject
            matter or contains a link to an adult website;
          </p>
        </li>
        <li>
          <p>
            Any material that contains software viruses or any other computer
            code, files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment;
          </p>
        </li>
        <li>
          <p>
            Any content that would constitute, encourage or provide instructions
            for a criminal offense, violate the rights of any party (including
            without limitation rights of privacy and publicity), or that would
            otherwise create liability or violate any local, state, national or
            international law;
          </p>
        </li>
        <li>
          <p>
            Any content that you do not have a right to transmit under any law
            or under contractual or fiduciary relationships (such as inside
            information, proprietary and confidential information learned or
            disclosed as part of employment relationships or under nondisclosure
            agreements); or
          </p>
        </li>
        <li>
          <p>
            Any content that, in our sole judgment, is objectionable or which
            restricts or inhibits any other person from using or enjoying the
            Service, or which may expose your Community, Community Management
            Company or Iosync, LLC or its users or members to any harm or
            liability of any type.
          </p>
        </li>
      </ul>
      <p>
        <strong>Login and Account Credentials.</strong>
      </p>
      <p>
        In order to use the services offered, you would need to have an account
        in the Iosync database by an authorized agent of your Community or
        Iosync, LLC. You agree to notify us immediately of any unauthorized use
        of your credentials and any other suspected breach of security regarding
        the App and Communication Services. Iosync, LLC and the Community
        Management Company are not liable for any loss or damage arising from
        the unauthorized use of your credentials.
      </p>
      <p>
        <strong>Service Usage</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          You are solely responsible for all usage for the Service, whether or
          not you have authorized such usage. This Service is provided only for
          your use, and you may not resell, assign, or transfer your rights
          associated with the Service without prior written consent from
          Provider. The utilization of any data or information received, sent,
          posted or otherwise provided by or to the User from the utilization of
          the Services to be provided by Provider is at the sole risk and full
          responsibility of the User.&nbsp;
        </span>
        <span>
          <strong>
            Provider specifically disclaims and denies any responsibility for
            the content, completeness, accuracy or quality of information
            transferred or obtained through the services.&nbsp;
          </strong>
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>Security</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span>
          You are solely responsible for the security of any devices and the
          content of said devices that you or anyone authorized by you use with
          the Service. You are solely responsible for implementing protection
          from viruses, malware, unwanted and unauthorized intrusions or any
          other threat or potential threat associated with use of the
          service.&nbsp;
        </span>
        <span>
          <strong>
            Provider specifically disclaims and denies any responsibility for
            the security and protection of your devices and content of said
            devices.&nbsp;
          </strong>
        </span>
      </p>
      <p>
        <strong>Our Services Content.</strong>
      </p>
      <p>
        All content on the Service, including but not limited to designs, text,
        graphics, pictures, video, information, software, music, sound and other
        files, and their selection and arrangement, are the proprietary property
        of Iosync, LLC its users or its licensors with all rights reserved. You
        are granted a limited license to access and use our Services and solely
        for your personal use. Any use of the Service or the Service&apos;s
        content other than as specifically authorized herein, without the prior
        written permission of Iosync, LLC is strictly prohibited and will
        terminate the license granted herein.
      </p>
      <p>
        <strong>User Content.</strong>
      </p>
      <p>
        As a registered user, you may submit content through the mobile app or
        web page while using our Service. You are solely responsible for any
        content that you upload, publish, display, or link to (hereinafter,
        &quot;post&quot;) on or through the Service, or transmit to or share
        with other users (&quot;User Content&quot;). Iosync, LLC and your
        Community Management Company have no ability to control User Content
        posted on the Service, but we reserve the right to modify or remove, at
        our discretion, any User Content posted by you to the Service. By
        posting User Content to any part of the Service and/or in any hyperlink
        you represent and warrant that you have obtained the necessary rights to
        post such User Content and such posting does not infringe any third
        party&apos;s rights.
      </p>
      <p>
        <strong>Third Party Content and Services.</strong>
      </p>
      <p>
        Other users or third parties may make services or goods available
        through the Service. You understand that Iosync, LLC and your Community
        Management Company do not verify the qualifications of such users or
        third parties, nor do we evaluate or control in any ongoing manner
        exchanges between you and such third parties. Iosync, LLC and your
        Community Management Company cannot and do not assume responsibility for
        the accuracy, completeness, safety, reliability, timeliness, legality or
        applicability of anything said, written, posted, displayed or otherwise
        made available by any other user.
      </p>
      <p>
        You understand that you may be exposed to content that you find
        offensive to you, and that you use the Service at your own risk.
      </p>
      <p>
        Please use caution and common sense when interacting with other users.
        By interacting with other users or third parties, you agree to accept
        the risks associated with such dealings and that Iosync, LLC and your
        Community Management Company (along with our and their respective
        officers, directors, agents, subsidiaries, joint ventures and employees)
        are not responsible for any and all acts or omissions of users on the
        Service.
      </p>
      <p>
        <strong>Suspension, Termination.</strong>
      </p>
      <p>
        If you violate any of the terms in this Agreement, Iosync, LLC may
        suspend or terminate your use of the Service. YOU AGREE THAT IOSYNC, LLC
        AND YOUR COMMUNITY MANAGEMENT COMPANY SHALL NOT BE LIABLE TO YOU OR ANY
        THIRD PARTY FOR REMOVING YOUR USER CONTENT OR SUSPENDING OR TERMINATING
        YOUR ACCESS TO THE SERVICE (OR ANY PORTION THEREOF). If your
        participation in or access to the Service is discontinued at any time,
        Iosync, LLC continue to use your User Content as set forth in these
        Terms.
      </p>
      <p>
        <strong>Privacy Policy.</strong>
      </p>
      <p>
        We care about the privacy of our users. Use of the Service is governed
        by our Privacy Policy, which is hereby incorporated into this Agreement
        by reference. By using our Service, you are consenting to have your
        personal data transferred to and processed in the United States.
      </p>
      <p>
        <strong>Proprietary Rights.</strong>
      </p>
      <p>
        The Service is protected to the maximum extent permitted by copyright
        laws and international treaties. Any reproduction, modification,
        creation of derivative works from or redistribution of the Service or
        the collective work is prohibited without the express written consent of
        Iosync, LLC.
      </p>
      <p>
        All contents on the Service (except User Content and the Community
        Management Company&apos;s content) are Copyright &copy; 2014 Iosync, LLC
        or our partners. All rights reserved. Iosync and the Iosync logo are
        trademarks of Iosync. All other trademarks are property of their
        respective companies. All trademarks and registered trademarks are
        protected by US and international trademark laws.
      </p>
      <p>
        <strong>Modifications to or Discontinuation of Service.</strong>
      </p>
      <p>
        Iosync, LLC reserves the right to modify or terminate the Service with
        or without cause at any time and effective immediately. Iosync, LLC
        shall not be liable to you or any third party for termination. Should
        you object to any terms and conditions of the Agreement or any
        subsequent modifications thereto or become dissatisfied with Our
        Services in any way, your only recourse is to immediately discontinue
        use of Our Services. Upon termination of Our Services, your right to use
        the Service immediately ceases. You shall have no right and Iosync, LLC
        will have no obligation thereafter.
      </p>
      <p>
        <strong>Disclaimers.</strong>
      </p>
      <p>
        Iosync, LLC is not responsible or liable in any manner for any User
        Content or Third Party Content linked to or posted on or through the
        Service or any hyperlink. Iosync, LLC does not monitor, control or have
        knowledge of any User Content transmitted through the Service and is not
        responsible for what users contribute to the Service or any hyperlink
        and is not responsible for any offensive, inappropriate, obscene,
        unlawful or otherwise objectionable content you may encounter as a
        result of the services offered by the Service or in connection with any
        hyperlink, User Content, or Third Party Content. Iosync, LLC and Your
        Community Management Company are not responsible for the conduct,
        whether online or offline, of any user of Our Services.
      </p>
      <p>
        Reference to any products, services, processes or other information, by
        trade name, trademark, manufacturer, supplier or otherwise does not
        constitute or imply endorsement, sponsorship or recommendation thereof,
        or any affiliation therewith, by Iosync, LLC.
      </p>
      <p>
        <strong>Third Party Systems Disclaimers.</strong>
      </p>
      <p>
        Iosync, LLC may use third party systems for certain features and
        functionality within the Iosync Services. Notably, Rent Payment and the
        control of Smart Home devices are accomplished within the Iosync
        services via the integration to third party systems. Iosync is not
        responsible or liable in any manner for these third party systems. If
        you use the features of Iosync that use these third party systems, you
        may be required to agree to their specific terms and conditions required
        by these third party providers.&nbsp;
      </p>
      <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
      <ul>
        <li>
          <p>
            THE SERVICE AND ALL CONTENT ARE PROVIDED &quot;AS IS&quot;, WITH NO
            WARRANTIES WHATSOEVER.
          </p>
        </li>
        <li>
          <p>
            USE OF THE SERVICE IS AT YOUR SOLE RISK. ANY MATERIAL AND/OR DATA
            DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS
            AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE
            FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
            FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA.
          </p>
        </li>
        <li>
          <p>
            YOUR USE OF ANY FUNCTIONALITY INCLUDED IN THE SERVICE EITHER BY
            IOSYNC OR A THIRD PARTY IS TAKEN AT YOUR OWN DISCRETION AND RISK AND
            YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES INCURRED.&nbsp;
          </p>
        </li>
      </ul>
      <p>
        <strong>Limitation of Liability.</strong>
      </p>
      <p>
        Any Provider liability to the damages resulting from your use of the
        Service or unavailability of the Service is limited as set forth in this
        Agreement. Provider makes no representations and does not assume any
        legal liability or responsibility for the content, accuracy,
        completeness, or usefulness of any information or messages sent or
        received via the Service. Provider will not be responsible for any loss
        or damages arising out of the use of the Service, including damages
        resulting from personal injuries, death, or property damage. Neither
        Provider nor its suppliers of equipment, agents or authorized
        distributors of service are liable for any loss or damage whatsoever
        resulting from mistakes, network failure, unauthorized access to data,
        files or equipment, interruptions in service or delays in transmission.
        In no event shall you have any right to recover from Provider, or its
        equipment or service providers, agents or authorized distributors any
        indirect, special, punitive, incidental, or consequential damages,
        including but not limited to loss of profit or revenue, even if Provider
        has been advised of the possibility of such damages. Any Provider
        liability for direct damages arising out of any additions, omissions,
        interruptions, delays, mistakes, errors or defects in the transmission
        occurring in the course of furnishing the Service or facilities shall in
        no event exceed an amount that is equivalent to the charge for the
        Service during the period in which the fault in transmission occurs.
        Provider is not liable for any defacement of or damage to a User&rsquo;s
        premises resulting from the furnishing of Service or the installation or
        removal of facilities and equipment, when such defacement or damage is
        not the result of the gross negligence of Provider. If such defacement
        or damage is the result of gross negligence by Provider, in no event
        will Provider be liable for more than the direct damage.
      </p>
      <p>
        UNDER NO CIRCUMSTANCES SHALL IOSYNC, LLC OR THE COMMUNITY MANAGEMENT
        COMPANY BE LIABLE TO YOU ON ACCOUNT OF (i) ANY USER CONTENT, (ii) YOUR
        USE OR MISUSE OF OR RELIANCE ON THE SERVICE, OR (iii) THE INTERRUPTION,
        SUSPENSION, OR TERMINATION OF THE SERVICE (INCLUDING SUCH DAMAGES
        INCURRED BY THIRD PARTIES). SUCH LIMITATION OF LIABILITY SHALL APPLY TO
        PREVENT RECOVERY OF DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL,
        SPECIAL, EXEMPLARY, AND PUNITIVE DAMAGES ARISING FROM ANY CLAIM RELATING
        TO THIS AGREEMENT OR THE SUBJECT MATTER HEREOF WHETHER SUCH CLAIM IS
        BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE,
        (EVEN IF IOSYNC, LLC OR ITS LICENSORS OR THE COMMUNITY MANAGEMENT
        COMPANY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). IN NO
        EVENT SHALL THE LIABILITY OF IOSYNC, LLC AND COMMUNITY MANAGEMENT
        COMPANY OR THEIR RESPECTIVE OFFICERS, DIRECTORS, AND EMPLOYEES EXCEED
        $100. SUCH LIMITATION OF LIABILITY SHALL APPLY NOTWITHSTANDING ANY
        FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST
        EXTENT PERMITTED BY LAW.
      </p>
      <p>
        <strong>Indemnity.</strong>
      </p>
      <p>
        You agree to indemnify and hold Iosync, LLC, its subsidiaries and
        affiliates and each of their directors, officers, agents, contractors,
        partners and employees, harmless from and against any loss, liability,
        claim, demand, damages, costs and expenses, including reasonable
        attorney&apos;s fees, arising out of or in connection with any Use of
        the System, any User Content, any Third Party Content you link to, post
        or share on or through the Service, your use of the Service, your
        conduct in connection with the Service or with other users of the
        Service, or any violation of this Agreement or of any law or the rights
        of any third party.
      </p>
      <p>
        <strong>Entire Agreement.</strong>
      </p>
      <p>
        These Terms of Use constitute the entire agreement between the parties
        with respect to the subject matter hereof and supersede and replace all
        prior or contemporaneous understandings or agreements, written or oral,
        regarding such subject matter.
      </p>
      <p>
        Any questions regarding these Terms and Conditions should be directed
        to:&nbsp;
        <a href="mailto:terms@communique.us">
          <u>terms@iosync.us</u>
        </a>
      </p>
      <p>
        <strong>Waiver and Severability of Terms.</strong>
      </p>
      <p>
        The failure of Iosync, LLC to exercise or enforce any right or provision
        of this Agreement shall not constitute a waiver of such right or
        provision. Any waiver of any provision of this Agreement will be
        effective only if in writing and signed by Iosync, LLC If any provision
        of this Agreement is found by a court of competent jurisdiction to be
        invalid, the parties nevertheless agree that the court should endeavor
        to give effect to the parties intentions as reflected in the provision,
        and the other provisions of this Agreement remain in full force and
        effect.
      </p>
      <p>
        <strong>Statute of Limitations.</strong>
      </p>
      <p>
        You and Iosync, LLC agree that any cause of action arising out of or
        related to this service must commence within one (1) year after the
        cause of action arose; otherwise, such cause of action is permanently
        barred. Some jurisdictions may prohibit the shortening of the time
        period in which a cause of action must be brought. In all such
        jurisdictions, the applicable time period shall be the minimum allowed
        by law.
      </p>
      <p>
        <strong>Choice of Law and Forum.</strong>
      </p>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of North Carolina, excluding its conflict of law
        provisions. You and Iosync, LLC agree to submit to the exclusive
        jurisdiction of the courts of the State of North Carolina.
      </p>
      <p>
        <strong>Modification.</strong>
      </p>
      <p>
        We reserve the right to modify these terms from time to time, for any
        reason, without notice and such modifications will be made available on
        our Service. Use of our Service constitutes (1) acknowledgement by you
        of our modifications, and (2) an agreement by you to abide and be bound
        by these terms and their modifications.
      </p>
      <p>
        <strong>Copyright Claims.</strong>
      </p>
      <p>
        If you are a copyright owner or an agent thereof and believe that any
        User Content or other content infringes upon your copyrights, you may
        submit a notification pursuant to the Digital Millennium Copyright Act
        (&quot;DMCA&quot;) by providing our copyright agent with the following
        information in writing (see 17 U.S.C. 512(c)(3) for further detail):
      </p>
      <ul>
        <li>
          <p>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed;
          </p>
        </li>
        <li>
          <p>
            Identification of the copyrighted work claimed to have been
            infringed;
          </p>
        </li>
        <li>
          <p>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled and information reasonably
            sufficient to permit us to locate the material;
          </p>
        </li>
        <li>
          <p>
            Information reasonably sufficient to permit us to contact you, such
            as an address, telephone number, and, if available, an electronic
            mail;
          </p>
        </li>
        <li>
          <p>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </p>
        </li>
        <li>
          <p>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </p>
        </li>
      </ul>
      <p>
        Our designated Copyright Agent to receive notifications of claimed
        infringement is:
      </p>
      <p>
        By mail:
        <br />
        &nbsp;Copyright Agent
        <br />
        &nbsp;3208 Pennington Drive, Wilmington, NC 28405
        <br />
        &nbsp;
        <br />
        &nbsp;By email at&nbsp;
        <a href="mailto:copyright@communique.us">
          <u>copyright@iosync.us</u>
        </a>
      </p>
      <p>
        For clarity, only DMCA notices should go to the Copyright Agent; any
        other feedback, comments, requests for technical support, and other
        communications should be directed to Iosync, LLC at&nbsp;
        <a href="mailto:support@communique.us">
          <u>support@iosync.us</u>
        </a>
        . You acknowledge that if you fail to comply with all of the
        requirements of this section, your DMCA notice may not be valid.
      </p>
      <p>
        If you believe that content or a submission of yours that was removed
        (or to which access was disabled) is not infringing, or that you have
        the authorization from the copyright owner, the copyright owner&apos;s
        agent, or pursuant to the law, to post and use such content or
        submission, you may send a counter-notice containing the following
        information to the Copyright Agent:
      </p>
      <ul>
        <li>
          <p>Your physical or electronic signature;</p>
        </li>
        <li>
          <p>
            Identification of the content that has been removed or to which
            access has been disabled and the location at which the content
            appeared before it was removed or disabled;
          </p>
        </li>
        <li>
          <p>
            A statement that you have a good faith belief that the content was
            removed or disabled as a result of a mistake or a misidentification
            of the content; and
          </p>
        </li>
        <li>
          <p>
            Your name, address, telephone number, and email address, a statement
            that you consent to the jurisdiction of the federal court in New
            Hanover County, North Carolina, and a statement that you will accept
            service of process from the person who provided notification of the
            alleged infringement.
          </p>
        </li>
      </ul>
      <p>
        If a counter-notice is received by the Copyright Agent, we may send a
        copy of the counter-notice to the original complaining party informing
        that person that the removed content may be replaced or no longer
        disabled in 10 business days.
      </p>
      <p>
        Unless the copyright owner files an action seeking a court order against
        the content provider, member or user, the removed content may be
        replaced, or access to it restored, in 10 to 14 business days or more
        after receipt of the counter-notice, at our sole discretion.
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <strong>SmartThings Usage and Charges</strong>
      </p>
      <p>
        <br />
      </p>
      <p>
        In any case where SmartThings app is deployed to resident to control the
        experience, this section is to explicitly inform the resident/customer
        that SmartThings is not charging the resident for use of the free
        SmartThings app. Fees are associated with hardware and management of the
        Smart Apartment features made possible by Iosync or it&rsquo;s
        partners.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default TermsCondition;
