import { http } from "../utils/http";

import {
  IAddResident,
  IImport,
  IMultipleResidentDelete,
  IResidentDelete,
} from "../interfaces/ResidentInterface";
// import { INotifications } from "../interfaces/StaffInterface";
import { ISupportNotification } from "../interfaces/Notifications";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";

/**
 * Add resident
 */
export const addResident = (data: IAddResident) => {
  return http.post(`${endpoint.Resident.ADDRESIDENT}`, data);
};

/**
 *  Get Residents list for a property
 */
export const getResidentList = (
  property_id: string,
  offset?: number,
  data?: any
): Promise<ApiResponse> => {
  const queryParams: string[] = [];
  if (offset || offset === 0) queryParams.push(`offset=${offset}`);
  if (data) {
    Object.keys(data).forEach((key) => {
      if (data[key]) queryParams.push(`${key}=${data[key]}`);
    });
  }
  return http.get(
    `${endpoint.Resident.GETRESIDENT.replace(":property_id", property_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 *  Update the resident
 */
export const updateResident = (
  user_id: string[],
  status: string
): Promise<ApiResponse> => {
  const data = {
    userStatus: status,
    ids: user_id,
  };
  return http.patch(`${endpoint.Resident.UPDATERESIDENT}`, data);
};
/**
 *  fetech the resident credential sent status
 */
export const getResidentcredentialStatus = (
  user_ids: string[]
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Resident.GETRESIDENTCREDENTIALSTATUS.replace(
      ":user_ids",
      JSON.stringify(user_ids)
    )}`
  );
};

/**
 *
 * get property id of the resident
 */
export const getResidentProperty = (residentId: string) => {
  return http.get(
    `${endpoint.Resident.GETRESIDENTPROPERTY.replace(":id", residentId)}`
  );
};

/**
 *
 * get resident pending count
 */
export const pendingResidentCount = (
  property_id: string,
  propertyManagerId?: string
) => {
  const queryParams: string[] = [];
  if (propertyManagerId) {
    // Object.keys(data).forEach((key) => {
    queryParams.push(`propertyManagerId=${propertyManagerId}`);
    // });
  }
  return http.get(
    `${endpoint.Resident.GETPENDINGCOUNT.replace(":property_id", property_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 * Support for resident
 */
export const support = (data: ISupportNotification) => {
  return http.post(`${endpoint.Resident.SUPPORT}`, data);
};

/**
 *  get room id for the resident
 */
export const roomId = (data: string) => {
  return http.get(
    `${endpoint.Resident.GETROOMID.replace(":resident_id", data)}`
  );
};

/**
 *  delete resident
 */

export const deleteResident = (data: IResidentDelete) => {
  return http.delete(
    `${endpoint.Resident.DELETERESIDENT.replace(":id", data.resident_id)
      .replace(":deleted_by", data.deletedBy)
      .replace(":property_id", data.property_id)}`
  );
};
/**
 *  delete resident
 */

export const deleteMultipleResident = (data: IMultipleResidentDelete) => {
  return http.post(`${endpoint.Resident.DELETEMULTIPLERESIDENT}`, data);
};

/**
 *  resident notification
 */

// export const residentNotification = (
//   data: INotifications
// ): Promise<ApiResponse> => {
//   return http.post(`${endpoint.Resident.RESIDENTNOTIFICATION}`, data);
// };
/**
 * Add resident
 */
export const importRecord = (data: IImport) => {
  return http.post(`${endpoint.Resident.IMPORT_RECORD}`, data);
};

export const readCsv = (data: IImport) => {
  return http.post(`${endpoint.Resident.READ_CSV}`, data);
};

export const readCsvError = (data: IImport) => {
  const queryParams: string[] = [];
  if (data.property_id === "all") {
    // Object.keys(data).forEach((key) => {
    queryParams.push(`propertyManagerId=${data.company_user_id}`);
    // });
  }
  return http.get(
    `${endpoint.Resident.READ_CSV_ERROR.replace(
      ":property_id",
      data.property_id ? data.property_id : "all"
    ).replace(":user_id", data.user_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 *  delete resident
 */

export const deleteCsvData = (data: IImport) => {
  const queryParams: string[] = [];
  if (data.property_id === "all") {
    // Object.keys(data).forEach((key) => {
    queryParams.push(`propertyManagerId=${data.company_user_id}`);
    // });
  }
  return http.delete(
    `${endpoint.Resident.DELETE_CSV_DATA.replace(
      ":property_id",
      data.property_id
    ).replace(":user_id", data.user_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

export const fetchTotalCount = (data: IImport) => {
  const queryParams: string[] = [];
  if (data.property_id === "all") {
    // Object.keys(data).forEach((key) => {
    queryParams.push(`propertyManagerId=${data.company_user_id}`);
    // });
  }
  return http.get(
    `${endpoint.Resident.READ_TOTAL_COUNT.replace(
      ":property_id",
      data.property_id
    ).replace(":user_id", data.user_id)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};
