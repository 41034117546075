import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
// import { Line } from "react-chartjs-2";
import moment from "moment";
import {
  getResidentPropertiesList,
  getunitDetailData,
  getunitListResident,
} from "../../services/unit";
import { deviceReportHub } from "../../services/device";

import { IAuthReducerData } from "../../interfaces/AuthInterface";
import { chartWeekDaysFormat, IDevice } from "../../interfaces/CommonInterface";
import {
  // IOnlineChartData,
  ISwitchDetail,
  IUnitDetailData,
  IUnitId,
  IUnitResidentList,
} from "../../interfaces/UnitInterface";
import { IDeviceStatusCounting } from "../../interfaces/PropertyInterface";
import {
  IResidentPropertyInformation,
  ISwitchStatusCount,
} from "../../interfaces/ResidentInterface";

import { RootState } from "../../redux/reducers/Index";
import {
  Auto,
  Cool,
  deviceStatus,
  Heat,
  Locked,
  locked,
  Off,
  Unlocked,
} from "../../constants/commonConstants";

import Header from "../../components/header/Header";
import storage from "../../utils/storage";
import ActionType from "../../resources/enums";
import Loading from "../../components/loader/Loader";

import units from "../../assets/images/units.svg";
import light from "../../assets/images/light.svg";
import dolar from "../../assets/images/dolar.svg";
import lockc from "../../assets/images/lockc.svg";
import waterLeakSensor from "../../assets/images/water_leak_sensor.svg";
import contactSensor from "../../assets/images/contact_sensor.svg";
import online_support from "../../assets/images/online_support.svg";
import { getPropertyManagers } from "../../services/company";
import CommonSelect from "../../components/formElements/CommonSelect";
import { IResidentUnitList } from "../../interfaces/StaffInterface";

type State = {
  unitData: IDevice;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UserDashboard: React.FC = () => {
  const [unitDetailData, setUnitList] = useState<State>({
    unitData: {
      switch: [],
      door: [],
      thermoStat: [],
      waterSensor: [],
      contactSensor: [],
    },
  });
  const {
    control,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({});
  const [unitResidentList, setResidentUnitList] = useState<IResidentUnitList[]>(
    []
  );
  const [propertyResidentList, setPropertyResidentList] = useState<
    IResidentUnitList[]
  >([]);
  const [propertyListForResident, setPropertyListForResident] = useState<
    IResidentPropertyInformation[]
  >([]);
  const dispatch = useDispatch();

  const { t: translation } = useTranslation();

  const [unitId, setUnitId] = useState<IUnitId>();
  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [unitUnassigned, setUnitUnassigned] = useState<boolean>(false);
  useEffect(() => {
    getPropertyAndUnitDetail();
    getDeviceReports();
  }, []);

  useEffect(() => {
    getResidentData();
  }, []);

  useEffect(() => {
    if (authReducer?.authData._id) {
      getResidentPropertyList(authReducer?.authData._id);
      setResidentUnitList([]);
      reset({
        property_resident_id: "",
        unit: "",
      });
    }
  }, [authReducer?.currentCompanyId]);
  const getResidentData = async () => {
    if (authReducer?.authData._id) {
      const propertyList = await getResidentPropertyList(
        authReducer.authData._id
      );
      const unitList = await getResidentUnitList({
        propertyId: storage.get("propertyId"),
        id: authReducer.authData._id,
      });
      const propertyData = propertyList?.find(
        (item: IResidentUnitList) => item.key === storage.get("propertyId")
      );
      const unitValue = unitList?.find(
        (item: IResidentUnitList) => item.key === storage.get("unitId")
      );
      reset({
        property_resident_id: propertyData?.key,
        unit: unitValue?.key,
      });
    }
  };

  useEffect(() => {
    if (getValues("unit")) {
      const unitValue = unitResidentList?.find(
        (item: IResidentUnitList) => item.key === getValues("unit")
      );

      setUnitUnassigned(false);
      if (unitValue) {
        getunitDetail({
          propertyId: storage.get("propertyId"),
          unitId: unitValue?.key,
        });

        setUnitId({
          unitId: unitValue?.key,
        });
        storage.set("unitId", unitValue?.key);
      }
    }
  }, [watch("unit")]);

  useEffect(() => {
    if (getValues("property_resident_id")) {
      if (firstLoad) {
        setFirstLoad(false);
      } else {
        setValue("unit", undefined);
      }
      storage.set("propertyId", getValues("property_resident_id"));
      const companyUserId = propertyListForResident?.filter(
        (item: IResidentPropertyInformation) =>
          item &&
          item?.propertyInfo &&
          item.propertyInfo[0]._id === getValues("property_resident_id")
      )[0].propertyInfo[0].company_user_id;
      getPropertyAndUnitDetail(companyUserId);
      if (getValues("property_resident_id") && authReducer?.authData._id) {
        getResidentUnitList({
          propertyId: getValues("property_resident_id"),
          id: authReducer.authData._id,
        });
      }
    }
  }, [watch("property_resident_id"), authReducer?.currentCompanyId]);

  useEffect(() => {
    if (storage.get("unitId")) {
      setUnitUnassigned(false);
      // getunitDetail({
      //   propertyId: storage.get("propertyId"),
      //   unitId: storage.get("unitId"),
      // });
    } else {
      setUnitUnassigned(true);
    }
  }, [storage.get("unitId")]);
  const [switchStatusCount, setSwitchStatusCount] =
    useState<ISwitchStatusCount>();
  /**
   * Get Unit detail
   */
  const getunitDetail = async (data: IUnitDetailData) => {
    setLoading(true);
    const unitDetails = await getunitDetailData(data);
    if (unitDetails?.data?.success) {
      setLoading(false);
      setUnitList({
        unitData: unitDetails.data.data,
      });
      let count = 0;

      unitDetails?.data?.data?.switch?.forEach((ele: ISwitchDetail) => {
        if (ele?.latestReading?.switch === deviceStatus.off) count += 1;
      });
      setSwitchStatusCount({
        switchOnCount: unitDetails.data.data.switch.length - count,
        switchOffCount: count,
      });
    } else {
      setLoading(false);
    }
  };

  /**
   *  get property id
   */
  const getPropertyAndUnitDetail = async (companyUserId?: string) => {
    const propertyManager = await getPropertyManagers({
      id: companyUserId ? companyUserId : storage.get("propertyManagerId"),
    });
    if (propertyManager?.data?.success) {
      dispatch({
        type: ActionType.PAYMENT_LINK,
        payload: propertyManager.data.data[0]?.payment_link,
      });

      if (!companyUserId) {
        await setUnitId({
          unitId: storage.get("unitId"),
        });
      }
    }
  };

  /**
   * Get resident property list
   * */

  const getResidentPropertyList = async (user_id: string) => {
    const filterData: { key: string; value: string }[] = [];
    const propertyManagerId = authReducer.currentCompanyId
      ? authReducer.currentCompanyId
      : storage.get("propertyManagerId");
    const residentPropertyList = await getResidentPropertiesList(
      user_id,
      propertyManagerId
    );
    if (residentPropertyList?.data?.success) {
      setPropertyListForResident(
        residentPropertyList.data.data[0].propertyResident
      );
      residentPropertyList.data.data[0].propertyResident.forEach(
        (item: IResidentPropertyInformation) => {
          if (item.propertyInfo && item.propertyInfo.length) {
            filterData.push({
              key: item.propertyInfo[0]._id,
              value: item.propertyInfo[0].building_name,
            });
          }
        }
      );
      setPropertyResidentList(filterData);
    }
    return filterData;
  };

  /**
   * Get resident unit list
   * */
  const getResidentUnitList = async (data: {
    propertyId: string;
    id: string;
  }) => {
    setLoading(true);
    const filterData: { key: string; value: string }[] = [];
    const residentUnitList = await getunitListResident({
      id: data.id,
      propertyId: data.propertyId,
    });
    if (residentUnitList?.data?.success) {
      residentUnitList.data.data.forEach((item: IUnitResidentList) => {
        if (item.unit && item.unit.length) {
          filterData.push({
            key: item.unit[0]._id,
            value: item.unit[0].unit,
          } as IResidentUnitList);
        }
      });
      setResidentUnitList(filterData);
    }
    setLoading(false);
    return filterData;
  };

  // const [onlineChartData, seOnlineChartData] = useState<IOnlineChartData>({
  //   labels: [],
  //   datasets: [
  //     {
  //       data: [],
  //       tension: 0.4,
  //       fill: false,
  //     },
  //   ],
  // });
  /**
   *  options for graph
   */
  // const options = {
  //   responsive: true,
  //   interaction: {
  //     mode: "index" as const,
  //     intersect: false,
  //   },
  //   stacked: false,
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     title: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     y: {
  //       display: true,
  //       ticks: {
  //         color: " #ffffff",
  //       },
  //       grid: {
  //         display: false,
  //       },
  //     },
  //     x: {
  //       grid: {
  //         display: false,
  //       },
  //     },
  //   },
  // };
  useEffect(() => {
    getDeviceReports();
  }, [unitId?.unitId]);

  const daysOfWeekData: chartWeekDaysFormat = [
    { Mon: 1 },
    { Tue: 2 },
    { Wed: 3 },
    { Thr: 4 },
    { Fri: 5 },
    { Sat: 6 },
    { Sun: 7 },
  ];
  const labels: string[] = [];
  /**
   *  get device offline and online report
   */
  const getDeviceReports = async () => {
    if (unitId?.unitId) {
      const reponseData = await deviceReportHub(unitId?.unitId);
      if (reponseData?.data?.success) {
        /**
         *  dynamic label
         */

        for (let i = new Date().getDay(); i < daysOfWeekData.length; ) {
          labels.push(Object.keys(daysOfWeekData[i])[0]);
          i += 1;
        }
        for (let i = 0; i < new Date().getDay(); ) {
          labels.push(Object.keys(daysOfWeekData[i])[0]);
          i += 1;
        }
        const dataForOnlineStatusChart: number[] = [];

        if (reponseData?.data?.success) {
          const lastSevenDaysDateForOnlineStatus: string[] = [];
          const lastSevenDaysDateForOfflineStatus: string[] = [];
          reponseData?.data?.data?.hubsOnline.forEach(
            (e: IDeviceStatusCounting) => {
              lastSevenDaysDateForOnlineStatus.push(
                new Date(e._id).toISOString().split("T")[0]
              );
            }
          );
          reponseData?.data?.data?.hubsOffline.forEach(
            (e: IDeviceStatusCounting) => {
              lastSevenDaysDateForOfflineStatus.push(
                new Date(e._id).toISOString().split("T")[0]
              );
            }
          );
          if (lastSevenDaysDateForOnlineStatus.length < 7) {
            for (let i = 0; i < 7; ) {
              if (
                lastSevenDaysDateForOnlineStatus.includes(
                  new Date(new Date().setDate(new Date().getDate() - 6 + i))
                    .toISOString()
                    .split("T")[0]
                )
              ) {
                reponseData?.data?.data?.hubsOnline.forEach(
                  (e: IDeviceStatusCounting) => {
                    if (
                      new Date(e._id).toISOString().split("T")[0] ===
                      new Date(new Date().setDate(new Date().getDate() - 6 + i))
                        .toISOString()
                        .split("T")[0]
                    ) {
                      dataForOnlineStatusChart.push(e.count);
                    }
                  }
                );
              } else {
                dataForOnlineStatusChart.push(0);
              }
              i += 1;
            }
          } else {
            reponseData?.data?.data?.hubsOnline.forEach(
              (e: IDeviceStatusCounting) => {
                dataForOnlineStatusChart.push(e.count);
              }
            );
          }

          // const datasetsForOnline = [
          //   {
          //     fill: false,
          //     data: dataForOnlineStatusChart,
          //     borderColor: "rgb(17, 237, 35)",
          //     backgroundColor: "rgba(27, 213, 58, 0.9)",
          //     tension: 0.4,
          //   },
          // ];
          // seOnlineChartData({
          //   ...onlineChartData,
          //   labels,
          //   datasets: datasetsForOnline,
          // });
        }
      }
    }
  };

  return (
    <div className="p-4">
      <Header />
      <div>
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="mb-3">
              <h2 className="theme-color fw-bold">{`${
                authReducer.authData.first_name
              } ${
                authReducer.authData.last_name
                  ? authReducer.authData.last_name
                  : ""
              }`}</h2>
              <p className="op-6">
                {authReducer?.authData?.address?.address_line1
                  ? `${authReducer?.authData?.address?.address_line1} , ${
                      authReducer?.authData?.address?.address_line2
                        ? `${authReducer?.authData?.address?.address_line2} , `
                        : ""
                    } ${authReducer?.authData?.address?.city} , ${
                      authReducer?.authData?.address?.country
                    } , ${authReducer?.authData?.address?.state} , ${
                      authReducer?.authData?.address?.zip
                    }`
                  : ""}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            {!unitUnassigned && (
              <div className="row">
                <div className="col-md-2">
                  {loading && (
                    <div className="mt-3">
                      <Loading />
                    </div>
                  )}
                </div>
                <div className="col-md-5">
                  <CommonSelect
                    className="form-select"
                    aria-label="Default select example"
                    dropDownData={propertyResidentList}
                    error={errors?.property_resident_id}
                    control={control}
                    name="property_resident_id"
                    multipleSelect={false}
                    firstOption={translation("select_building_dropdown")}
                    disabled={false}
                  />
                </div>

                <div className="col-md-5">
                  <CommonSelect
                    className="form-select"
                    aria-label="Default select example"
                    dropDownData={unitResidentList}
                    error={errors?.unit}
                    control={control}
                    name="unit"
                    multipleSelect={false}
                    firstOption={translation("select_unit_placeholder")}
                    disabled={false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {unitUnassigned && !loading ? (
        <div className="please-wait">
          <div className="please-wait-inner">
            <img src={online_support} alt="" />
            <p>
              {translation("hello")} {authReducer?.authData?.first_name}{" "}
              {authReducer?.authData?.last_name}{" "}
              {translation("unit_unassigned_resident_message")}
            </p>
          </div>
        </div>
      ) : (
        <div className="row mt-3">
          {unitDetailData &&
          unitDetailData.unitData &&
          unitDetailData.unitData.thermoStat &&
          unitDetailData.unitData.thermoStat.length > 0 ? (
            <div className="col-md-4 pb-3">
              <div className="white-card text-start white-height-card">
                <div className="d-flex align-items-center justify-content-left">
                  <img className="me-2" src={units} alt="" />
                  <p className="op-3 ">
                    {unitDetailData &&
                      unitDetailData.unitData &&
                      unitDetailData.unitData.thermoStat &&
                      unitDetailData.unitData.thermoStat[0]?.name}
                  </p>
                </div>

                <p className="font25">
                  {" "}
                  {unitDetailData &&
                  unitDetailData.unitData &&
                  unitDetailData.unitData.thermoStat &&
                  unitDetailData.unitData.thermoStat[0]?.latestReading
                    ?.thermostatMode
                    ? unitDetailData.unitData.thermoStat[0]?.latestReading
                        ?.thermostatMode === deviceStatus.cool
                      ? Cool
                      : unitDetailData.unitData.thermoStat[0]?.latestReading
                          ?.thermostatMode === deviceStatus.heat
                      ? Heat
                      : unitDetailData.unitData.thermoStat[0]?.latestReading
                          ?.thermostatMode === deviceStatus.off
                      ? Off
                      : unitDetailData.unitData.thermoStat[0]?.latestReading
                          ?.thermostatMode === deviceStatus.auto
                      ? Auto
                      : ""
                    : ""}{" "}
                  {translation("mode")}
                </p>
              </div>
            </div>
          ) : null}
          {unitDetailData &&
          unitDetailData?.unitData &&
          unitDetailData?.unitData.door &&
          unitDetailData?.unitData?.door.length > 0 ? (
            <div className="col-md-4 pb-3">
              <div className="white-card text-start white-height-card">
                <div className="d-flex align-items-center justify-content-between">
                  {" "}
                  <div className="d-flex align-items-center justify-content-left">
                    <img className="me-2" src={lockc} alt="" />
                    <p className="op-3 ">
                      {unitDetailData?.unitData?.door
                        ? unitDetailData?.unitData?.door[0]?.name
                        : translation("no_door_available")}{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className=" font25">
                    {unitDetailData?.unitData.door &&
                    unitDetailData?.unitData?.door[0]?.latestReading &&
                    unitDetailData?.unitData?.door[0]?.latestReading?.lock
                      ? unitDetailData?.unitData?.door[0]?.latestReading
                          ?.lock === locked
                        ? Locked
                        : Unlocked
                      : ""}
                  </p>
                  {unitDetailData?.unitData.door &&
                  unitDetailData?.unitData?.door[0]?.latestReading &&
                  unitDetailData?.unitData?.door[0]?.latestReading?.lock ? (
                    unitDetailData?.unitData?.door[0]?.latestReading?.lock ===
                    locked ? (
                      <div className="offline-lg" />
                    ) : (
                      <div className="online-lg" />
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : null}

          {unitDetailData &&
          unitDetailData?.unitData &&
          unitDetailData?.unitData.contactSensor &&
          unitDetailData?.unitData?.contactSensor.length > 0 ? (
            <div className="col-md-4 pb-3">
              <div className="white-card text-start white-height-card">
                <div className="d-flex align-items-center justify-content-between">
                  {" "}
                  <div className="d-flex align-items-center justify-content-left">
                    <img className="me-2" src={contactSensor} alt="" />
                    <p className="op-3 ">
                      {unitDetailData?.unitData?.contactSensor
                        ? unitDetailData?.unitData?.contactSensor[0]?.label
                        : translation("no_conatctSensor_device_found")}{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className="font25 text-capitalize">
                    {unitDetailData?.unitData.contactSensor &&
                      unitDetailData?.unitData?.contactSensor[0]
                        ?.latestReading &&
                      unitDetailData?.unitData?.contactSensor[0]?.latestReading
                        ?.contactSensor}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {unitDetailData &&
          unitDetailData?.unitData &&
          unitDetailData?.unitData.waterSensor &&
          unitDetailData?.unitData?.waterSensor.length > 0 ? (
            <div className="col-md-4 pb-3">
              <div className="white-card text-start white-height-card">
                <div className="d-flex align-items-center justify-content-between">
                  {" "}
                  <div className="d-flex align-items-center justify-content-left">
                    <img className="me-2" src={waterLeakSensor} alt="" />
                    <p className="op-3 ">
                      {unitDetailData?.unitData?.contactSensor
                        ? unitDetailData?.unitData?.waterSensor[0]?.label
                        : translation("no_waterLeakSensor_device_found")}{" "}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p className=" font25 text-capitalize">
                    {unitDetailData?.unitData.waterSensor &&
                      unitDetailData?.unitData?.waterSensor[0]?.latestReading &&
                      unitDetailData?.unitData?.waterSensor[0]?.latestReading
                        ?.water}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-md-4 pb-3">
            <div className="white-card text-start white-height-card">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-left">
                  <img className="me-2" src={dolar} alt="" />
                  <p className="op-3 ">{translation("next_payment")}</p>
                </div>
              </div>
              <p className=" font25">
                {moment(new Date()).daysInMonth() - new Date().getUTCDate()}{" "}
                {translation("days_remaining")}
              </p>
            </div>
          </div>
          {unitDetailData &&
          unitDetailData?.unitData &&
          unitDetailData?.unitData.switch &&
          unitDetailData?.unitData?.switch.length > 0 ? (
            <div className="col-md-4 pb-3">
              <div className="white-card text-start white-height-card">
                <div className="d-flex align-items-center justify-content-left">
                  <img className="me-2" src={light} alt="" />
                  <p className="op-3 ">{translation("smart_light_status")}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 switch-statement-show">
                  <p>
                    {translation("switch_on_count")}
                    <span className="colorStrong ms-1">
                      {switchStatusCount && switchStatusCount.switchOnCount
                        ? switchStatusCount.switchOnCount
                        : 0}
                    </span>
                  </p>
                  <p>
                    {translation("switch_off_count")}
                    <span className="colorDanger ms-1">
                      {switchStatusCount && switchStatusCount.switchOffCount
                        ? switchStatusCount.switchOffCount
                        : 0}
                    </span>
                  </p>
                  <p className=" font25"> </p>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="row">
            <div className="col-md-12">
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="white-card">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center justify-content-left">
                        <p className="fw-bold me-3">
                          {translation("resident_hub")}
                        </p>
                      </div>
                    </div>

                    <div>
                      <Line data={onlineChartData} options={options} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
