import { http } from "../utils/http";

import { IChangeDeviceStatus } from "../interfaces/UnitInterface";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";

/**
 * get device reports for all hubs
 */
export const deviceReport = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Device.GETDEVICEREPORT.replace(":property_id", data)}`
  );
};

/**
 *  get device report for one hub
 */
export const deviceReportHub = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Device.GETDEVICEREPORTHUB.replace(":unit_id", data)}`
  );
};

/**
 * control device
 */
export const controlDevice = (
  data: IChangeDeviceStatus
): Promise<ApiResponse> => {
  return http.post(`${endpoint.Device.CONTROLDEVICE}`, data);
};
