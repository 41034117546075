const ROUTES = {
  LOGIN: "/login",
  FORGET_PASSWORD: "/forget-password",
  NEW_PASSWORD: "/new-password",
  VERIFY_OTP: "/verify",
  UNITS: "/units",
  COMPANY: "/properties",
  ALL_PROPETIES: "/buildings",
  ADD_PROPERTIES: "/add-building",
  EDIT_PROPERTIES: "/edit-building",
  ADD_COMPANY: "/add-property",
  EDIT_COMPANY: "/edit-property",
  DASHBOARD: "/dashboard",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITION: "/terms-condition",
  RESIDENTS: "/residents",
  UNITDETAIL: "/unit-no",
  ADD_RESIDENTS: "/add-resident",
  STAFF: "/staff",
  MANAGE_ROLES: "/manage-roles",
  MY_PROFILE: "/my-profile",
  SUPPORT_TICKET: "/support-ticket",
  Resident: {
    RESIDENT_DASHBOARD: "/resident-dashboard",
  },
  NOTIFICATIONS: "/notifications",
  EMAIL: "/email",
};
export default ROUTES;
