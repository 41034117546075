import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import storage from "./utils/storage";

import PublicRoutes, { preLogginPages } from "./routes/PublicRoutes";

import "./components/i18n/I18n";

import "bootstrap/dist/css/bootstrap.css";
import "./styles/Variable.scss";
import "./styles/Style.scss";
import ActionType from "./resources/enums";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  Sentry.init({
    dsn: process.env.REACT_APP_DSN_KEY,
    tracesSampleRate: 1.0,
  });

  const checkTimeExist = () => {
    let stopLogout = 0;
    if (storage.get("OLDER_TIME") !== "") {
      if (
        !storage.get("LAST_OLDER_TIME") ||
        (storage.get("LAST_OLDER_TIME") &&
          new Date(`${JSON.parse(storage.get("OLDER_TIME"))}`).getTime() -
            new Date(
              `${JSON.parse(storage.get("LAST_OLDER_TIME"))}`
            ).getTime() >
            10)
      ) {
        if (storage.get("LAST_OLDER_TIME")) {
          const diff =
            new Date().getTime() -
            new Date(`${JSON.parse(storage.get("LAST_OLDER_TIME"))}`).getTime();

          const second = Math.floor(diff / 1000);
          if (second > 20) {
            stopLogout = 1;
          }
        }
      }
      const date = new Date();
      const diff =
        date.getTime() -
        new Date(`${JSON.parse(storage.get("OLDER_TIME"))}`).getTime();

      const second = Math.floor(diff / 1000);

      if (second > 10 && second < 20) {
        storage.set("LAST_OLDER_TIME", storage.get("OLDER_TIME"));
        storage.set("OLDER_TIME", JSON.stringify(date));
      } else if (second > 20 && !stopLogout) {
        storage.removeAll();
        dispatch({
          type: ActionType.LOGOUT,
        });
        navigate("/");
      } else {
        storage.set("LAST_OLDER_TIME", storage.get("OLDER_TIME"));
        storage.set("OLDER_TIME", JSON.stringify(date));
      }
    } else if (!preLogginPages.includes(window.location.pathname)) {
      dispatch({
        type: ActionType.LOGOUT,
      });
      return <Navigate to="/login" />;
    }
  };

  useEffect(() => {
    storage.set("LAST_OLDER_TIME", null);
    checkTimeExist();

    setInterval(() => {
      checkTimeExist();
    }, 5000);
  }, []);

  return (
    <div className="App">
      <Sentry.ErrorBoundary>
        <PublicRoutes />
      </Sentry.ErrorBoundary>
    </div>
  );
};
export default App;
