import * as yup from "yup";

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const passwordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

const loginValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_is_req"))
      .email(translation("email_valid")),
    password: yup
      .string()
      .required(translation("password"))
      .min(8, translation("min_len_8"))
      .max(25, translation("max_len_25")),
  });

const forgetPasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("is_req"))
      .email(translation("email_valid"))
      .min(8, translation("min_len_8")),
  });

const resetPasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    password: yup
      .string()
      .required(translation("is_req"))
      .matches(passwordRegExp, translation("pass_reg_ms")),
    confirm_password: yup
      .string()
      .required(translation("is_req"))
      .matches(passwordRegExp, translation("pass_reg_ms"))
      .max(25, translation("max_len_25")),
  });

const addCompanyValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .required(translation("email_is_req"))
      .matches(emailRegex, translation("email_valid"))
      .email(translation("email_valid")),
    phone: yup
      .string()
      .trim()
      .required(translation("phone_is_req"))
      .matches(phoneRegExp, translation("phone_valid")),
    companyName: yup
      .string()
      .trim()
      .required(translation("company_name_req"))
      .max(30, translation("company_name_max_len_30")),
    address_line1: yup.string().trim().required(translation("addressLine1")),
    city: yup.string().trim().required(translation("city")),
    zip: yup.string().trim().required(translation("zip")),
    state: yup.string().trim().required(translation("state")),
    country: yup.string().trim().required(translation("country")),
    apiKey: yup.array().of(
      yup.object().shape({
        key: yup.string().trim().required(translation("key_req")),
        value: yup.string().trim().required(translation("value_req")),
      })
    ),
  });

const addPropertyValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    building_name: yup
      .string()
      .trim()
      .required(translation("building_name"))
      .max(30, translation("builiding_name_max_len_30")),
    units: yup
      .number()
      .test("units must be number only ", (number) => Number.isInteger(number))
      .integer(`Please enter a valid phone number without decimal values`)
      .typeError(translation("units"))
      .required(translation("units"))
      .min(0, translation("min_units"))
      .max(500, translation("max_units")),

    image: yup.string().trim().required(translation("image_is_req")),

    floorData: yup.array().of(
      yup.object().shape({
        floor: yup
          .string()
          .trim()
          .optional()
          .max(20, translation("floor_len_20")),
        prefix: yup.string().trim().max(20, translation("prefix_len_20")),
        sufix: yup.string().trim().max(20, translation("suffix_len_20")),
        apiKey: yup.string().trim().required(translation("apiKey_is_req")),
        startRange: yup
          .number()
          .typeError(translation("start_range_req"))
          .required(translation("start_range_req"))
          .min(0, translation("min_start_Range")),
        endRange: yup
          .number()
          .typeError(translation("end_range_req"))
          .required(translation("end_range_req"))
          .min(0, translation("min_end_Range"))
          .when("startRange", (startRange: number, schema: any): any => {
            startRange &&
              schema.max(
                startRange,
                translation("end_range_greater_start_range")
              );
          }),
      })
    ),
  });

const addUnitValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    units: yup
      .number()
      .test("units must be number only ", (number) => Number.isInteger(number))
      .integer(`Please enter a valid phone number without decimal values`)
      .typeError(translation("units"))
      .required(translation("units"))
      .min(0, translation("min_units"))
      .max(500, translation("max_units")),
    floor: yup.string().trim().optional().max(20, translation("floor_len_20")),
    prefix: yup.string().trim().max(20, translation("prefix_len_20")),
    sufix: yup.string().trim().max(20, translation("suffix_len_20")),
    apiKey: yup.string().trim().required(translation("apiKey_is_req")),
  });

const addResidentValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required(translation("first_name_is_req"))
      .max(30, translation("first_name_max_len_30")),
    last_name: yup
      .string()
      .trim()
      .required(translation("last_name_is_req"))
      .max(30, translation("last_name_max_len_30")),
    email: yup
      .string()
      .trim()
      .required(translation("email_is_req"))
      .matches(emailRegex, translation("email_valid"))
      .email(translation("email_valid")),
    phone: yup
      .string()
      .trim()
      .required(translation("phone_is_req"))
      .matches(phoneRegExp, translation("phone_valid")),
    unit_id: yup.string().trim().required(translation("unit_is_req")),
    date: yup.string().trim().required(translation("date_is_req")),

    property_id: yup.string().trim().required(translation("property_is_req")),
  });
const addSupportTicketValidationSchema = (
  translation: (key: string) => string
) =>
  yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .required(translation("full_name_is_req"))
      .max(30, translation("full_name_max_len_30")),
    email: yup
      .string()
      .trim()
      .required(translation("email_is_req"))
      .matches(emailRegex, translation("email_valid"))
      .email(translation("email_valid")),
    companyIdForSupportTicketCreation: yup
      // .string().trim()
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .test(
        translation("company_is_req"),
        (unitIdForSupportTicketCreation) =>
          Object.keys(unitIdForSupportTicketCreation).length !== 0
      )
      .typeError("company_is_req")
      .required(translation("company_is_req")),
    buildingIdForSupportTicketCreation: yup
      // .string().trim()
      .object()
      .shape({
        label: yup.string().trim().required(),
        value: yup.string().trim().required(),
      })
      .test(
        "",
        translation("building_is_req"),
        (buildingIdForSupportTicketCreation) =>
          Object.keys(buildingIdForSupportTicketCreation).length !== 0
      )
      .typeError("building_is_req")
      .required(translation("building_is_req")),
    unitIdForSupportTicketCreation: yup
      // .string().trim()
      .object()
      .shape({
        label: yup.string().trim().required(),
        value: yup.string().trim().required(),
      })
      .test(
        "",
        translation("unit_is_req"),
        (unitIdForSupportTicketCreation) =>
          Object.keys(unitIdForSupportTicketCreation).length !== 0
      )
      .typeError("unit_is_req")
      .required(translation("unit_is_req")),
    message: yup.string().trim().required(translation("message_is_req")),
  });
const updateSupportTicketValidationSchema = (
  translation: (key: string) => string
) =>
  yup.object().shape({
    issueStatus: yup
      .array()
      .of(yup.string())
      .required(translation("company_is_req")),
    comment: yup.string().trim().required(translation("message_is_req")),
  });

const addNewStaffValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    first_name: yup
      .string()
      .trim()
      .required(translation("first_name_is_req"))
      .max(30, translation("first_name_max_len_30")),
    last_name: yup
      .string()
      .trim()
      .required(translation("last_name_is_req"))
      .max(30, translation("first_name_max_len_30")),
    email: yup
      .string()
      .trim()
      .required(translation("email_is_req"))
      .matches(emailRegex, translation("email_valid"))
      .email(translation("email_valid")),
    phone: yup
      .string()
      .trim()
      .required(translation("phone_is_req"))
      .matches(phoneRegExp, translation("phone_valid")),

    role_id: yup.string().trim().required(translation("role_is_req")),
  });

const myProfileValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    email: yup.string().trim().required(translation("email_is_req")),
    phone: yup
      .string()
      .required(translation("phone_is_req"))
      .matches(phoneRegExp, translation("phone_valid")),
    addressLine1: yup.string().trim().required(translation("addressLine1")),
    city: yup.string().trim().required(translation("city")),
    zip: yup.string().required(translation("zip")),
    state: yup.string().trim().required(translation("state")),
    country: yup.string().trim().required(translation("country")),
  });
const assignUnitValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    residentId: yup.string().trim().required(translation("resident_is_req")),
  });

const updatePasswordValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    old_password: yup.string().required(translation("old_password")),
    password: yup
      .string()
      .required(translation("password"))
      .matches(passwordRegExp, translation("pass_reg_ms")),
    confirm_password: yup
      .string()
      .required(translation("confirm_password"))
      .matches(passwordRegExp, translation("pass_reg_ms"))
      .max(25, translation("max_len_25")),
  });

const supportValidationSchema = (translation: (key: string) => string) =>
  yup.object().shape({
    message: yup.string().trim().required(translation("country")),
  });

const sendEmailValidationSchema = (
  translation: (key: string) => string,
  shouldValidate: string
) => {
  let baseSchema = yup.object().shape({
    user_type: yup.string().required(translation("select_user_type")),
    description: yup
      .string()
      .required(translation("description_req"))
      .max(1000, translation("description_max_length")),
    subject: yup
      .string()
      .required(translation("subject_req"))
      .max(100, translation("subject_max_length")),
  });

  if (shouldValidate === "yes") {
    baseSchema = baseSchema.shape({
      building_list: yup
        .array()
        .nullable()
        .min(1, translation("select_building"))
        .required(translation("select_building")),
    });
  }

  return baseSchema;
};

export {
  loginValidationSchema,
  forgetPasswordValidationSchema,
  resetPasswordValidationSchema,
  addCompanyValidationSchema,
  addPropertyValidationSchema,
  addResidentValidationSchema,
  addNewStaffValidationSchema,
  myProfileValidationSchema,
  updatePasswordValidationSchema,
  supportValidationSchema,
  assignUnitValidationSchema,
  addSupportTicketValidationSchema,
  updateSupportTicketValidationSchema,
  addUnitValidationSchema,
  sendEmailValidationSchema,
};
