import React from "react";
import { useTranslation } from "react-i18next";

import { ISearchFilter } from "../../interfaces/ResidentInterface";

import search from "../../assets/images/search.svg";

const CommonSearchBar = (props: ISearchFilter) => {
  const { t: translation } = useTranslation();
  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange?.(e?.target?.value);
  };
  const { searchPlaceholderText } = props;

  return (
    <div className="admin-header">
      <div className="row">
        <div className="col-md-12">
          <div className="header-search">
            {props.isSearch ? (
              <div className="form-group mb-0 ">
                <div className="right-icon-input ">
                  <input
                    onChange={handleFilterChange}
                    type="text"
                    className="form-control custom-search"
                    placeholder={translation(searchPlaceholderText)}
                  />
                  <span>
                    <img src={search} alt="" />
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSearchBar;
