import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    initImmediate: false,
    lng: "en",

    fallbackLng: "en",

    debug: true,
    backend: {
      /* translation file path */
      loadPath: "./assets/i18n/{{ns}}/{{lng}}.json",
    },
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    // react: {
    //   wait: true,
    // },
  });

export default i18n;
