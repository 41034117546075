import React, { ChangeEvent, useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { ILoginState } from "../../interfaces/AuthInterface";

import {
  admin,
  privacyPolicyUrl,
  termsAndConditionUrl,
  user,
} from "../../constants/commonConstants";
import { loginValidationSchema } from "../../utils/validationSchema";

import ROUTES from "../../constants/routes";
import storage from "../../utils/storage";

import Logo from "../../assets/images/logo.png";

import "./userAuth.scss";

const loginType = process.env.REACT_APP_USER_TYPE;

interface ILoginProps {
  type: string;
  loading: boolean;
  checkBoxDataHandler: (value: boolean) => void;
  getLogin: (value: ILoginState) => void;
}

const CommonLogin: React.FC<ILoginProps> = (props) => {
  const { type, getLogin, loading, checkBoxDataHandler } = props;

  const { t: translation } = useTranslation();
  const [toggle, setToggle] = useState(false);
  const [checkBox1, setCheckBox] = useState<boolean>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ILoginState>({
    resolver: yupResolver(loginValidationSchema(translation)),
  });

  /**
   *  login
   */
  const onSubmitloginData = async (data: ILoginState) => {
    getLogin(data);
  };

  /**
   *  password hide or seen toggle
   */
  const passwordToggle = () => {
    setToggle(!toggle);
  };

  /**
   *  checkBox checked or unchecked
   */

  const checkBoxHandler = ({
    currentTarget: { checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setCheckBox(checked);
    checkBoxDataHandler(checked);
  };

  /**
   *  get data from localStorage
   */
  const getRememberData = () => {
    if (storage.get("rememberMe")) {
      setCheckBox(true);
      checkBoxDataHandler(true);
      const data = JSON.parse(storage.get("rememberMe"));

      reset({
        email: data?.email,
        password: data?.password,
      });
    } else {
      setCheckBox(false);
    }
  };

  useEffect(() => {
    getRememberData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmitloginData)}>
      <div className={loginType === admin ? "inner" : ""}>
        {type === user ? (
          <>
            <img src={Logo} alt="logo" className="logo-left-set" />
            <div className="resident-head">
              <h2>
                {translation("Control your")} <span>{translation("home")}</span>{" "}
                {translation("with_click")}
              </h2>
              <p>{translation("welcome_message_for_user")}</p>
            </div>
          </>
        ) : (
          <h2 className="text-center white-color mb-3">
            {translation("admin")}
          </h2>
        )}

        <div className="form-group">
          <div className="left-icon-input">
            <input
              {...register("email")}
              type="text"
              className="form-control"
              placeholder={translation("user_name")}
            />
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28.421"
                height="30"
                viewBox="0 0 28.421 30"
              >
                <g id="bx-user" opacity="0.5">
                  <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M22.5,10.5A4.5,4.5,0,1,1,18,6,4.5,4.5,0,0,1,22.5,10.5Z"
                    transform="translate(-3.789 -3.25)"
                    fill="none"
                  />
                  <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M18.711,3a7.895,7.895,0,1,0,7.895,7.895A7.9,7.9,0,0,0,18.711,3Zm0,12.632a4.737,4.737,0,1,1,4.737-4.737A4.743,4.743,0,0,1,18.711,15.632ZM32.921,33V31.421A11.067,11.067,0,0,0,21.868,20.368H15.553A11.066,11.066,0,0,0,4.5,31.421V33H7.658V31.421a7.9,7.9,0,0,1,7.895-7.895h6.316a7.9,7.9,0,0,1,7.895,7.895V33Z"
                    transform="translate(-4.5 -3)"
                  />
                </g>
              </svg>
            </span>
          </div>
          {errors && errors.email && (
            <p className="auth-msg error">
              {translation(`${errors.email.message}`, { field: "email" })}
            </p>
          )}
        </div>

        <div className="form-group">
          <div className="double-icon-input">
            <input
              {...register("password")}
              type={toggle ? "text" : "password"}
              className="form-control"
              placeholder={translation("password_placeholder")}
            />
            <span className="left-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.042"
                height="30"
                viewBox="0 0 30.042 30"
              >
                <g id="password" opacity="0.5">
                  <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M22.607,2.25a9.643,9.643,0,0,0-9.643,9.643,9.5,9.5,0,0,0,.418,2.8L2.25,25.821V32.25H8.679L19.811,21.118A9.643,9.643,0,0,0,32.25,12.685a9.4,9.4,0,0,0-1.768-6.429A9.643,9.643,0,0,0,22.607,2.25Zm0,17.143a7.5,7.5,0,0,1-2.143-.321L19.232,18.7l-.91.91-3.408,3.408L13.4,21.536l-1.51,1.51,1.478,1.478-1.7,1.7L10.189,24.75l-1.51,1.51,1.478,1.478L7.789,30.107h-3.4v-3.4l10.5-10.532.91-.91-.31-1.018a7.649,7.649,0,0,1,3.643-9.043,7.5,7.5,0,0,1,10.971,6.429,7.168,7.168,0,0,1-1.168,4.286,7.5,7.5,0,0,1-6.332,3.471Z"
                    transform="translate(-2.25 -2.25)"
                  />
                  <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M27,11.25A2.25,2.25,0,1,1,24.75,9,2.25,2.25,0,0,1,27,11.25Z"
                    transform="translate(-3.375 -2.598)"
                  />
                </g>
              </svg>
            </span>
            <span className="right-icon">
              <button
                type="button"
                className="theme-button trans-btn"
                onClick={passwordToggle}
              >
                {!toggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="30.01"
                    viewBox="0 0 40 30.01"
                  >
                    <path
                      id="np_view-password_4287676_000000"
                      d="M45.967,31.312c-5.561-7.2-11.822-10.552-19.7-10.552a22.958,22.958,0,0,0-8.037,1.374L13.488,17.4a.716.716,0,0,0-1.011,0l-1.011,1.011a.716.716,0,0,0,0,1.011L15.443,23.4a28.612,28.612,0,0,0-8.883,7.919,1.455,1.455,0,0,0,0,1.76c5.564,7.2,11.825,10.552,19.7,10.552A22.958,22.958,0,0,0,34.3,42.251l4.737,4.737a.716.716,0,0,0,1.011,0l1.011-1.011a.716.716,0,0,0,0-1.011L37.084,40.99a28.612,28.612,0,0,0,8.883-7.919,1.455,1.455,0,0,0,0-1.76Zm-19.7,9.455c-6.7,0-11.9-2.654-16.752-8.574a23.986,23.986,0,0,1,8.06-6.672l3.787,3.787a5.633,5.633,0,0,0-.811,2.885,5.724,5.724,0,0,0,5.716,5.716,5.649,5.649,0,0,0,2.885-.809l2.871,2.871a20.792,20.792,0,0,1-5.756.8Zm2.751-7.844L25.531,29.44a2.924,2.924,0,0,1,.732-.105,2.862,2.862,0,0,1,2.858,2.858,2.821,2.821,0,0,1-.107.731Zm-2.02,2.02a2.821,2.821,0,0,1-.731.107,2.862,2.862,0,0,1-2.858-2.858,2.821,2.821,0,0,1,.107-.731Zm7.962,3.921-3.787-3.787a5.633,5.633,0,0,0,.811-2.885,5.724,5.724,0,0,0-5.716-5.716,5.649,5.649,0,0,0-2.885.809l-2.871-2.871a20.792,20.792,0,0,1,5.756-.8c6.7,0,11.906,2.654,16.752,8.574a23.986,23.986,0,0,1-8.06,6.672Z"
                      transform="translate(-6.263 -17.188)"
                      opacity={0.3}
                    />
                  </svg>
                )}
                {toggle && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24"
                    viewBox="0 0 30 24"
                  >
                    <path
                      id="Show"
                      opacity={0.3}
                      d="M17.432,4.007,17,4C10.792,4,5.194,8.385,2.091,15.559a1.111,1.111,0,0,0,0,.883l.215.484C5.37,23.64,10.663,27.8,16.568,27.993L17,28c6.211,0,11.809-4.385,14.912-11.559a1.112,1.112,0,0,0,0-.893l-.209-.47C28.625,8.351,23.329,4.2,17.432,4.007Zm-.419,2.227.358.008.4.021c4.8.34,9.206,3.9,11.871,9.736l-.014.036C26.9,22,22.335,25.578,17.388,25.757l-.382.006-.386-.005-.4-.021c-4.647-.329-8.931-3.686-11.612-9.2L4.355,16l.235-.5C7.417,9.66,12.037,6.235,17.014,6.234ZM17,10.17A5.829,5.829,0,1,0,22.867,16,5.848,5.848,0,0,0,17,10.17Zm0,2.235A3.594,3.594,0,1,1,13.383,16,3.606,3.606,0,0,1,17,12.406Z"
                      transform="translate(-2 -4)"
                      fillRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            </span>
          </div>
          {errors && errors.password && (
            <p className="auth-msg error">
              {translation(`${errors.password.message}`, {
                field: "password",
              })}
            </p>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <label htmlFor="remember-me" className="outer-check login-check ">
            <input
              type="checkbox"
              id="remember-me"
              checked={checkBox1}
              onChange={(e) =>
                checkBoxHandler(e as unknown as ChangeEvent<HTMLInputElement>)
              }
              defaultChecked={storage.get("rememberMe") ? true : false}
            />
            <span className="checkmark" />
            <p className="op-6">{translation("remember_me")} </p>
          </label>
          <Link to={ROUTES.FORGET_PASSWORD}>
            {" "}
            <p className="op-6">{translation("recover_password")}</p>
          </Link>
        </div>
        <button
          type="submit"
          className="theme-button primary-btn w-100"
          disabled={loading}
        >
          {translation("sign_in")}
          {loading ? (
            <div
              className="spinner-border text-light ms-2 btn-loader"
              role="status"
            >
              <span className="sr-only" />
            </div>
          ) : (
            ""
          )}
        </button>
      </div>
      {loginType !== admin && (
        <div className="mt-2 d-flex items-center justify-content-between">
          <a
            href={privacyPolicyUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="op-6"
          >
            {translation("privacy_policy")}
          </a>

          <a
            href={termsAndConditionUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="op-6"
          >
            {translation("terms_condition")}
          </a>
        </div>
      )}
    </form>
  );
};

export default CommonLogin;
