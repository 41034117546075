import React, { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { getBuildingList, sendEmailData } from "../../services/email";
import Header from "../../components/header/Header";
import CommonTextBox from "../../components/formElements/CommonTextBox";
import CommonTextarea from "../../components/formElements/CommonTextarea";
import Attachments from "./Attachments";
import useMultipleAttachment from "./UseMultipleAttachment";
import CommonSelect from "../../components/formElements/CommonSelect";
import CommonMultiSearchSelect from "../../components/formElements/CommonSearchMultiSelect";
import { IMultiSelect } from "../../interfaces/CommonInterface";
import { checkBoxType, userType } from "../../constants/commonConstants";
import { sendEmailValidationSchema } from "../../utils/validationSchema";
import storage from "../../utils/storage";
import {
  IBuildingDataSchema,
  IlistData,
  ISendEmailParams,
} from "../../interfaces/EmailInterface";
import toast from "../../utils/toastMessage";

const Email: React.FC = () => {
  const { t: translation } = useTranslation();
  const [buildingCheckBox, setBuildingCheckBox] = useState<boolean>(true);
  const [allcheckBox, setAllCheckBox] = useState<boolean>();
  const [attachFilesEvent, setAttachFilesEvent] = useState<FileList | null>(
    null
  );
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      sendEmailValidationSchema(translation, buildingCheckBox ? "yes" : "no")
    ),
  });
  const [buildingListData, setBuildingListData] = useState<IMultiSelect[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userTypeData, setUserType] = useState("");
  const { handleGetAttachments, setAttachFiles, attachFile } =
    useMultipleAttachment(attachFilesEvent);

  /**
   *  get Building List Data
   */

  const getBuildingListData = async () => {
    const buildingList = await getBuildingList(
      await storage.get("propertyManagerId")
    );
    if (buildingList.data.data.length > 0) {
      const buildingData: IMultiSelect[] = [];
      await buildingList?.data?.data.forEach((item: IlistData) => {
        buildingData.push({
          value: item?._id,
          label: item.building_name,
        });
      });
      setBuildingListData(buildingData);
    }
  };
  useEffect(() => {
    getBuildingListData();
  }, []);

  /**
   *  checkBox checked or unchecked
   */

  const checkBoxHandler = (checkbox: string) => {
    if (checkbox === checkBoxType.buildingCheckBox) {
      setBuildingCheckBox(true);
      setAllCheckBox(false);
    } else if (checkbox === checkBoxType.allCheckBox) {
      setBuildingCheckBox(false);
      setAllCheckBox(true);
      setValue("building_list", null);
    }
  };

  /**
   *   Email form handler
   */

  const onSubmit = async (data: IBuildingDataSchema) => {
    setLoading(true);
    const buildingDataIds: string[] = [];
    if (data && data.building_list?.length) {
      data.building_list.forEach((item: IMultiSelect) => {
        buildingDataIds.push(item.value);
      });
    }

    const getData = await handleGetAttachments();
    const params: ISendEmailParams = {
      company_user_id: await storage.get("propertyManagerId"),
      buildingsIds: buildingCheckBox ? buildingDataIds : null,
      allBuildings: allcheckBox ? true : false,
      userType: userTypeData,
      mailDetails: {
        subject: data.subject,
        description: data.description,
        attachment: (getData as { url: string; response: any }[]).map(
          ({ url }) => url
        ),
      },
    };
    const responseData = await sendEmailData(params);

    if (responseData?.data?.success) {
      toast.success(translation(`${responseData?.data?.message}`));
    } else {
      toast.error(translation(`${responseData?.data?.message}`));
    }
    reset();
    setAttachFiles([]);
    setLoading(false);
  };

  return (
    <div className="p-4 email-page">
      <Header />
      <div className="d-flex align-items-center justify-content-between">
        <h2 className="theme-color fw-bold">Email</h2>
      </div>

      <form
        onSubmit={handleSubmit((data) => onSubmit(data as IBuildingDataSchema))}
      >
        <div className="white-card mt-3">
          <div className="inline-radio mb-3">
            <label className="custom-radio" htmlFor="bulding-list">
              {translation("bulding_list")}
              <input
                type="radio"
                id="building-list"
                checked={buildingCheckBox}
                onChange={() => checkBoxHandler("buildingCheckBox")}
                defaultChecked={buildingCheckBox}
              />
              <span className="checkmark" />
            </label>
            <label className="custom-radio" htmlFor="all">
              {translation("all")}
              <input
                type="radio"
                id="all"
                name="radio"
                checked={allcheckBox}
                onChange={() => checkBoxHandler("allCheckBox")}
              />
              <span className="checkmark" />
            </label>
          </div>
          <div className="row">
            {buildingCheckBox ? (
              <div className="col-md-4">
                <CommonMultiSearchSelect
                  className="form-select"
                  aria-label="Default select example"
                  options={buildingListData}
                  errors={errors?.building_list}
                  control={control}
                  name="building_list"
                  isSearchable={false}
                  isMulti
                  disabled={false}
                />
              </div>
            ) : (
              ""
            )}
            <div className="col-md-4">
              <CommonSelect
                className="form-select"
                aria-label="Default select example"
                dropDownData={userType}
                error={errors?.user_type}
                control={control}
                name="user_type"
                multipleSelect={false}
                firstOption="--Select User Type--"
                onChange={(value) => setUserType(value)}
              />
            </div>
          </div>
        </div>

        <div className="white-card mt-3">
          <div className="form-group">
            <CommonTextBox
              label={translation("subject")}
              control={control}
              type="text"
              error={errors?.subject}
              placeHolder={translation("add_subject")}
              className="form-control grey-input"
              name="subject"
              fieldType="input"
            />
          </div>
          <div className="form-group">
            <CommonTextarea
              label={translation("description")}
              control={control}
              placeHolder={translation("add_description")}
              className="form-control grey-input"
              name="description"
              fieldType="message"
              rows={10}
              error={errors?.description}
            />
          </div>

          <div className="atteched-file-btn mb-3">
            <p className="form-group">{translation("atteched_file_type")}</p>
            <input
              {...register("Attechedimage")}
              className="file-op"
              type="file"
              accept=""
              multiple
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setAttachFilesEvent(event.target.files)
              }
            />
            <div className="btn-inner">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                  <path
                    d="m17.65 9.4-7.78 7.78a2 2 0 0 0 2.83 2.82l7.77-7.78a4 4 0 0 0 -5.65-5.65l-7.82 7.78a6 6 0 0 0 8.49 8.49l9.19-9.19"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>{" "}
                {translation("atteched_file")}
              </span>
            </div>
          </div>

          <Attachments fileItems={attachFile} setFileItems={setAttachFiles} />
          <div className="action-btn p-0">
            <button
              type="submit"
              className="theme-button primary-btn"
              disabled={loading}
            >
              {translation("send")}
              {loading ? (
                <div
                  className="spinner-border text-light ms-2 btn-loader"
                  role="status"
                >
                  <span className="sr-only" />
                </div>
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Email;
