import React, { useState } from "react";

import {
  Controller,
  Control,
  FieldErrors,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";

import CommonTextBox from "./CommonTextBox";

interface IProps1 {
  name: string;
  label?: string;
  control: Control;
  error?: FieldErrors<FieldValues> | FieldError;
  minReqDate?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const CommonDatePicker: React.FC<IProps1> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { t: translation } = useTranslation();
  const {
    label,
    name,
    control,
    error,
    minReqDate = false,
    disabled,
    readOnly,
  } = props;

  return (
    <div className="form-group">
      <div
        className="icon-input"
        onClick={() => (!readOnly || !disabled) && setShowModal(showModal)}
      >
        <label htmlFor={name}>{label}</label>
        <CommonTextBox
          type="date"
          name={name}
          className="form-control"
          control={control}
          disabled={disabled}
          readOnly={readOnly}
          onKeyDown={(e) => {
            if (e.key) {
              e.preventDefault();
            }
          }}
        />
      </div>
      {showModal ? (
        <Controller
          render={({ field }) => (
            <Calendar
              value={field.value as Date}
              onChange={(value: Date) => {
                field.onChange(value);
                setShowModal(false);
              }}
              minDate={minReqDate ? new Date() : undefined}
            />
          )}
          name={name}
          control={control}
        />
      ) : null}

      <div className="auth-msg error">
        <p>
          {error && error.message ? (
            <>{translation(`${error.message}`, { field: name })}</>
          ) : null}
        </p>
      </div>
    </div>
  );
};
export default CommonDatePicker;
