import { http } from "../utils/http";

import {
  IAddPropertyDetail,
  IBuildingDeleteParams,
  IEditablePropertyData,
  IGerProperty,
  IUnitCreationCheck,
} from "../interfaces/PropertyInterface";
import { IFilePath } from "../interfaces/AuthInterface";

import ApiResponse from "../resources/entity/IApiResponse";
import endpoint from "../constants/endPoint";

/**
 * Get Property
 */
export const getProperty = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETPROPERTY.replace(":property_id", data)}`
  );
};

/**
 * Get PropertyManager
 */
export const getPropertyManager = (data: string): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETPROPERTYMANAGER.replace(
      ":property_manager_id",
      data
    )}`
  );
};

/**
 *  Add Property
 */
export const addProperty = (data: IAddPropertyDetail): Promise<ApiResponse> => {
  return http.post(`${endpoint.Property.ADDPROPERTIES}`, data);
};

/**
 * Get Dashboard for a property
 */
export const getDashboard = (data: IGerProperty): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETDASHBOARD.replace(
      ":property_id",
      data.property_id
    )}`
  );
};

/**
 *  Get all properties list
 */
export const allProperties = (
  propertyId: string,
  offset?: number
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.GETPROPERTIES.replace(":id", propertyId)}${
      offset || offset === 0 ? `?offset=${offset}` : ""
    }`
  );
};

/**
 * Get presignedUrl for image upload
 */
export const getSignedUrl = (data: IFilePath): Promise<ApiResponse> => {
  return http.post(`${endpoint.Property.PRESIGNEDURL}`, data);
};

/**
 *  Get record count of units,residents for a building
 */
export const recordCount = (
  data: string,
  company_user_id?: string
): Promise<ApiResponse> => {
  const queryParams: string[] = [];
  if (data === "all") {
    // Object.keys(data).forEach((key) => {
    queryParams.push(`propertyManagerId=${company_user_id}`);
    // });
  }
  return http.get(
    `${endpoint.Property.REORDCOUNT.replace(":property_id", data)}${
      queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
    }`
  );
};

/**
 * update Add property
 */

export const updateProperty = (
  data: IEditablePropertyData
): Promise<ApiResponse> => {
  return http.post(`${endpoint.Property.UPDATEPROPERTY}`, data);
};

/**
 *  get api keys
 */
export const getApiKeys = (data: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.Property.GETAPIKEYS.replace(":id", data)}`);
};

/**
 * unit count check
 */

export const unitCreationCheck = (
  data: IUnitCreationCheck
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.Property.UNITCHECK.replace(":id", data.propertyId).replace(
      ":units",
      data.units
    )}`
  );
};

/**
 * delete property
 */
export const deleteProperty = (
  propertyInfo: IBuildingDeleteParams
): Promise<ApiResponse> => {
  return http.post(`${endpoint.property.DELETEPROPERTY}`, propertyInfo);
};
/**
 * get propertylist
 */
export const getPropertyList = (): Promise<ApiResponse> => {
  return http.get(`${endpoint.property.GETPROPERTIES}`);
};

/**
 * delete access token
 */

export const deleteCompanyAccessToken = (id: string): Promise<ApiResponse> => {
  return http.delete(
    endpoint.Property.DELETECOMPANYACCESSTOKEN.replace(":id", id)
  );
};
