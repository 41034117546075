import React, { useState } from "react";

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";

import { support } from "../../services/resident";

import { IAuthReducerData } from "../../interfaces/AuthInterface";
import { SupportMessage } from "../../interfaces/CommonInterface";

import { RootState } from "../../redux/reducers/Index";
import { supportValidationSchema } from "../../utils/validationSchema";

import toast from "../../utils/toastMessage";
import storage from "../../utils/storage";

import close from "../../assets/images/close.svg";

interface ISupportProps {
  openModal: boolean;
  closePopUpModal?: (e: boolean) => void;
}

const Support: React.FC<ISupportProps> = (props) => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const closePopUp = (data: boolean) => {
    props?.closePopUpModal?.(data);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(supportValidationSchema(translation)),
  });

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  /**
   * Send support message
   */
  const SupportMessageSend = async (data: SupportMessage) => {
    setLoading(true);
    const SupportMessageData = {
      user_id: authReducer.authData.id,
      property_id: storage.get("propertyManagerId"),
      unit_id: storage.get("unitId"),
      name: `${authReducer.authData.first_name} ${
        authReducer.authData.last_name ? authReducer.authData.last_name : ""
      }`,
      email: authReducer.authData.email,
      message: data.message,
    };

    const responseData = await support(SupportMessageData);

    if (responseData?.data?.success) {
      toast.success(translation(`${responseData?.data?.message}`));
      closePopUp(false);
      reset();
    } else {
      toast.error(translation(`${responseData?.data?.message}`));
      reset();
    }
    setLoading(false);
  };

  return (
    <div>
      {props?.openModal && (
        <form
          onSubmit={handleSubmit((data) =>
            SupportMessageSend(data as SupportMessage)
          )}
        >
          <div
            className="modal modal-custom-show"
            id="assignUnitPopUp"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body  p-5 close-btn">
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn-close close-inner theme-button p-0"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => closePopUp(false)}
                    >
                      <img src={close} alt="" />
                    </button>
                  </div>
                  <div className="row">
                    <h3 className="theme-color text-center mb-3">
                      <b>{translation("contact_support")}</b>
                    </h3>
                    <div className="form-group">
                      <textarea
                        {...register("message")}
                        className="form-control grey-input"
                        placeholder={translation("message_placeholder")}
                        rows={6}
                      />
                      {errors.message && (
                        <span>{translation("field_required_message")}</span>
                      )}
                    </div>
                  </div>
                  <div className="d-flex">
                    <button
                      className="theme-button primary-btn w-100"
                      type="submit"
                    >
                      {translation("send_message")}

                      {loading ? (
                        <div
                          className="spinner-border text-light ms-2 btn-loader"
                          role="status"
                        >
                          <span className="sr-only" />
                        </div>
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className="theme-button black-btn-outline w-100 "
                      type="button"
                      onClick={() => {
                        reset();
                        closePopUp(false);
                      }}
                    >
                      {translation("cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Support;
