import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { IForgetPassword, ILoginState } from "../../interfaces/AuthInterface";

import { forgetPasswordValidationSchema } from "../../utils/validationSchema";
import { admin, user } from "../../constants/commonConstants";

import ROUTES from "../../constants/routes";

import Logo from "../../assets/images/logo.png";

import "./userAuth.scss";

const loginType = process.env.REACT_APP_USER_TYPE;

interface ILoginProps {
  type: string;
  loading: boolean;
  getRecover: (value: IForgetPassword) => void;
}

const CommonRecover: React.FC<ILoginProps> = (props) => {
  const { type, getRecover, loading } = props;

  const { t: translation } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginState>({
    resolver: yupResolver(forgetPasswordValidationSchema(translation)),
  });

  /**
   * Recover password
   */
  const onRecoverData = async (data: IForgetPassword) => {
    getRecover(data);
  };

  return (
    <form onSubmit={handleSubmit(onRecoverData)}>
      <div className={loginType === admin ? "inner" : ""}>
        {type === user ? (
          <>
            <img src={Logo} alt="logo" className="logo-left-set" />
            <div className="resident-head">
              <h2>{translation("recover_password")}</h2>
              <p> {translation("enter_email_for_new_passwprd")}</p>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-center white-color mb-3">
              {translation("recover_password")}
            </h2>
            <p className="text-center white-color mb-3">
              {translation("enter_email_for_new_passwprd")}
            </p>
          </>
        )}
        <div className="form-group">
          <div className="left-icon-input">
            <input
              {...register("email")}
              type="text"
              className="form-control"
              placeholder={translation("email_address")}
            />
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 41.25 32.25"
              >
                <g
                  id="bx-user"
                  transform="translate(0.45 -2.798)"
                  opacity="0.5"
                >
                  <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M22.5,10.5A4.5,4.5,0,1,1,18,6,4.5,4.5,0,0,1,22.5,10.5Z"
                    transform="translate(2.175)"
                    fill="none"
                  />
                  <g id="mail-outline" transform="translate(0.675 3.923)">
                    <path
                      id="Path_292"
                      data-name="Path 292"
                      d="M7.126,5.625h31.5A4.882,4.882,0,0,1,43.5,10.5V33a4.882,4.882,0,0,1-4.874,4.874H7.126A4.882,4.882,0,0,1,2.25,33V10.5A4.881,4.881,0,0,1,7.126,5.625Zm31.5,30A2.629,2.629,0,0,0,41.25,33V10.5a2.629,2.629,0,0,0-2.625-2.626H7.126A2.629,2.629,0,0,0,4.5,10.5V33a2.629,2.629,0,0,0,2.626,2.624Z"
                      transform="translate(-3.375 -6.75)"
                    />
                    <path
                      id="Path_293"
                      data-name="Path 293"
                      d="M22.044,21.7a1.124,1.124,0,0,1-.619-.185L7.256,12.19A1.125,1.125,0,1,1,8.494,10.31l13.551,8.921L35.6,10.31a1.125,1.125,0,1,1,1.237,1.879L22.663,21.518A1.124,1.124,0,0,1,22.044,21.7Z"
                      transform="translate(-2.544 -4.89)"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </div>
          {errors && errors.email && (
            <p className="auth-msg error">
              {translation(`${errors.email.message}`, { field: "Email" })}
            </p>
          )}
        </div>
        <button type="submit" className="theme-button primary-btn mb-3 w-100">
          {translation("recover_password")}
          {loading ? (
            <div
              className="spinner-border text-light ms-2 btn-loader"
              role="status"
            >
              <span className="sr-only" />
            </div>
          ) : (
            ""
          )}
        </button>
        <p className="text-center">
          {translation("remember_password")}
          <Link to={ROUTES.LOGIN}>
            {" "}
            <span className="theme-color"> {translation("login_now")}</span>
          </Link>
        </p>
      </div>
    </form>
  );
};

export default CommonRecover;
