import React, { ChangeEvent, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { yupResolver } from "@hookform/resolvers/yup";
import { debounce, isEmpty } from "lodash";
import * as Sentry from "@sentry/react";

import { getPlacesDetail, getPredictions } from "../../services/google";
import {
  changePassword,
  deleteImage,
  updateProfile,
} from "../../services/auth";
import { getSignedUrl } from "../../services/property";

import {
  IAuthData,
  IAuthReducerData,
  IUpdatePassword,
} from "../../interfaces/AuthInterface";
import { IAddressObject, Prediction } from "../../interfaces/CommonInterface";

import { myProfileValidationSchema } from "../../utils/validationSchema";
import { RootState } from "../../redux/reducers/Index";
import { arrowDown, arrowUp, extension } from "../../constants/commonConstants";

import toast from "../../utils/toastMessage";
import CommonUpdatePassword from "./CommonUpdatePassword";
import Header from "../../components/header/Header";
import CommonTextBox from "../../components/formElements/CommonTextBox";
import ActionType from "../../resources/enums";
import ROUTES from "../../constants/routes";

import location from "../../assets/images/location-pin.svg";
import map from "../../assets/images/map.svg";
import phone from "../../assets/images/phone.svg";
import mail from "../../assets/images/mail.svg";
import avatar from "../../assets/images/avatar.png";
import EditIcon from "../../assets/images/edit.svg";

type FilePath = {
  filePath: string;
  fileFormat: string;
};
const MyProfile: React.FC = () => {
  const [propertyAddress, setAddress] = useState<IAddressObject>();
  const [locationPredictions, setLocationPredictions] =
    useState<Prediction[]>();
  const [showLocationPrediction, setShowLocationPrediction] =
    useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(true);
  const [image, setImage] = useState<File>();
  const [previewImg, setPreviewImg] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordLoading, setPasswordLoading] = useState<boolean>(false);

  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(myProfileValidationSchema(translation)),
  });

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  /**
   * Get Image from user
   */
  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files[0]) {
      const Extension = files[0]?.type?.split("/")[1];
      if (
        Extension === extension.jpeg ||
        Extension === extension.jpg ||
        Extension === extension.png
      ) {
        setPreviewImg(URL.createObjectURL(files[0]));
        setImage(files[0]);
      } else {
        toast.error(translation("invaild_image_format"));
      }
    }
  };

  /**
   *  get presignedUrl for image upload
   */
  const uploadFileOnS3 = async (file: Blob, filePath: string) => {
    let body: FilePath = {
      filePath: "",
      fileFormat: "",
    };
    body = {
      filePath,
      fileFormat: file.type as string,
    };
    let signedUrl;
    const presignedUrl = await getSignedUrl(body);
    if (presignedUrl && presignedUrl.data) {
      const response = await pushFileToS3(presignedUrl.data.data, file);
      if (response?.url) {
        signedUrl = response?.url.split("?Content")?.[0];
      }
    }
    return signedUrl;
  };

  /**
   *  Upload file on presignedUrl of S3
   */
  const pushFileToS3 = async (signedUrl: string, file: Blob) => {
    const myHeaders = new Headers({
      "Content-Type": file.type,
      "x-amz-acl": "public-read",
    });
    return fetch(signedUrl, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    });
  };

  /**
   *  update Password
   */
  const onUpdatePassword = async (passworData: IUpdatePassword) => {
    setPasswordLoading(true);
    const passwordObject = {
      email: authReducer.authData.email,
      newPassword: passworData.password,
      oldPassword: passworData.old_password,
    };
    const changePasswordInfo = await changePassword(passwordObject);

    if (changePasswordInfo?.data?.success) {
      setPasswordLoading(false);
      toast.success(translation(`${changePasswordInfo?.data?.message}`));
    } else {
      setPasswordLoading(false);
      toast.error(translation(`${changePasswordInfo?.data?.message}`));
    }
  };
  /**
   * Edit profile
   */
  const onEditProfile = async (data: IAuthData) => {
    setLoading(true);
    setShowLocationPrediction(false);
    if (image) {
      const file = image;

      const fileNameArr = file?.name.split(".");
      const fileExt = fileNameArr.pop();
      const fileNameWithoutExt = fileNameArr.join("-").replaceAll(" ", "-");
      const filePath = `${fileNameWithoutExt}-${new Date().getTime()}.${fileExt}`;
      const response = await uploadFileOnS3(file, filePath);
      if (response) {
        data.image = response;
      }
    } else {
      data.image = authReducer.authData.image;
    }

    const updatedUserInfo = await updateProfile(data);
    if (updatedUserInfo?.data?.success) {
      if (authReducer.authData.image) {
        const imageUrl = authReducer.authData.image;
        await deleteImage({ imageUrl });
      }
      dispatch({
        type: ActionType.LOGIN,
        payload: updatedUserInfo.data,
      });
      toast.success(translation(`${updatedUserInfo?.data?.message}`));
      setDisable(true);
    } else {
      toast.error(translation(`${updatedUserInfo?.data?.message}`));
    }
    setLoading(false);
  };

  /**
   *  Prediction result
   */
  const addressPredictionChnage = debounce(async (event) => {
    try {
      setShowLocationPrediction(true);
      setLocationPredictions([]);
      const responseData = await getPredictions(event.target.value);

      if (responseData.data && responseData.data.data.predictions) {
        setLocationPredictions(responseData.data.data.predictions);
      }
    } catch (error) {
      console.log("Error", Error);
      Sentry.captureException(Error);
    }
  }, 100);

  /**
   *  handle PlaceSelection result
   */
  const handlePlaceSelection = async (placeId: string) => {
    const responseData = await getPlacesDetail(placeId);
    if (responseData.data && responseData.data.success) {
      setAddress(responseData.data.data);
      setShowLocationPrediction(false);
    }
  };
  /**
   *  reset the fields
   */
  const resetForm = () => {
    setDisable(true);
    setDefaultProfileData();
    reset({
      email: authReducer?.authData?.email ? authReducer?.authData?.email : "",
      phone: authReducer?.authData?.phone ? authReducer?.authData?.phone : "",
      addressLine1: authReducer?.authData?.address?.address_line1
        ? authReducer?.authData?.address?.address_line1
        : "",
      addressLine2: authReducer?.authData?.address?.address_line2
        ? authReducer?.authData?.address?.address_line2
        : "",
      city: authReducer?.authData?.address?.city
        ? authReducer?.authData?.address?.city
        : "",
      state: authReducer?.authData?.address?.state
        ? authReducer?.authData?.address?.state
        : "",
      zip: authReducer?.authData?.address?.zip
        ? authReducer?.authData?.address?.zip
        : "",
      country: authReducer?.authData?.address?.country
        ? authReducer?.authData?.address?.country
        : "",
    });
  };
  useEffect(() => {
    if (!isEmpty(propertyAddress)) {
      reset({
        ...getValues(),
        city: propertyAddress?.city,
        state: propertyAddress?.state,
        zip: propertyAddress?.zip,

        addressLine2: propertyAddress?.addressLine2,
        country: propertyAddress?.country,
        addressLine1: propertyAddress?.addressLine1,
      });
    }
  }, [propertyAddress]);

  useEffect(() => {
    setDefaultProfileData();
  }, []);

  /**
   *fill default profile  fields
   */
  const setDefaultProfileData = () => {
    reset({
      email: authReducer.authData.email,
      phone: authReducer.authData.phone,
      addressLine1: authReducer?.authData?.address?.address_line1,
      addressLine2: authReducer?.authData?.address?.address_line2,
      city: authReducer?.authData?.address?.city,
      zip: authReducer?.authData?.address?.zip,
      state: authReducer?.authData?.address?.state,
      country: authReducer?.authData?.address?.country,
    });
  };
  return (
    <div className="p-4">
      <Header
        childComponent={{
          name: translation("my_profile"),
          route: ROUTES.MY_PROFILE,
        }}
      />
      <h2 className="theme-color fw-bold">{translation("my_profile")}</h2>

      <div className="white-card mt-3">
        <form
          onSubmit={handleSubmit((data) => onEditProfile(data as IAuthData))}
        >
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <div className="edit-profile-select">
                <img
                  className="fix-img me-3"
                  src={
                    previewImg
                      ? previewImg
                      : authReducer.authData.image
                      ? authReducer.authData.image
                      : avatar
                  }
                  alt=""
                />
                {!disable ? (
                  <div className="input-change-image">
                    <img src={EditIcon} alt="icon" />
                    <input
                      {...register("image")}
                      className="file-op"
                      type="file"
                      accept="image/*"
                      onChange={onImageChange}
                    />
                  </div>
                ) : (
                  " "
                )}
              </div>

              <h2>{`${authReducer.authData.first_name} ${
                authReducer.authData.last_name
                  ? authReducer.authData.last_name
                  : " "
              }`}</h2>
            </div>
            <div className="d-flex align-items-center">
              {disable ? (
                <button
                  type="button"
                  onClick={() => setDisable(false)}
                  className="theme-button primary-btn"
                >
                  {translation("edit_profile")}
                </button>
              ) : (
                <>
                  {" "}
                  <button
                    type="submit"
                    disabled={loading}
                    className="theme-button primary-btn"
                  >
                    {translation("save_profile")}
                    {loading ? (
                      <div
                        className="spinner-border text-light ms-2 btn-loader"
                        role="status"
                      >
                        <span className="sr-only" />
                      </div>
                    ) : (
                      ""
                    )}
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      resetForm();
                      setPreviewImg("");
                      setShowLocationPrediction(false);
                    }}
                    className="theme-button black-btn-outline"
                  >
                    {translation("cancel")}
                  </button>{" "}
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="email"
                    placeHolder={translation("email_placeholder")}
                    className="form-control grey-input"
                    name="email"
                    error={errors?.email}
                    fieldType="input"
                    readOnly
                  />
                  <span>
                    <img src={mail} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="number"
                    placeHolder={translation("phone_placeholder")}
                    className="form-control grey-input"
                    name="phone"
                    error={errors?.phone}
                    fieldType="input"
                    disabled={disable}
                    onWheel={(event) => {
                      event.currentTarget.blur();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === arrowDown || e.key === arrowUp) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span>
                    <img src={phone} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("address_line _1_placeholder")}
                    className="form-control grey-input"
                    name="addressLine1"
                    error={errors?.addressLine1}
                    fieldType="input"
                    onChange={(event) => addressPredictionChnage(event)}
                    disabled={disable}
                  />
                  {showLocationPrediction && locationPredictions?.length ? (
                    <ul className="common-search-list">
                      {locationPredictions?.length
                        ? locationPredictions.map((value) => (
                            <li
                              key={value.place_id}
                              onClick={() =>
                                handlePlaceSelection(value.place_id)
                              }
                            >
                              {value.description}
                            </li>
                          ))
                        : null}
                    </ul>
                  ) : (
                    ""
                  )}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 40 40"
                    >
                      <path
                        id="Path_8425"
                        data-name="Path 8425"
                        d="M7.708-26.142a1.631,1.631,0,0,0,1.845-1.845v-4.678a3.109,3.109,0,0,1,.773-2.307,3.047,3.047,0,0,1,2.232-.762h4.785a1.631,1.631,0,0,0,1.867-1.824A1.641,1.641,0,0,0,17.343-39.4H12.407a6.658,6.658,0,0,0-4.871,1.631,6.5,6.5,0,0,0-1.652,4.828v4.957A1.622,1.622,0,0,0,7.708-26.142Zm36.352,0a1.622,1.622,0,0,0,1.824-1.845v-4.957a6.523,6.523,0,0,0-1.642-4.828A6.652,6.652,0,0,0,39.36-39.4H34.425a1.631,1.631,0,0,0-1.845,1.845,1.622,1.622,0,0,0,1.845,1.824H39.21a3.093,3.093,0,0,1,2.232.762,3.058,3.058,0,0,1,.794,2.307v4.678A1.622,1.622,0,0,0,44.06-26.142ZM12.407.6h4.936A1.631,1.631,0,0,0,19.21-1.228a1.631,1.631,0,0,0-1.867-1.824H12.558a3.047,3.047,0,0,1-2.232-.762,3.109,3.109,0,0,1-.773-2.307V-10.8a1.631,1.631,0,0,0-1.845-1.845A1.622,1.622,0,0,0,5.884-10.8v4.936A6.491,6.491,0,0,0,7.536-1.024,6.685,6.685,0,0,0,12.407.6ZM34.425.6H39.36a6.652,6.652,0,0,0,4.882-1.631,6.523,6.523,0,0,0,1.642-4.828V-10.8a1.622,1.622,0,0,0-1.824-1.845A1.622,1.622,0,0,0,42.236-10.8v4.678a3.058,3.058,0,0,1-.794,2.307,3.093,3.093,0,0,1-2.232.762H34.425a1.622,1.622,0,0,0-1.845,1.824A1.622,1.622,0,0,0,34.425.6ZM14.811-17.087l8.734.021.021,8.584a2.513,2.513,0,0,0,.494,1.674,1.758,1.758,0,0,0,1.416.579,2.293,2.293,0,0,0,2.082-1.652L36.42-27.022a3.551,3.551,0,0,0,.386-1.438,1.8,1.8,0,0,0-.526-1.309,1.8,1.8,0,0,0-1.341-.536,3.411,3.411,0,0,0-1.416.386L14.231-21.014a2.744,2.744,0,0,0-1.127.869A1.934,1.934,0,0,0,12.729-19a1.828,1.828,0,0,0,.536,1.416A2.2,2.2,0,0,0,14.811-17.087Zm3.3-2.725,15.193-7-7,15.236.043-6.953a1.305,1.305,0,0,0-.365-.966A1.334,1.334,0,0,0,25-19.855Z"
                        transform="translate(-5.884 39.404)"
                        fill="#b6b6b6"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("address_line _2_placeholder")}
                    className="form-control grey-input"
                    name="addressLine2"
                    error={errors?.addressLine2}
                    disabled={disable}
                  />
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 40 40"
                    >
                      <path
                        id="Path_8425"
                        data-name="Path 8425"
                        d="M7.708-26.142a1.631,1.631,0,0,0,1.845-1.845v-4.678a3.109,3.109,0,0,1,.773-2.307,3.047,3.047,0,0,1,2.232-.762h4.785a1.631,1.631,0,0,0,1.867-1.824A1.641,1.641,0,0,0,17.343-39.4H12.407a6.658,6.658,0,0,0-4.871,1.631,6.5,6.5,0,0,0-1.652,4.828v4.957A1.622,1.622,0,0,0,7.708-26.142Zm36.352,0a1.622,1.622,0,0,0,1.824-1.845v-4.957a6.523,6.523,0,0,0-1.642-4.828A6.652,6.652,0,0,0,39.36-39.4H34.425a1.631,1.631,0,0,0-1.845,1.845,1.622,1.622,0,0,0,1.845,1.824H39.21a3.093,3.093,0,0,1,2.232.762,3.058,3.058,0,0,1,.794,2.307v4.678A1.622,1.622,0,0,0,44.06-26.142ZM12.407.6h4.936A1.631,1.631,0,0,0,19.21-1.228a1.631,1.631,0,0,0-1.867-1.824H12.558a3.047,3.047,0,0,1-2.232-.762,3.109,3.109,0,0,1-.773-2.307V-10.8a1.631,1.631,0,0,0-1.845-1.845A1.622,1.622,0,0,0,5.884-10.8v4.936A6.491,6.491,0,0,0,7.536-1.024,6.685,6.685,0,0,0,12.407.6ZM34.425.6H39.36a6.652,6.652,0,0,0,4.882-1.631,6.523,6.523,0,0,0,1.642-4.828V-10.8a1.622,1.622,0,0,0-1.824-1.845A1.622,1.622,0,0,0,42.236-10.8v4.678a3.058,3.058,0,0,1-.794,2.307,3.093,3.093,0,0,1-2.232.762H34.425a1.622,1.622,0,0,0-1.845,1.824A1.622,1.622,0,0,0,34.425.6ZM14.811-17.087l8.734.021.021,8.584a2.513,2.513,0,0,0,.494,1.674,1.758,1.758,0,0,0,1.416.579,2.293,2.293,0,0,0,2.082-1.652L36.42-27.022a3.551,3.551,0,0,0,.386-1.438,1.8,1.8,0,0,0-.526-1.309,1.8,1.8,0,0,0-1.341-.536,3.411,3.411,0,0,0-1.416.386L14.231-21.014a2.744,2.744,0,0,0-1.127.869A1.934,1.934,0,0,0,12.729-19a1.828,1.828,0,0,0,.536,1.416A2.2,2.2,0,0,0,14.811-17.087Zm3.3-2.725,15.193-7-7,15.236.043-6.953a1.305,1.305,0,0,0-.365-.966A1.334,1.334,0,0,0,25-19.855Z"
                        transform="translate(-5.884 39.404)"
                        fill="#b6b6b6"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("town/city_placeholder")}
                    className="form-control grey-input"
                    name="city"
                    error={errors?.city}
                    disabled={disable}
                  />
                  <span>
                    <img src={location} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("zip_code_placeholder")}
                    className="form-control grey-input"
                    name="zip"
                    error={errors?.zip}
                    fieldType="input"
                    value={propertyAddress?.zip}
                    disabled={disable}
                  />
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 25 40"
                    >
                      <g
                        id="location_1_"
                        data-name="location (1)"
                        transform="translate(-95.898)"
                      >
                        <path
                          id="Path_8346"
                          data-name="Path 8346"
                          d="M178.634,90.488a6.538,6.538,0,1,0-6.566-6.538A6.559,6.559,0,0,0,178.634,90.488Zm0-10.738a4.2,4.2,0,1,1-4.219,4.2A4.215,4.215,0,0,1,178.634,79.75Z"
                          transform="translate(-70.236 -71.357)"
                          fill="#b6b6b6"
                        />
                        <path
                          id="Path_8347"
                          data-name="Path 8347"
                          d="M101.263,24.4c1.775,2.406,1.062,1.47,6.175,8.778a1.171,1.171,0,0,0,1.92,0c5.136-7.343,4.429-6.413,6.175-8.779a29.841,29.841,0,0,0,4.6-7.782A12.124,12.124,0,0,0,118.368,4.9h0a12.593,12.593,0,0,0-19.939,0,12.124,12.124,0,0,0-1.765,11.717A29.837,29.837,0,0,0,101.263,24.4Zm-.982-18.064a10.252,10.252,0,0,1,16.235,0h0a9.845,9.845,0,0,1,1.4,9.517,28.1,28.1,0,0,1-4.27,7.154c-1.3,1.756-.908,1.219-5.251,7.452-4.339-6.227-3.956-5.7-5.251-7.452a28.1,28.1,0,0,1-4.27-7.154,9.845,9.845,0,0,1,1.4-9.517Z"
                          transform="translate(0 0)"
                          fill="#b6b6b6"
                        />
                        <path
                          id="Path_8348"
                          data-name="Path 8348"
                          d="M112.1,370.721a1.173,1.173,0,0,0-1.616-.364l-3.33,2.1a1.167,1.167,0,0,0,0,1.976l10.495,6.613a1.174,1.174,0,0,0,1.251,0l10.495-6.613a1.167,1.167,0,0,0,0-1.976l-3.33-2.1a1.169,1.169,0,1,0-1.251,1.976l1.763,1.111-8.3,5.231-8.3-5.231,1.763-1.111A1.167,1.167,0,0,0,112.1,370.721Z"
                          transform="translate(-9.876 -341.225)"
                          fill="#b6b6b6"
                        />
                      </g>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("state_placeholder")}
                    className="form-control grey-input"
                    name="state"
                    error={errors?.state}
                    fieldType="input"
                    disabled={disable}
                  />
                  <span>
                    <img src={map} alt="" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="form-group">
                <div className="left-icon-input">
                  <CommonTextBox
                    control={control}
                    type="text"
                    placeHolder={translation("country_placeholder")}
                    className="form-control grey-input"
                    name="country"
                    error={errors?.country}
                    fieldType="input"
                    disabled={disable}
                  />
                  <span>
                    <img src={map} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div className="white-card mt-3">
        <CommonUpdatePassword
          getUpdatePassword={onUpdatePassword}
          loading={passwordLoading}
        />
      </div>
    </div>
  );
};

export default MyProfile;
