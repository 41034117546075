import React, { useCallback, useEffect } from "react";

import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { matchToken } from "../services/auth";
// import { isExpired } from "react-jwt";

import { IAuthReducerData } from "../interfaces/AuthInterface";

import { RootState } from "../redux/reducers/Index";

import storage from "../utils/storage";
import ActionType from "../resources/enums";
import { IResidentInfo } from "../interfaces/ResidentInterface";
// import toast from "../utils/toastMessage";
// import { useTranslation } from "react-i18next";

/**
 * Private routes are only visible for login users
 * */
interface Props {
  component: React.ComponentType;
}
const PrivateRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const token: string = storage.get("__RTA__");
  // const { t: translation } = useTranslation();

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (token) {
  //     sessionExpire();
  //   }
  // }, [isExpired(token)]);

  // const sessionExpire = async () => {
  //   console.log("enter111");

  //   if (window.location.pathname != ROUTES.LOGIN) {
  //     const isMyTokenExpired = await isExpired(token);
  //     if (isMyTokenExpired) {
  //       localStorage.removeItem("token");
  //       storage.removeAll();
  //       dispatch({
  //         type: ActionType.LOGOUT,
  //       });
  //       toast.error(translation("token-expired"));
  //     }
  //   }
  // };

  useEffect(() => {
    if (authReducer && authReducer.isLoggedIn) {
      (async () => {
        await authCheck();
      })();
    }
  }, []);

  /**
   * Making request to match access token
   */

  const authCheck = useCallback(async () => {
    const { data } = await matchToken();

    if (data) {
      if (authReducer.authData.account_type === "Resident") {
        const residentInfo = data?.data?.[0]?.residentInfo.filter(
          (item: IResidentInfo) => item.unit_id
        );
        if (
          (!storage.get("unitId") && residentInfo.length > 0) ||
          (storage.get("unitId") &&
            storage.get("unitId") !== residentInfo[0]?.unit_id)
        ) {
          localStorage.removeItem("token");
          dispatch({
            type: ActionType.LOGOUT,
          });
        } else {
          //
        }
      }
      if (
        data?.success &&
        (data.data.is_active === 1 || data?.data?.[0]?.is_active === 1)
      ) {
        // setAuthState(data?.data);
        // storage.set("__SFA__", JSON.stringify(data?.data));
        // dispatch(updateAuth(data));
      } else {
        localStorage.removeItem("token");
        dispatch({
          type: ActionType.LOGOUT,
        });
      }
    } else {
      localStorage.removeItem("token");
      dispatch({
        type: ActionType.LOGOUT,
      });
    }
  }, []);

  if (token) {
    return <RouteComponent />;
  }

  return <Navigate to="/login" />;
};

export default PrivateRoutes;
