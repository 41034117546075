export const residentRole = [
  { key: "Guest", value: "Guest" },
  { key: "Resident", value: "Resident" },
];

export const NewLineRegex = /\r?\n/g;
export const locationLimit = 10;
export const apiKeysLimit = 50;
export const urlCsv =
  "https://addresident.s3.amazonaws.com/IOSync+Bulk+Resident+Upload.csv";
export const urlCsvForResidentUploadOnPropertyLevel =
  "https://addresident.s3.amazonaws.com/IOSyn.csv";

export const Action = [
  // { key: "All", value: "ALL" },
  { key: "Pending", value: "PENDING" },
  { key: "Approved", value: "APPROVED" },
  { key: "Denied", value: "DENIED" },
];

export const selectStatus = [
  { key: "All", value: "ALL" },
  { key: "Pending", value: "PENDING" },
  { key: "Approved", value: "APPROVED" },
  { key: "Denied", value: "DENIED" },
];

export const selectUnitStatus = [
  { key: "All", value: "ALL" },
  { key: "OFFLINE", value: "OFFLINE" },
  { key: "ONLINE", value: "ONLINE" },
  { key: "Not Available", value: "NOT-AVAILABLE" },
];

export const Status = [
  { key: "All", value: "Status: All" },
  { key: "Pending", value: "PENDING" },
  { key: "Resolved", value: "RESOLVED" },
  { key: "In Progress", value: "IN-PROGRESS" },
];
export const StatusType = [
  { key: "Pending", value: "PENDING" },
  { key: "Resolved", value: "RESOLVED" },
  { key: "In Progress", value: "IN-PROGRESS" },
];
export const dateSortingOption = [
  { key: "1", value: "Date: Old to New" },
  { key: "-1", value: "Date: New to Old" },
];
export const roleAccess = {
  full_access: "Full Access",
  limited_access: "Limited Access",
};
export const deviceStatus = {
  off: "off",
  on: "on",
  lock: "lock",
  unlock: "unlock",
  auto: "auto",
  cool: "cool",
  heat: "heat",
  circulate: "circulate",
};
export const extension = {
  jpeg: "jpeg",
  jpg: "jpg",
  png: "png",
};

export const thermostatStatus = {
  cool: "Cooling",
  heat: "Heating",
  auto: "Auto",
  off: "Off",
};
export const csvFormat = [
  "first_name",
  "last_name",
  "email",
  "units",
  "phone_number",
  "date",
  "role",
];

export const thermostatsControlType = {
  thermostatMode: {
    command: "thermostatMode",
    capability: "thermostatMode",
  },
  thermostatFanMode: {
    command: "setThermostatFanMode",
    capability: "thermostatFanMode",
  },
  thermostatHeatingSetpoint: {
    command: "setHeatingSetpoint",
    capability: "thermostatHeatingSetpoint",
  },
  thermostatCoolingSetpoint: {
    command: "setCoolingSetpoint",
    capability: "thermostatCoolingSetpoint",
  },
};

export const userType = [
  { key: "Staff", value: "Staff" },
  { key: "Resident", value: "Resident" },
  { key: "Both", value: "Both(Staff & Resident)" },
];
export const attachmentType = {
  mp4: "mp4",
  mp3: "mp3",
  xlsx: "xlsx",
  docx: "docx",
  csv: "csv",
  webm: "webm",
  png: "png",
  jpeg: "jpeg",
  jpg: "jpg",
  pdf: "pdf",
};
export const checkBoxType = {
  buildingCheckBox: "buildingCheckBox",
  allCheckBox: "allCheckBox",
};

export const maxFileSize = 20 * 1024 * 1024;

export const supportMail = "support@iosync.com";
export const admin = "Admin";
export const company = "Company";
export const resident = "Resident";
export const staff = "Staff";
export const user = "User";
export const locked = "locked";
export const Locked = "Locked";
export const Unlocked = "Unlocked";
export const unlock = "unlock";
export const lock = "lock";
export const switchType = "switch";
export const manager = "Property Manager";
export const leasingStaff = "Leasing Staff";
export const n_format = "N";
export const nn_format = "NN";
export const nnn_format = "NNN";
export const none_format = "None";
export const endRange = "endRange";
export const startRange = "startRange";
export const vacantUnitConstant = "Vacant";
export const thermostat = "thermostat";
export const arrowUp = "ArrowUp";
export const arrowDown = "ArrowDown";
export const approved = "Approved";
export const denied = "Denied";
export const pending = "Pending";
export const inprogress = "In Progress";
export const resolved = "Resolved";
export const maintenance = "Maintenance";
export const setCoolingSetpoint = "setCoolingSetpoint";
export const setHeatingSetpoint = "setCoolingSetpoint";
export const Cool = "COOL";
export const Heat = "HEAT";
export const Off = "OFF";
export const Auto = "AUTO";
export const buildingLimitForInfiniteScroll = 12;
export const online = "ONLINE";
export const privacyPolicyUrl = "https://iosync.com/privacy-policy/";
export const termsAndConditionUrl = "https://iosync.com/terms-of-service/";
export const switchLevel = "switchLevel";
export const setLevel = "setLevel";
export const setColorTemperature = "setColorTemperature";
// export const colorTemperature = "colorTemperature";
// export const colorControl = "colorControl";
export const setcolorControl = "setColor";

export const switchControls = {
  dimmer: "dimmer",
  colorControl: "colorControl",
  colorTemperature: "colorTemperature",
};
