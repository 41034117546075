import React from "react";
import { useTranslation } from "react-i18next";
import battery1_19 from "../../assets/images/battery1_19.svg";
import battery20 from "../../assets/images/battery_20.svg";
import battery30 from "../../assets/images/battery_30.svg";
import battery40 from "../../assets/images/battery_40.svg";
import battery50 from "../../assets/images/battery_50.svg";
import battery60 from "../../assets/images/battery_60.svg";
import battery70 from "../../assets/images/battery_70.svg";
import battery80 from "../../assets/images/battery_80.svg";
import battery90 from "../../assets/images/battery_90.svg";
import battery100 from "../../assets/images/battery_100.svg";
import { IDevice } from "../../interfaces/CommonInterface";

type State = {
  unitData: IDevice;
};

interface IProps {
  UnitDeatisData: State;
}

const WaterSensor = (props: IProps) => {
  const { UnitDeatisData } = props;
  const { t: translation } = useTranslation();
  return (
    <div className="col-md-6">
      <div className="white-card h-100  text-start mb-3 d-flex justify-content-around flex-column">
        {UnitDeatisData &&
         UnitDeatisData?.unitData &&
        UnitDeatisData?.unitData?.waterSensor &&
        UnitDeatisData.unitData.waterSensor?.length ? (
          <>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-left">
                {UnitDeatisData &&
                UnitDeatisData.unitData.waterSensor &&
                UnitDeatisData.unitData.waterSensor[0] &&
                UnitDeatisData.unitData.waterSensor[0]?.latestReading
                  .WaterBatteryValue ? (
                  UnitDeatisData.unitData.waterSensor[0]?.latestReading
                    .WaterBatteryValue < 20 ? (
                    <img className="me-2" src={battery1_19} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 30 ? (
                    <img className="me-2" src={battery20} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 40 ? (
                    <img className="me-2" src={battery30} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 50 ? (
                    <img className="me-2" src={battery40} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 60 ? (
                    <img className="me-2" src={battery50} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 70 ? (
                    <img className="me-2" src={battery60} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 80 ? (
                    <img className="me-2" src={battery70} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 90 ? (
                    <img className="me-2" src={battery80} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterBatteryValue < 100 ? (
                    <img className="me-2" src={battery90} alt="" />
                  ) : UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .contactSensorBattery === 100 ? (
                    <img className="me-2" src={battery100} alt="" />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {UnitDeatisData &&
                  UnitDeatisData?.unitData?.waterSensor &&
                  UnitDeatisData?.unitData?.waterSensor[0] &&
                  UnitDeatisData?.unitData.waterSensor[0]?.latestReading
                    .WaterBatteryValue}
                % &nbsp;
                <p className="op-3 ">
                  {UnitDeatisData &&
                    UnitDeatisData.unitData.waterSensor &&
                    UnitDeatisData.unitData.waterSensor[0]?.label}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="contact-sensor-unit">
                <h4>{translation("water_sensor")}</h4>
                <p>
                  {UnitDeatisData &&
                    UnitDeatisData.unitData.waterSensor &&
                    UnitDeatisData.unitData.waterSensor[0]?.latestReading.water}
                </p>
              </div>
              <div className="contact-sensor-unit">
                <h4>{translation("tempreature")}</h4>
                <p>
                  {UnitDeatisData &&
                    UnitDeatisData.unitData.waterSensor &&
                    UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterTemperature}{" "}
                  {
                    UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .WaterTemperatureUnit
                  }
                </p>
              </div>
              <div className="contact-sensor-unit">
                <h4>{translation("tamper_alert")}</h4>
                <p>
                  {UnitDeatisData &&
                    UnitDeatisData.unitData.waterSensor &&
                    UnitDeatisData.unitData.waterSensor[0]?.latestReading
                      .waterTamperAlert}
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="danger-color">
            {translation("no_waterLeakSensor_device_found")}
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterSensor;
