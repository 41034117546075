/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { HuePicker } from "react-color";
import { debounce } from "lodash";

import { getunitDetailData } from "../../services/unit";
import { deviceReportHub, controlDevice } from "../../services/device";

import {
  chartWeekDaysFormat,
  IDevice,
  IDeviceReading,
} from "../../interfaces/CommonInterface";
import {
  IChangeDeviceStatus,
  IOnlineChartData,
  IUnitDetailBreadCrumbs,
  IUnitDetailData,
  IUnitID,
} from "../../interfaces/UnitInterface";
import { IAuthReducerData } from "../../interfaces/AuthInterface";
import { IDeviceStatusCounting } from "../../interfaces/PropertyInterface";

import {
  admin,
  company,
  deviceStatus,
  lock,
  Locked,
  locked,
  manager,
  resident,
  setcolorControl,
  setColorTemperature,
  setCoolingSetpoint,
  setHeatingSetpoint,
  setLevel,
  switchControls,
  switchLevel,
  switchType,
  thermostatsControlType,
  thermostatStatus,
  Unlocked,
  vacantUnitConstant,
} from "../../constants/commonConstants";
import { RootState } from "../../redux/reducers/Index";

import Header from "../../components/header/Header";
import ROUTES from "../../constants/routes";
import storage from "../../utils/storage";
import Loading from "../../components/loader/Loader";
import toast from "../../utils/toastMessage";

import refresh from "../../assets/images/refresh.svg";
import stat from "../../assets/images/status.svg";
import device from "../../assets/images/device.svg";
import thermostat from "../../assets/images/thermostat.svg";
import battery1_19 from "../../assets/images/battery1_19.svg";
import battery20 from "../../assets/images/battery_20.svg";
import battery30 from "../../assets/images/battery_30.svg";
import battery40 from "../../assets/images/battery_40.svg";
import battery50 from "../../assets/images/battery_50.svg";
import battery60 from "../../assets/images/battery_60.svg";
import battery70 from "../../assets/images/battery_70.svg";
import battery80 from "../../assets/images/battery_80.svg";
import battery90 from "../../assets/images/battery_90.svg";
import battery100 from "../../assets/images/battery_100.svg";
import dimIcon from "../../assets/images/dim-icon.svg";
import brightIcon from "../../assets/images/bright-icon.svg";
import online_support from "../../assets/images/online_support.svg";
import ContactSensor from "./ContactSensor";
import WaterSensor from "./WaterSensor";

type State = {
  unitData: IDevice;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UnitNo: React.FC = () => {
  const [unitDetailData, setUnitList] = useState<State>({
    unitData: {
      switch: [],
      door: [],
      thermoStat: [],
      waterSensor: [],
      contactSensor: [],
    },
  });

  const { t: translation } = useTranslation();
  const location = useLocation();
  const bredcrumbs = location.state as IUnitDetailBreadCrumbs;

  const [unitUnassigned, setUnitUnassigned] = useState<boolean>(false);
  const [temperatureValue, setTemperatureValue] = useState<number>();
  const [dimmerValue, setDimmerValue] = useState<any>({});
  const [colorTempratureValue, setColorTempratureValue] = useState<any>({});
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [hslColor, setHslColor] = useState<any>({});
  useEffect(() => {
    if (
      authReducer.authData.account_type === resident &&
      !storage.get("unitId")
    ) {
      setUnitUnassigned(true);
    } else {
      getunitDetail({
        propertyId: storage.get("propertyId"),
        unitId: bredcrumbs?.unitId ? bredcrumbs?.unitId : storage.get("unitId"),
      });
      setUnitId({
        unitId: bredcrumbs?.unitId,
        unitName: bredcrumbs?.unitName,
      });

      getDeviceReports();
    }
    if (
      authReducer.authData.account_type !== resident &&
      bredcrumbs?.unitStatus !== vacantUnitConstant
    ) {
      setButtonDisable(true);
    }
  }, []);
  const labels: string[] = [];
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * refresh the page
   */
  const refreshPage = async () => {
    setLoading(true);
    getunitDetail({
      propertyId: storage.get("propertyId"),
      unitId: bredcrumbs?.unitId ? bredcrumbs?.unitId : storage.get("unitId"),
    });
    setUnitId({
      unitId: bredcrumbs?.unitId,
      unitName: bredcrumbs?.unitName,
    });

    getDeviceReports();
    setLoading(false);
  };

  /**
   * Get Unit detail
   */
  const getunitDetail = async (data: IUnitDetailData) => {
    setLoading(true);
    const unitDetails = await getunitDetailData(data);
    if (unitDetails?.data?.success) {
      setLoading(false);

      setUnitList({
        unitData: unitDetails.data.data,
      });
      if (
        unitDetails.data.data.thermoStat &&
        unitDetails.data.data.thermoStat?.length > 0 &&
        (unitDetails.data.data.thermoStat[0].latestReading.thermostatMode ===
          deviceStatus.cool ||
          unitDetails.data.data.thermoStat[0].latestReading.thermostatMode ===
            deviceStatus.heat)
      ) {
        setTemperatureValue(
          unitDetails.data.data.thermoStat[0].latestReading.thermostatMode ===
            deviceStatus.cool
            ? unitDetails.data.data.thermoStat[0].latestReading.coolingPoint
            : unitDetails.data.data.thermoStat[0].latestReading.heatingPoint
        );
        setLoading(false);
      }
    }
    setButtonDisable(false);
    setLoading(false);
  };

  const [unitId, setUnitId] = useState<IUnitID>();

  const authReducer: IAuthReducerData = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  const [onlineChartData, seOnlineChartData] = useState<IOnlineChartData>({
    labels: [],
    datasets: [
      {
        data: [],
        tension: 0.4,
        fill: false,
      },
    ],
  });
  useEffect(() => {
    getDeviceReports();
  }, [unitId?.unitId]);

  const daysOfWeekData: chartWeekDaysFormat = [
    { Mon: 1 },
    { Tue: 2 },
    { Wed: 3 },
    { Thr: 4 },
    { Fri: 5 },
    { Sat: 6 },
    { Sun: 7 },
  ];

  /**
   *  get device offline and online report
   */
  const getDeviceReports = async () => {
    if (unitId?.unitId) {
      const reponseData = await deviceReportHub(unitId?.unitId);
      if (reponseData?.data?.success) {
        /**
         *  dynamic label
         */

        for (let i = new Date().getDay(); i < daysOfWeekData.length; ) {
          labels.push(Object.keys(daysOfWeekData[i])[0]);
          i += 1;
        }
        for (let i = 0; i < new Date().getDay(); ) {
          labels.push(Object.keys(daysOfWeekData[i])[0]);
          i += 1;
        }
        const dataForOnlineStatusChart: number[] = [];
        if (reponseData?.data?.success) {
          const lastSevenDaysDateForOnlineStatus: string[] = [];
          const lastSevenDaysDateForOfflineStatus: string[] = [];
          reponseData?.data?.data?.hubsOnline.forEach(
            (e: IDeviceStatusCounting) => {
              lastSevenDaysDateForOnlineStatus.push(
                new Date(e._id).toISOString().split("T")[0]
              );
            }
          );
          reponseData?.data?.data?.hubsOffline.forEach(
            (e: IDeviceStatusCounting) => {
              lastSevenDaysDateForOfflineStatus.push(
                new Date(e._id).toISOString().split("T")[0]
              );
            }
          );
          if (lastSevenDaysDateForOnlineStatus.length < 7) {
            for (let i = 0; i < 7; ) {
              if (
                lastSevenDaysDateForOnlineStatus.includes(
                  new Date(new Date().setDate(new Date().getDate() - 6 + i))
                    .toISOString()
                    .split("T")[0]
                )
              ) {
                reponseData?.data?.data?.hubsOnline.forEach(
                  (e: IDeviceStatusCounting) => {
                    if (
                      new Date(e._id).toISOString().split("T")[0] ===
                      new Date(new Date().setDate(new Date().getDate() - 6 + i))
                        .toISOString()
                        .split("T")[0]
                    ) {
                      dataForOnlineStatusChart.push(e.count);
                    }
                  }
                );
              } else {
                dataForOnlineStatusChart.push(0);
              }
              i += 1;
            }
          } else {
            reponseData?.data?.data?.hubsOnline.forEach(
              (e: IDeviceStatusCounting) => {
                dataForOnlineStatusChart.push(e.count);
              }
            );
          }
        }

        const datasetsForOnline = [
          {
            fill: false,
            data: dataForOnlineStatusChart,
            borderColor: "rgb(17, 237, 35)",
            backgroundColor: "rgba(27, 213, 58, 0.9)",
            tension: 0.4,
          },
        ];
        seOnlineChartData({
          ...onlineChartData,
          labels,
          datasets: datasetsForOnline,
        });
      }
    }
  };

  /**
   *  options for graph
   */
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        display: true,
        ticks: {
          color: " #ffffff",
        },
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  /**
   *  on the device
   */
  const deviceControl = async (info: IChangeDeviceStatus) => {
    setButtonDisable(true);
    if (
      authReducer.authData.account_type === resident ||
      bredcrumbs?.residentId ||
      ((authReducer.authData.account_type === admin ||
        authReducer.authData.account_type === company) &&
        bredcrumbs?.unitStatus === vacantUnitConstant)
    ) {
      let applySetting = true;
      if (
        info.status === setCoolingSetpoint &&
        (Number(info.arguments) < 65 || Number(info.arguments) > 92)
      ) {
        applySetting = false;
      }
      if (
        info.status === setHeatingSetpoint &&
        (Number(info.arguments) < 45 || Number(info.arguments) > 79)
      ) {
        applySetting = false;
      }
      if (applySetting) {
        setLoading(true);

        const params: IChangeDeviceStatus = {
          status: info.status,
          deviceId: info.deviceId,
          roomId: info.roomId,
          deviceType: info.deviceType,
          arguments: info.arguments,
          propertyId: storage.get("propertyId"),
        };
        const responseData = await controlDevice(params);
        if (responseData?.data?.success) {
          setTimeout(() => {
            getunitDetail({
              propertyId: storage.get("propertyId"),
              unitId: bredcrumbs?.unitId
                ? bredcrumbs?.unitId
                : storage.get("unitId"),
            });
            setUnitId({
              unitId: bredcrumbs?.unitId,
              unitName: bredcrumbs?.unitName,
            });
            getDeviceReports();
          }, 3000);
          toast.success(translation(`${responseData?.data?.message}`));
        } else {
          toast.error(translation(`${responseData?.data?.message}`));
        }
        setLoading(false);
      } else {
        toast.error(translation(`temp_range_${info.status}_mode`));
      }
    }
    // }
    // setButtonDisable(false);
  };

  /**
   *  debounce on temperature change
   */
  const handleTemperatureChange = useCallback(
    debounce(async (data: IChangeDeviceStatus) => {
      deviceControl(data);
    }, 500),
    []
  );

  const handleDimmer = useCallback(
    debounce(async (data) => {
      setButtonDisable(true);
      await deviceControl(data);
    }, 500),
    []
  );

  const handleSwitchControls = async (
    event: ChangeEvent<HTMLInputElement>,
    key: number,
    ele: IDeviceReading,
    type: string
  ) => {
    let controlData = {};
    if (type === switchControls.dimmer) {
      setDimmerValue((prev: any) => ({
        ...prev,
        [key]: event.target.value,
      }));
      controlData = {
        status: setLevel,
        deviceType: switchLevel,
        deviceId: ele.deviceId,
        roomId: ele.roomId,
        arguments: Number(event.target.value),
      };
      await handleDimmer(controlData);
    } else {
      controlData = {
        status: setColorTemperature,
        deviceType: switchControls.colorTemperature,
        deviceId: ele.deviceId,
        roomId: ele.roomId,
        arguments: Number(event.target.value),
      };
      setColorTempratureValue((prev: any) => ({
        ...prev,
        [key]: event.target.value,
      }));

      await handleDimmer(controlData);
    }
  };

  const HandleColorChnage = async (event: any, key: number, ele: any) => {
    const { h, s, l } = event;
    const hue = Math.round((h / 360) * 100);
    const saturation = Math.round(s * 100);
    const level = Math.round(l * 100);
    const colorControlData = {
      status: setcolorControl,
      deviceType: switchControls.colorControl,
      deviceId: ele.deviceId,
      roomId: ele.roomId,
      arguments: { hue, saturation, level },
    };
    setHslColor((prev: any) => ({
      ...prev,
      [key]: event,
    }));

    await handleDimmer(colorControlData);
  };

  return (
    <div className="p-4">
      {authReducer.authData.account_type === resident ? (
        <Header
          parentComponent={{
            route: ROUTES.Resident.RESIDENT_DASHBOARD,
            name: translation("room"),
          }}
        />
      ) : authReducer.roleType === manager ? (
        <Header
          parentComponent={{
            route: bredcrumbs?.parentComponent?.route,
            name: bredcrumbs?.parentComponent?.name,

            homeRoute: ROUTES.DASHBOARD,
          }}
          childComponent={{
            route: bredcrumbs?.childComponent?.route,
            name: bredcrumbs?.childComponent?.name,
          }}
        />
      ) : (
        <Header
          parentComponent={{
            route: bredcrumbs?.parentComponent?.route,
            name: bredcrumbs?.parentComponent?.name,
            homeName: translation("all_buildings"),
            homeRoute: ROUTES.ALL_PROPETIES,
          }}
          childComponent={{
            route: bredcrumbs?.childComponent?.route,
            name: bredcrumbs?.childComponent?.name,
          }}
        />
      )}
      {loading && (
        <div className="item-center">
          {" "}
          <Loading />{" "}
        </div>
      )}
      {unitUnassigned ? (
        <div className="please-wait">
          <div className="please-wait-inner">
            <img src={online_support} alt="" />
            <p>
              {translation("hello")} {authReducer?.authData?.first_name}{" "}
              {authReducer?.authData?.last_name}{" "}
              {translation("unit_unassigned_resident_message")}
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className="theme-color fw-bold me-2">
            {bredcrumbs?.childComponent?.name}
          </h2>
          {!unitUnassigned && (
            <img
              src={refresh}
              alt=""
              onClick={() => refreshPage()}
              className="point"
            />
          )}
        </div>
        <Link to={ROUTES.UNITS}>
          {" "}
          {authReducer?.authData?.account_type === admin ||
          authReducer?.authData?.account_type === company ? (
            <button className="theme-button primary-btn" type="button">
              {translation("back")}
            </button>
          ) : (
            ""
          )}
        </Link>
      </div>

      {unitDetailData.unitData &&
      (unitDetailData.unitData.door?.length > 0 ||
        unitDetailData.unitData.switch?.length > 0 ||
        unitDetailData.unitData.thermoStat?.length > 0) ? (
        <>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="status-table">
                <div className="head">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="d-flex">
                        <img src={device} className="me-2" alt="" />
                        {translation("device")}
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="d-flex">
                        <img src={stat} className="me-2" alt="" />
                        {translation("status")}
                      </div>
                    </div>
                  </div>
                </div>
                {unitDetailData.unitData &&
                unitDetailData.unitData.switch.length > 0 ? (
                  <div className="table-bottom">
                    {unitDetailData &&
                    unitDetailData.unitData.switch &&
                    unitDetailData.unitData.switch.length > 0 ? (
                      unitDetailData.unitData.switch.map(
                        (ele: IDeviceReading, key) => (
                          <div className="row bd-bottom mb-2 align-items-center">
                            <div className="col-md-3">
                              <p className="fw-bold">{ele.label}</p>
                              <p className="op-6">{ele.name}</p>
                            </div>
                            <div className="col-md-1">
                              <p className="fw-bold">
                                {translation(`${ele.latestReading.switch}`)}{" "}
                              </p>
                            </div>
                            <div className="col-md-6">
                              {authReducer?.authData?.account_type !==
                                resident &&
                              bredcrumbs?.unitStatus !== vacantUnitConstant
                                ? null
                                : ele.latestReading.switch ===
                                    deviceStatus.on && (
                                    <div className="rangebar-box mt-0">
                                      <div className="light-status-icon bright-icon">
                                        <label htmlFor="dimmer">
                                          {translation("dimmer_control")}
                                        </label>
                                      </div>
                                      <div className="light-control-range mt-0">
                                        <div className="light-status-icon dim-icon">
                                          <img src={dimIcon} alt="dimIcon" />
                                        </div>

                                        <div className="light-status-icon bright-icon">
                                          <img
                                            src={brightIcon}
                                            alt="brightIcon"
                                          />
                                        </div>

                                        <div className="range-slider">
                                          <input
                                            className="custom-range"
                                            type="range"
                                            id="togBtn"
                                            min={1}
                                            max={100}
                                            value={
                                              dimmerValue[key]
                                                ? dimmerValue[key]
                                                : ele?.latestReading?.dimmer
                                            }
                                            key={ele.deviceId}
                                            disabled={buttonDisable || loading}
                                            // name={"atma" + key}
                                            onChange={(event) =>
                                              handleSwitchControls(
                                                event,
                                                key,
                                                ele,
                                                switchControls.dimmer
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              {/* <label>Color Temp</label> */}

                              {authReducer?.authData?.account_type !==
                                resident &&
                              bredcrumbs?.unitStatus !== vacantUnitConstant
                                ? null
                                : ele.latestReading.colorControl === true &&
                                  ele.latestReading.switch ===
                                    deviceStatus.on && (
                                    <div className="rangebar-box">
                                      <div className="light-status-icon bright-icon">
                                        <label htmlFor="colorTemp">
                                          {translation("color_tempreatur")}
                                        </label>
                                      </div>
                                      <div className="light-control-range mt-0">
                                        <div className="light-status-icon dim-icon">
                                          <img src={dimIcon} alt="dimIcon" />
                                        </div>
                                        <div className="light-status-icon bright-icon">
                                          <img
                                            src={brightIcon}
                                            alt="brightIcon"
                                          />
                                        </div>

                                        <div className="range-slider">
                                          <input
                                            className="custom-range"
                                            type="range"
                                            id="togBtn"
                                            min={1800}
                                            max={6500}
                                            value={
                                              colorTempratureValue[key]
                                                ? colorTempratureValue[key]
                                                : ele?.latestReading
                                                    ?.colorTemperature
                                            }
                                            key={ele.deviceId}
                                            disabled={buttonDisable || loading}
                                            // name={"atmaa" + key}
                                            onChange={(event) =>
                                              handleSwitchControls(
                                                event,
                                                key,
                                                ele,
                                                switchControls.colorTemperature
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              {/* <label>Color Control</label> */}
                              {authReducer?.authData?.account_type !==
                                resident &&
                              bredcrumbs?.unitStatus !== vacantUnitConstant
                                ? null
                                : ele.latestReading.colorControl === true &&
                                  ele.latestReading.switch ===
                                    deviceStatus.on && (
                                    <div className="rangebar-box">
                                      <div className="light-status-icon bright-icon">
                                        <label htmlFor="colourControl">
                                          {translation("color_control")}
                                        </label>
                                      </div>
                                      <div className="light-control-range mt-0">
                                        <div className="light-status-icon dim-icon">
                                          <img src={dimIcon} alt="dimIcon" />
                                        </div>
                                        <div className="light-status-icon bright-icon">
                                          <img
                                            src={brightIcon}
                                            alt="brightIcon"
                                          />
                                        </div>

                                        <div className="range-slider">
                                          <HuePicker
                                            // disabled={loading}
                                            color={{
                                              h: hslColor[key]?.h
                                                ? hslColor[key]?.h
                                                : ((ele.latestReading
                                                    .colorHue as number) /
                                                    100) *
                                                  360,
                                              s: hslColor[key]?.s
                                                ? hslColor[key]?.s
                                                : (ele.latestReading
                                                    .colorSaturation as number) /
                                                  100,
                                              l: 100,
                                            }}
                                            onChangeComplete={(event) =>
                                              HandleColorChnage(
                                                event.hsl,
                                                key,
                                                ele
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                            </div>

                            <div className="col-md-2 text-end">
                              {authReducer?.authData?.account_type !==
                                resident &&
                              bredcrumbs?.unitStatus !== vacantUnitConstant ? (
                                ele.latestReading.switch === deviceStatus.on ? (
                                  <span className="custom-badge-on">
                                    {translation("on")}
                                  </span>
                                ) : (
                                  <span className="custom-badge-off">
                                    {translation("off")}
                                  </span>
                                )
                              ) : (
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    defaultChecked={
                                      ele.latestReading.switch ===
                                      deviceStatus.on
                                        ? true
                                        : false
                                    }
                                    id="togBtn"
                                    onChange={(event) => {
                                      deviceControl({
                                        status: event.target.checked
                                          ? deviceStatus.on
                                          : deviceStatus.off,
                                        deviceType: switchType,
                                        deviceId: ele.deviceId,
                                        roomId: ele.roomId,
                                      });
                                    }}
                                  />

                                  <div className="slider round" />
                                </label>
                              )}
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="danger-color">
                        {translation("no_switch_device_found")}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-3 g-3">
            <div className="col-md-9">
              <div className="status-table h-100">
                {unitDetailData.unitData &&
                unitDetailData.unitData.thermoStat.length > 0 ? (
                  <div className="table-bottom">
                    {unitDetailData &&
                    unitDetailData.unitData?.thermoStat &&
                    unitDetailData.unitData.thermoStat?.length > 0 ? (
                      <>
                        <div className="head">
                          {" "}
                          <img src={thermostat} className="me-2" alt="" />
                          {unitDetailData.unitData.thermoStat[0].name}
                        </div>
                        <div className="d-flex text-align-center justify-content-between ">
                          {" "}
                          <p className="fw-bold">
                            {unitDetailData.unitData.thermoStat[0].label}
                          </p>
                          <div
                            className={
                              authReducer.authData.account_type !== resident &&
                              bredcrumbs?.unitStatus !== vacantUnitConstant
                                ? "default"
                                : " "
                            }
                          >
                            <button
                              className={`me-2 ${
                                unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.off
                                  ? "theme-button primary-btn btn-xs"
                                  : "grey-btn-sm"
                              }`}
                              type="button"
                              onClick={() =>
                                deviceControl({
                                  status: deviceStatus.off,
                                  deviceType:
                                    thermostatsControlType.thermostatMode
                                      .capability,
                                  deviceId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.deviceId,

                                  roomId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.roomId,
                                })
                              }
                            >
                              {translation("off")}
                            </button>
                            <button
                              className={`me-2 ${
                                unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.auto
                                  ? "theme-button primary-btn btn-xs"
                                  : "grey-btn-sm"
                              }`}
                              type="button"
                              onClick={() =>
                                deviceControl({
                                  status: deviceStatus.auto,
                                  deviceType:
                                    thermostatsControlType.thermostatMode
                                      .capability,
                                  deviceId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.deviceId,
                                  roomId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.roomId,
                                })
                              }
                            >
                              {translation("Auto")}
                            </button>
                            <button
                              className={`me-2 ${
                                unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.heat
                                  ? "theme-button primary-btn btn-xs"
                                  : "grey-btn-sm"
                              }`}
                              type="button"
                              onClick={() =>
                                deviceControl({
                                  status: deviceStatus.heat,
                                  deviceType:
                                    thermostatsControlType.thermostatMode
                                      .capability,
                                  deviceId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.deviceId,
                                  roomId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.roomId,
                                })
                              }
                            >
                              {translation("heat")}
                            </button>
                            <button
                              className={`me-2 ${
                                unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.cool
                                  ? "theme-button primary-btn btn-xs "
                                  : "grey-btn-sm"
                              }`}
                              type="button"
                              onClick={() =>
                                deviceControl({
                                  status: deviceStatus.cool,
                                  deviceType:
                                    thermostatsControlType.thermostatMode
                                      .capability,
                                  deviceId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.deviceId,
                                  roomId:
                                    unitDetailData?.unitData.thermoStat &&
                                    unitDetailData?.unitData?.thermoStat[0] &&
                                    unitDetailData?.unitData?.thermoStat[0]
                                      ?.roomId,
                                })
                              }
                            >
                              {translation("cool")}
                            </button>
                          </div>
                          <div className="d-flex">
                            <p className="fw-bold me-2">Fan:</p>
                            <div
                              className={
                                authReducer.authData.account_type !==
                                  resident &&
                                bredcrumbs?.unitStatus !== vacantUnitConstant
                                  ? "form-check form-switch default"
                                  : " form-check form-switch"
                              }
                            >
                              <button
                                className={`me-2 ${
                                  unitDetailData.unitData.thermoStat[0]
                                    .latestReading.fanMode === deviceStatus.auto
                                    ? "theme-button primary-btn btn-xs"
                                    : "grey-btn-sm"
                                }`}
                                type="button"
                                onClick={() =>
                                  deviceControl({
                                    status:
                                      thermostatsControlType.thermostatFanMode
                                        .command,
                                    arguments: deviceStatus.auto,
                                    deviceType:
                                      thermostatsControlType.thermostatFanMode
                                        .capability,
                                    deviceId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.deviceId,
                                    roomId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.roomId,
                                  })
                                }
                              >
                                {translation("Auto")}
                              </button>
                              <button
                                className={`me-2 ${
                                  unitDetailData.unitData.thermoStat[0]
                                    .latestReading.fanMode === deviceStatus.on
                                    ? "theme-button primary-btn btn-xs"
                                    : "grey-btn-sm"
                                }`}
                                type="button"
                                onClick={() =>
                                  deviceControl({
                                    status:
                                      thermostatsControlType.thermostatFanMode
                                        .command,
                                    arguments: deviceStatus.on,
                                    deviceType:
                                      thermostatsControlType.thermostatFanMode
                                        .capability,
                                    deviceId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.deviceId,
                                    roomId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.roomId,
                                  })
                                }
                              >
                                {translation("on")}
                              </button>
                            </div>
                          </div>
                          {(unitDetailData.unitData.thermoStat[0].latestReading
                            .thermostatMode === deviceStatus.cool ||
                            unitDetailData.unitData.thermoStat[0].latestReading
                              .thermostatMode === deviceStatus.heat) && (
                            <div className="d-flex">
                              <button
                                className=" grey-btn-sm me-2"
                                onClick={() => {
                                  setTemperatureValue(
                                    temperatureValue && temperatureValue - 1
                                  );
                                  handleTemperatureChange.cancel();
                                  handleTemperatureChange({
                                    status:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint.command
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint.command,
                                    arguments:
                                      temperatureValue && temperatureValue - 1,
                                    deviceType:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint
                                            .capability
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint
                                            .capability,
                                    deviceId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.deviceId,
                                    roomId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.roomId,
                                  });
                                }}
                                type="button"
                                disabled={buttonDisable}
                              >
                                -
                              </button>
                              <input
                                className="grey-btn-sm px-2 me-2"
                                type="number"
                                value={temperatureValue}
                                disabled={buttonDisable}
                                onChange={(e) => {
                                  setTemperatureValue(Number(e.target.value));
                                  handleTemperatureChange.cancel();
                                  handleTemperatureChange({
                                    status:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint.command
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint.command,
                                    arguments: Number(e.target.value),
                                    deviceType:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint
                                            .capability
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint
                                            .capability,
                                    deviceId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.deviceId,
                                    roomId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.roomId,
                                  });
                                }}
                              />{" "}
                              °F &nbsp;
                              <button
                                className="grey-btn-sm"
                                onClick={() => {
                                  setTemperatureValue(
                                    temperatureValue && temperatureValue + 1
                                  );
                                  handleTemperatureChange.cancel();
                                  handleTemperatureChange({
                                    status:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint.command
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint.command,
                                    arguments:
                                      temperatureValue && temperatureValue + 1,
                                    deviceType:
                                      unitDetailData.unitData.thermoStat &&
                                      unitDetailData.unitData.thermoStat[0]
                                        .latestReading.thermostatMode ===
                                        deviceStatus.cool
                                        ? thermostatsControlType
                                            .thermostatCoolingSetpoint
                                            .capability
                                        : thermostatsControlType
                                            .thermostatHeatingSetpoint
                                            .capability,
                                    deviceId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.deviceId,
                                    roomId:
                                      unitDetailData?.unitData.thermoStat &&
                                      unitDetailData?.unitData?.thermoStat[0] &&
                                      unitDetailData?.unitData?.thermoStat[0]
                                        ?.roomId,
                                  });
                                }}
                                type="button"
                                disabled={buttonDisable}
                              >
                                +
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                          <div
                            className={
                              unitDetailData.unitData.thermoStat[0]
                                .latestReading.thermostatMode ===
                              deviceStatus.cool
                                ? "online sucess-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.heat
                                ? "offline danger-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.auto
                                ? "online sucess-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.off
                                ? "offline danger-color"
                                : ""
                            }
                          />
                          <span
                            className={
                              unitDetailData.unitData.thermoStat[0]
                                .latestReading.thermostatMode ===
                              deviceStatus.cool
                                ? "ms-2 sucess-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.heat
                                ? "ms-2 danger-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.auto
                                ? "ms-2 sucess-color"
                                : unitDetailData.unitData.thermoStat[0]
                                    .latestReading.thermostatMode ===
                                  deviceStatus.off
                                ? "ms-2 danger-color"
                                : ""
                            }
                          >
                            {unitDetailData.unitData.thermoStat[0].latestReading
                              .thermostatMode === deviceStatus.cool
                              ? thermostatStatus.cool
                              : unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.heat
                              ? thermostatStatus.heat
                              : unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.auto
                              ? thermostatStatus.auto
                              : unitDetailData.unitData.thermoStat[0]
                                  .latestReading.thermostatMode ===
                                deviceStatus.off
                              ? thermostatStatus.off
                              : ""}
                          </span>
                        </div>
                      </>
                    ) : (
                      <div className="danger-color">
                        {translation("no_thermostat_device_found")}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            {/**
             * Door lock
             */}

            {unitDetailData.unitData &&
            unitDetailData.unitData.door.length > 0 ? (
              <div className="col-md-3">
                <div className="white-card h-100  text-start mb-3 d-flex justify-content-around flex-column">
                  {unitDetailData &&
                  unitDetailData.unitData.door &&
                  unitDetailData.unitData.door?.length ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-left">
                          {unitDetailData &&
                          unitDetailData.unitData.door &&
                          unitDetailData.unitData.door[0] &&
                          unitDetailData.unitData.door[0]?.latestReading
                            .battery ? (
                            unitDetailData.unitData.door[0]?.latestReading
                              .battery < 20 ? (
                              <img className="me-2" src={battery1_19} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 30 ? (
                              <img className="me-2" src={battery20} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 40 ? (
                              <img className="me-2" src={battery30} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 50 ? (
                              <img className="me-2" src={battery40} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 60 ? (
                              <img className="me-2" src={battery50} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 70 ? (
                              <img className="me-2" src={battery60} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 80 ? (
                              <img className="me-2" src={battery70} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 90 ? (
                              <img className="me-2" src={battery80} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery < 100 ? (
                              <img className="me-2" src={battery90} alt="" />
                            ) : unitDetailData.unitData.door[0]?.latestReading
                                .battery === 100 ? (
                              <img className="me-2" src={battery100} alt="" />
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                          {unitDetailData &&
                            unitDetailData.unitData.door &&
                            unitDetailData.unitData.door[0] &&
                            unitDetailData.unitData.door[0]?.latestReading
                              .battery}
                          % &nbsp;
                          <p className="op-3 ">
                            {unitDetailData &&
                              unitDetailData.unitData.door &&
                              unitDetailData.unitData.door[0]?.name}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="font25">
                          {unitDetailData &&
                          unitDetailData.unitData.door &&
                          unitDetailData.unitData.door[0]?.latestReading
                            .lock === locked
                            ? Locked
                            : Unlocked}
                        </p>
                        {authReducer?.authData?.account_type !== resident &&
                        bredcrumbs?.unitStatus !== vacantUnitConstant ? (
                          unitDetailData.unitData.door[0]?.latestReading
                            .lock === locked ? (
                            <span className="custom-badge-off">
                              {translation("locked")}
                            </span>
                          ) : (
                            <span className="custom-badge-on">
                              {translation("unlocked")}
                            </span>
                          )
                        ) : (
                          <label className="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              defaultChecked={
                                unitDetailData &&
                                unitDetailData.unitData.door &&
                                unitDetailData.unitData.door[0]?.latestReading
                                  .lock === locked
                                  ? true
                                  : false
                              }
                              onChange={(event) => {
                                deviceControl({
                                  status: event.target.checked
                                    ? deviceStatus.lock
                                    : deviceStatus.unlock,
                                  deviceType: lock,
                                  deviceId:
                                    unitDetailData &&
                                    unitDetailData.unitData.door &&
                                    unitDetailData.unitData.door[0].deviceId,
                                  roomId:
                                    unitDetailData &&
                                    unitDetailData.unitData.door &&
                                    unitDetailData.unitData.door[0].roomId,
                                });
                              }}
                            />
                            <div className="lock-slider round" />
                          </label>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="danger-color">
                      {translation("no_door_device_found")}
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            {/**
             * Contact Sensor
             */}

            {unitDetailData.unitData &&
            unitDetailData.unitData.contactSensor.length > 0 ? (
              <ContactSensor UnitDeatisData={unitDetailData} />
            ) : null}
            {/**
             * Water leak Sensor
             */}

            {unitDetailData.unitData &&
            unitDetailData.unitData.waterSensor.length > 0 ? (
              <WaterSensor UnitDeatisData={unitDetailData} />
            ) : null}
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              {authReducer?.authData?.account_type === admin ||
              authReducer?.authData?.account_type === company ? (
                <div className="white-card ">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center justify-content-left">
                      <p className="fw-bold me-3">
                        {translation("resident_hub")}{" "}
                      </p>
                    </div>
                  </div>
                  <p>{translation("last_7_days")}</p>
                  <div>
                    <Line data={onlineChartData} options={options} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        !loading &&
        !unitUnassigned && (
          <div className="danger-color text-center">
            {translation("device_not_found")}
          </div>
        )
      )}
    </div>
  );
};

export default UnitNo;
